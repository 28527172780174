import React, {useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, Row} from "reactstrap";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import AuthService from "../../../../services/AuthService";
import CompanyService from "../../../../services/CompanyService";
import styles from "../../../../assets/css/styles";
import {useForm} from "react-hook-form";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

function formatDate(startDate) {
    let startDateFormatted = startDate.toDateString().slice(4).replace(/ /g, '')
    return startDateFormatted.substr(0, 3) + "/" + startDateFormatted.substr(3, 2) + "/" + startDateFormatted.substr(5, startDateFormatted.length)
}

const vacationDatesText = () => {
    return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.VACATION_DATES_DE
        : TRANSLATION.VACATION_DATES_EN
};

const addVacationDatesText = () => {
    return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ADD_VACATION_DE
        : TRANSLATION.ADD_VACATION_EN
};


const StaffVacationsCard = (props) => {
    const {register, handleSubmit} = useForm();
    const [vacation, setVacation] = React.useState(null)
    const [notes, setNotes] = React.useState("NA");

    const startDateText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.START_DATE_DE
            : TRANSLATION.START_DATE_EN
    };

    const endDateText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.END_DATE_DE
            : TRANSLATION.END_DATE_EN
    };

    const notesText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.NOTES_DE
            : TRANSLATION.NOTES_EN
    };

    React.useEffect(() => {
        console.log(props.inEditMode.currentUser.id)
        CompanyService.getVacationsForStaff(props.inEditMode.currentUser.staffId
        ).then(
            (response) => {
                setVacation(response.data)
            },
            (error) => {
            }
        );
    }, [])


    const deleteVacationAndGetUpdatedVacationsList = (vacationId) => {
        CompanyService.deleteVacationAndGetUpdatedVacationList(vacationId
        ).then(
            (response) => {
                setVacation(response.data)
            },
            (error) => {
            }
        );
    }

    function addVacation(staffId, companyId, startDate, endDate, notes) {
        let startDateFormatted = formatDate(startDate);
        let enDateFormatted = formatDate(endDate)

        CompanyService.addVacationForStaff(staffId, companyId, startDateFormatted, enDateFormatted, notes).then(
            (response) => {
                setVacation(response.data)
            },
            (error) => {
            }
        );
        setformModal(false)
    }

    const df = "sf"

    const columns = [{
        dataField: 'startDate',
        text: startDateText(),
        sort: true
    }, {
        dataField: 'endDate',
        text: endDateText(),
        sort: true
    }, {
        dataField: 'notes',
        text: notesText(),
        sort: true

    }, {
        dataField: 'id',
        formatter: (cellContent, row) => {
            return (
                <>
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() => {
                            deleteVacationAndGetUpdatedVacationsList(row.id)
                        }}
                    >
                        Delete
                    </button>
                </>
            );
        }
    }];

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formModal, setformModal] = React.useState(false);

    return (
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">{vacationDatesText()}</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>{
                        vacation && <BootstrapTable
                            keyField='id'
                            data={vacation}
                            columns={columns}>
                            striped
                            bordered={false}
                            wrapperClasses="table-responsive"
                        </BootstrapTable>
                    }

                    </Row>
                    <Row>
                        <Col xs="6">
                            <button
                                block
                                className={"btn-primary"}
                                color="primary"
                                onClick={() => setformModal(true)}
                            >
                                {addVacationDatesText()}
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal
                //className="modal-dialog-centered"
                // size="lg"
                isOpen={formModal}
                toggle={() => setformModal(true)}
                animation={false}
            >
                <div className="modal-header">
                    <Container>
                        <Row className="align-items-center">
                            <Col md="4">
                                <h4>{startDateText()}</h4>
                            </Col>
                            <Col md="8">
                                <DatePicker selected={startDate} minDate={moment().toDate()}
                                            onChange={(date: Date) => setStartDate(date)}/>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="4">
                                <h4>{endDateText()}</h4>
                            </Col>
                            <Col md="8">
                                <DatePicker selected={endDate} minDate={moment().toDate()}
                                            onChange={(date: Date) => setEndDate(date)}/>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="7">
                                <input
                                    name="notes"
                                    {...register("notes", {required: true})}
                                    placeholder={notesText()}
                                    onChange={(e) => setNotes(`${e.target.value}`)}
                                    style={styles.marginTopAndBottom}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="info"
                                        onClick={() => addVacation(props.inEditMode.currentUser.staffId,
                                            AuthService.getCurrentUser().companyId,
                                            startDate,
                                            endDate,
                                            notes)}
                                        type="submit">Submit</Button>
                            </Col>
                        </Row>
                    </Container>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setformModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default StaffVacationsCard