/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Company from "./views/pages/settings/company/Company";
import Services from "./views/pages/settings/Services";
import Servicess from "./views/pages/settings/services/ServiceMultiTab";
import Staffs from "./views/pages/settings/staff/Staffs";
import BookingsHome from "./views/pages/booking/bookingsHome";
import CustomerPage from "./views/pages/customer/CustomerPage";
import CalendarMain from "./views/pages/calendar/CalendarMain";
import BaseIntegrationsPage from "./views/pages/integrations/BaseIntegrationsPage"
import Categories from "./views/pages/settings/Categories/Categories";
import Support from "./views/pages/support/Support";

const routes = [
    {
        path: "/bookings",
        name: "Bookings",
        icon: "ni ni-calendar-grid-58",
        component: BookingsHome,
        layout: "/admin",
    },

    {
        path: "/calendar",
        name: "Calendar",
        icon: "ni ni-calendar-grid-58",
        component: CalendarMain,
        layout: "/admin",
    },
    // {
    // collapse: true,
    // name: "Settings",
    // icon: "ni ni-ungroup text-orange",
    // state: "examplesCollapse",
    // views: [
    {
        path: "/business",
        name: "Business",
        miniName: "P",
        icon: "ni ni-shop text-primary",
        component: Company,
        layout: "/admin",
    },
    {
        path: "/resource",
        name: "Services",
        miniName: "P",
        icon: "ni ni-briefcase-24",
        component: Categories,
        layout: "/admin",
    }, {
        path: "/users",
        name: "Staffs",
        miniName: "P",
        icon: "ni ni-single-02",
        component: Staffs,
        layout: "/admin",
        // }
        // ],
    },
    {
        path: "/customer",
        name: "Customer",
        icon: "ni ni-circle-08",
        component: CustomerPage,
        layout: "/admin",
    },
    {
        path: "/integrations",
        name: "Integrations",
        icon: "ni ni-circle-08",
        component: BaseIntegrationsPage,
        layout: "/admin",
    },
    {
        path: "/support",
        name: "Support",
        icon: "ni ni-circle-08",
        component: Support,
        layout: "/admin",
    }
];

export default routes;
