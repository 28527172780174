import { Button, TextField } from "@mui/material";
import "./AddCategory.css";
import React, { useState } from "react";
import useInput from "../../helper/useInput";
import axiosInstance from "../../helper/AxiosMainHelper";
import { LoadingButton } from "@mui/lab";
import { successToast } from "../../helper/ToastHelper";
function EditCategory({ setOpenModal, companyId, fetchCategories, category }) {
    const [loading, setLoading] = useState(false);
    let {
        enteredInput: enteredName,
        inputInvalid: nameInputIsInvalid,
        blurHandler: nameInputBlurHandler,
        changeHandler: nameInputChangeHandler,
    } = useInput(category.name, (value) => value !== "");

    let {
        enteredInput: enteredDescription,
        inputInvalid: descriptionInputIsInvalid,
        blurHandler: descriptionInputBlurHandler,
        changeHandler: descriptionInputChangeHandler,
    } = useInput(category.description, (value) => value !== "");

    const handlerSubmit = async (e) => {
        e.preventDefault();

        nameInputBlurHandler();
        descriptionInputBlurHandler();

        if (!enteredName || !enteredDescription) {
            return;
        }
        setLoading(true);
        const payload = {
            name: enteredName,
            description: enteredDescription,
            groupId: category.groupId,
        };
        try {
            const response = await axiosInstance.patch(
                `appointment/resource-group`, // Needs to be changed
                payload
            );

            if (response.status === 200) {
                fetchCategories();
                setOpenModal(false);
                successToast("Category updated successfully!");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="booking-form-container create-category">
            <h2>Update category</h2>
            <form className="formWrapper">
                {/*<div className="form-control">*/}
                    <TextField
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={enteredName}
                        onChange={nameInputChangeHandler}
                        onBlur={nameInputBlurHandler}
                        error={nameInputIsInvalid}
                        helperText={nameInputIsInvalid ? "Please enter valid name!" : ""}
                    />
                {/*</div>*/}
                {/*<div className="form-control">*/}
                    <TextField
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={enteredDescription}
                        onChange={descriptionInputChangeHandler}
                        onBlur={descriptionInputBlurHandler}
                        error={descriptionInputIsInvalid}
                        helperText={
                            descriptionInputIsInvalid ? "Please enter valid Description!" : ""
                        }
                    />
                {/*</div>*/}
                <div className="btns">
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        sx={{ mr: 1 }}
                    >
                        Close
                    </Button>

                    <LoadingButton
                        onClick={handlerSubmit}
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                        loadingIndicator="Updating..."
                        sx={{ mr: 1 }}
                    >
                        Update
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
}

export default EditCategory;
