import {
    Button,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";

function WorkHours({ applyToAllHandler, workingHoursList, changeHandler }) {
    return (
        <div className="workHourWrapper">
            <div className="headerWrapperWorking">
                <h2>Business working hours</h2>
                <Button variant="contained" onClick={applyToAllHandler}>
                    Apply 1st to all
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Day</TableCell>
                            <TableCell>Open</TableCell>
                            <TableCell>Close</TableCell>
                            <TableCell>Active</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workingHoursList.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ "& > *": { borderBottom: "unset" } }}
                            >
                                <TableCell>{row.day}</TableCell>
                                <TableCell component="th" scope="row">
                                    <input
                                        type="time"
                                        value={row.startTime}
                                        onChange={(e) =>
                                            changeHandler(e.target.value, row.id, "startTime")
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        type="time"
                                        value={row.endTime}
                                        onChange={(e) =>
                                            changeHandler(e.target.value, row.id, "endTime")
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={row.isOpen}
                                        onChange={(e) =>
                                            changeHandler(e.target.checked, row.id, "isOpen")
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default WorkHours;
