import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import CompanyDetailsCard from "./CompanyDetailsCard";
import React from "react";
import CompanyBookingPageCard from "./CompanyBookingPageCard";
import BusinessHoursModal from "../BusinessHoursModal";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const CompanyMultiTab = (props) => {

    const bookingPageText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKING_PAGE_DE
            : TRANSLATION.BOOKING_PAGE_EN;
    };

    return (
        <Tabs>
            <TabList>
                <Tab>Details</Tab>
                <Tab>{bookingPageText()}</Tab>
                <Tab>Working Hours</Tab>
            </TabList>

            <TabPanel>
                <CompanyDetailsCard username={props.username}
                                    setUsername={props.setUsername}
                                    timeZone={props.timeZone}
                                    setTimeZone={props.setTimeZone}
                                    setRefreshFlag={props.setRefreshFlag}
                ></CompanyDetailsCard>
            </TabPanel>
            <TabPanel>
                <CompanyBookingPageCard companyName={props.companyName}
                                        setRefreshFlag={props.setRefreshFlag}>

                </CompanyBookingPageCard>
            </TabPanel>
            <TabPanel>
                <BusinessHoursModal workingHours={props.workingHours}></BusinessHoursModal>
            </TabPanel>
        </Tabs>
    )
}

export default CompanyMultiTab