const styles = {
    container: {
        width: "80%",
        margin: "0 auto",
    },
    input: {
        width: "100%",
        marginBottom: "5px"
    },
    inputWithNoBottom: {
        width: "100%"
    },
    inputWithExtraBottom: {
        width: "100%",
        marginBottom: "15px"
    },
    marginTopAndBottom: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    marginLeftAndBottom: {
        marginLeft: "15px",
        marginBottom: "15px",
        fontWeight: "bold",
        fontSize:"20px"
    },

    marginLeftAndBottomChild: {
        marginLeft: "15px",
        marginBottom: "15px",
        fontSize:"15px"
    }
};

export default styles
