/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react library for routing
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";

// reactstrap components
import {Button, Card, CardBody, Container, Row, Col, CardHeader, Label} from "reactstrap";
import {UncontrolledCarousel} from "reactstrap";
import CompanyService from "../../services/CompanyService";
import bookingContext from "../../views/pages/external-booking/context/bookingContext";
import UserBooking from "../../views/pages/external-booking/components/booking-process/UserBooking";
import BookingsSlot from "../booking/BookingsSlot";
import styles from "../../assets/css/styles";
import WorkingTimeTable from "./WorkingTimeTable";

function ExternalBookingHeader() {
    const items = [
        {
            src:
                "https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-5.jpg",
            altText: "Slide 1",
            caption: "",
            header: "",
            key: "1",
        },
        {
            src:
                "https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-4.jpg",
            altText: "Slide 2",
            caption: "",
            header: "",
            key: "2",
        },
        {
            src:
                "https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-3.jpg",
            altText: "Slide 3",
            caption: "",
            header: "",
            key: "3",
        },
    ];

    const {bookingDetails, setBookingDetails, setProceedNext} = useContext(bookingContext); // Context API
    const [staff, setStaff] = useState([]);
    const [response, setResponse] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);

    const [userid, setuserId] = useState(null);
    const [serviceid, setServiceId] = useState(null);
    const [companyid, setCompanyId] = useState(null);
    const [bookingDate, setBookingDate] = React.useState(null)
    const [timeSlot, setTimeSlot] = React.useState(null)

    function saveAndProceedToNextPage() {
        const data = {...bookingDetails}
        data["bookingData"]["staff"] = userid
        data["bookingData"]["service"] = serviceid
        data["bookingData"]["company"] = companyid
        data["bookingData"]["bookingDate"] = bookingDate
        data["bookingData"]["timeSlot"] = timeSlot
        setBookingDetails(data)

        const {bookingData} = bookingDetails;

        console.log(bookingData.staff)
    }

    const getBookingDate = (date) => {
        const data = {...bookingDetails}
        data["bookingData"]["bookingDate"] = date
        setBookingDetails(data)
        setBookingDate(date)
    }

    const getTimeSlot = (timeslot) => {
        const data = {...bookingDetails}
        data["bookingData"]["timeSlot"] = timeslot
        setBookingDetails(data)
        setTimeSlot(timeSlot)
    }

    function getDetailsFromUserBooking() {
        const getCompanyIdFromUserBooking = (companyId) => {
            const data = {...bookingDetails}
            data["bookingData"]["company"] = companyId
            setBookingDetails(data)
            setCompanyId(companyId)
        }

        const getServiceIdFromUserBooking = (serviceId, name) => {
            const data = {...bookingDetails}
            data["bookingData"]["service"] = serviceId
            data["bookingData"]["serviceName"] = name
            setBookingDetails(data)
            setServiceId(serviceId)
        }

        const getUserIdFromUserBooking = (userId, name) => {
            const data = {...bookingDetails}
            data["bookingData"]["staff"] = userId
            data["bookingData"]["staffName"] = name
            setBookingDetails(data)
            setuserId(userId)
        }
        return {getCompanyIdFromUserBooking, getServiceIdFromUserBooking, getUserIdFromUserBooking};
    }

    const {
        getCompanyIdFromUserBooking,
        getServiceIdFromUserBooking,
        getUserIdFromUserBooking
    } = getDetailsFromUserBooking();

    function setDetailsForCompanyBooking(response) {
        setStaff(response.data.staffDetailsList);
        setCompanyId(response.data.companyDetails.id)
        const data = {...bookingDetails}
        data["bookingData"]["company"] = response.data.companyDetails.id
        setBookingDetails(data)
    }

    React.useEffect(() => {
        let companyName = window.location.host.split(".")[0]
        CompanyService.getCompanyDetails(companyName).then(
            (response) => {
                console.log(response.data)
                if (!!(response.data.companyDetails)) {
                    setDetailsForCompanyBooking(response);
                }
                setResponse(response.data)
            },
            (error) => {
            }
        );
    }, [])


    const handleChangeStaff = event => {
        const value = staff.filter(function (item) {
            function setStaffNameInContext() {
                const data = {...bookingDetails}
                data["bookingData"]["staffName"] = event.target.value
                setBookingDetails(data)
            }

            setStaffNameInContext();
            let selectedIndex = event.target.options.selectedIndex;
            return item.id === event.target.options[selectedIndex].getAttribute('data-key')
        });

        setSelectedStaff(value[0]);
        setuserId(value[0].id)

        function setStaffIdInContext() {
            const data = {...bookingDetails}
            data["bookingData"]["staff"] = value[0].id
            setBookingDetails(data)
        }

        setStaffIdInContext();

    };

    const handleChangeService = event => {
        const value = selectedStaff.resourceDtoList.filter(function (item) {
            function setServiceNameInContext() {
                const data = {...bookingDetails}
                data["bookingData"]["serviceName"] = event.target.value
                setBookingDetails(data)
            }

            setServiceNameInContext();
            let selectedIndex = event.target.options.selectedIndex;
            return item.id === event.target.options[selectedIndex].getAttribute('data-key')
        });
        setServiceId(value[0].id)

        function setServiceidInContext() {
            const data = {...bookingDetails}
            data["bookingData"]["service"] = value[0].id
            setBookingDetails(data)
        }

        setServiceidInContext();
        // props.onChangeServiceId(value[0].id)
    };


    return (
        <>
            <div className="header bg-info pt-5 pb-7">
                <Container>
                    <div className="header-body">
                        <Row className="align-items-center">
                            <Col md="6">
                                <Row>
                                    {!!(response.companyDetails) ? (
                                        <>
                                            <Col md="12" style={styles.inputWithExtraBottom}
                                            >
                                                <div className="pr-5">
                                                    <>
                                                        <h1 className="display-2 text-white font-weight-bold mb-0"> {response.companyDetails.name}</h1>
                                                        <h2 className="display-4 text-white font-weight-light">
                                                            {response.companyDetails.addressFirst}, {response.companyDetails.postCode}

                                                        </h2>
                                                        <h3 className="display-4 text-white font-weight-light">
                                                            {response.companyDetails.city}
                                                        </h3>

                                                    </>

                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <WorkingTimeTable companyDetails={response.companyDetails}></WorkingTimeTable>
                                            </Col>
                                        </>

                                    ) : null}

                                </Row>
                            </Col>
                            <Col md="6">
                                <Row className="pt-5">
                                    <Col md="12">
                                        <Card>
                                            <UncontrolledCarousel items={items}/>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
                {
                    !!(response.currentStaffDetails) ? (

                        <>
                            {/*<p>Hi there!, welcome to the booking page of {response.currentStaffDetails.name}. Please select the*/}
                            {/*    appropriate event to add an event to my calendar.*/}
                            {/*    You can also reach me at {response.currentStaffDetails.email} for any queries in prior</p>*/}

                            <Row>

                                <UserBooking staff={response.currentStaffDetails}
                                             onChangeService={getServiceIdFromUserBooking}
                                             getCompanyid={getCompanyIdFromUserBooking}
                                             getUserId={getUserIdFromUserBooking}></UserBooking>
                            </Row>


                            <BookingsSlot onChangeBookingDate={getBookingDate} onChangeTimeSlot={getTimeSlot}
                                          userid={response.currentStaffDetails.id} serviceid={serviceid}
                                          companyid={companyid}/>

                        </>

                    ) : null
                }

                {
                    !!(response.companyDetails) ? (
                        // <Card md="8" style={{backgroundColor: '#4DD0E1'}}>
                        //   <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                        //     <h3 className="mb-0">Booking Details</h3>
                        //   </CardHeader>
                        //   <CardBody>
                        <Container>
                            <Row>
                                <BookingsSlot onChangeBookingDate={getBookingDate} onChangeTimeSlot={getTimeSlot}
                                              userid={userid} serviceid={serviceid} companyid={companyid}/>
                            </Row>
                            <Row>
                                <Col>
                                    {timeSlot ? (
                                        <Button
                                            className={"btn btn-info"}
                                            style={{marginLeft: 8}}
                                            onClick={e => {
                                                saveAndProceedToNextPage()
                                            }
                                            }
                                            style={styles.inputWithExtraBottom}
                                        >
                                            Next
                                        </Button>

                                    ) : null}

                                </Col>
                            </Row>
                        </Container>) : null
                }
                {/*</Card>) : null}*/
                }
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </div>
        </>
    )
        ;
}

export default ExternalBookingHeader;
