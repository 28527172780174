import AuthHeader from "../../../components/Headers/AuthHeader";

function EmailPasswordReset() {
  return (
    <>
      <AuthHeader
        title="Password Reset"
        lead="Please click on the link sent in your email to set the new password"
      />
    </>
  );
}

export default EmailPasswordReset;
