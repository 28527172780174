import React, {useContext} from 'react'
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, Container,
    FormGroup, Input,
    ListGroup,
    ListGroupItem,
    Modal, ModalHeader,
    Row,
    Table
} from 'reactstrap';
import OrderContext from '../../context/orderContext';
import {useForm} from "react-hook-form";
import styles from "../../../../../assets/css/styles";
import * as PropTypes from "prop-types";

function ModalTitle(props) {
    return null;
}

ModalTitle.propTypes = {children: PropTypes.node};
const AddEmployee = () => {

    const {checkoutDetails, setCheckoutDetails, setProceedNext} = useContext(OrderContext); // Context API
    const {register, handleSubmit} = useForm();
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("NA");

    const handleDeleteProduct = (productId) => {
        const data = {...checkoutDetails}
        data["employeeData"] = checkoutDetails.employeeData.filter(product => product.id !== productId); //Removing selected product
        setCheckoutDetails(data);
    }


    // Destructuring object from Context API
    const {employeeData} = checkoutDetails;
    const [formModal, setformModal] = React.useState(false);

    function onSubmit(submit) {
        const data = {...checkoutDetails}
        data["employeeData"] = checkoutDetails.employeeData
        const newEmployee = {name: name, email: email}
        employeeData.push(newEmployee)
        setformModal(false)
    }

    return (
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="4">
                                <button
                                    className={"btn-success"}
                                    onClick={() => setformModal(true)}
                                >
                                    Add Employee
                                </button>
                        </Col>
                    </Row>
                </CardHeader>
            </Card>
            <Table>
                <thead className="thead-light">
                <tr>
                    <th>Employee</th>
                    <th>email</th>
                </tr>
                </thead>
                <tbody>
                {employeeData.map((data, index) => {
                    return (
                        <>
                            <tr key={index}>
                                <td>
                                    {data["name"]}
                                </td>
                                <td>
                                    {data["email"]}
                                </td>
                                <span key={index}>
                                    <i
                                        key={index}
                                        className="fa fa-trash deleteBtn"
                                        aria-hidden="true"
                                        onClick={e => {
                                            handleDeleteProduct(data["id"])
                                        }}
                                    />
                                </span>
                            </tr>
                        </>
                    )
                })}
                </tbody>

            </Table>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={formModal}
                toggle={() => setformModal(false)}
            >
                <ModalHeader closeButton>
                    <div className="d-flex justify-content-center">
                        <b>Add New Employee</b>
                    </div>
                </ModalHeader>
                <div className="modal-header">
                    <Container>
                        <Row className="align-items-center">
                            <Col md="3">
                                <h4>Name</h4>
                            </Col>
                            <Col md="9">
                                <Input
                                    name="name"
                                    {...register("name", {required: true})}
                                    onChange={(e) => setName(`${e.target.value}`)}
                                    style={styles.inputWithExtraBottom}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="3">
                                <h4>Email</h4>
                            </Col>
                            <Col md="9">
                                <Input
                                    name="email"
                                    {...register("email", {required: true})}
                                    onChange={(e) => setEmail(`${e.target.value}`)}
                                    style={styles.inputWithExtraBottom}
                                />
                                <Alert md="3" color="warning">
                                    Please don't enter the email that you already registered with
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="info" onClick={onSubmit} type="submit">Submit</Button>

                            </Col>
                        </Row>

                    </Container>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setformModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            </Modal>
        </>
    )
}
export default AddEmployee
