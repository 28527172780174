import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

function StepperMain({ steps, activeStep, alternativeLabel = false }) {
    return (
        <Stepper
            activeStep={activeStep}
            className="stepper"
            alternativeLabel={alternativeLabel}
        >
            {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}

export default StepperMain;
