import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthService from "services/AuthService";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import styles from "../../../assets/css/styles";
import {GoogleLogin} from 'react-google-login';
import {gapi} from 'gapi-script';
import * as CONSTANTS from "../../../Constants";

function Register() {

    const [email, setEmail] = React.useState("NA");
    const [password, setPassword] = React.useState("NA");
    const [name, setName] = React.useState("NA");
    const {register, handleSubmit} = useForm();
    const history = useHistory();

    const onSuccess = (res) => {
        AuthService.register(null, null, null, null, res.tokenId).then(
            (response) => {
                history.push("/auth/slogin");
            },
            (error) => {
                if (error.response.data.message.includes("email")) {
                    history.push("/auth/flogin");
                }
            }
        );
    };

    const onFailure = (err) => {
        console.log('failed:', err);
    };


    function onSubmit() {
        AuthService.register(email, password, name, null, null).then(
            (response) => {
                history.push("/auth/slogin");
            },
            (error) => {
                if (error.response.data.message.includes("email")) {
                    history.push("/auth/flogin");
                }
            }
        );
    }

    return (
        <>
            <AuthHeader
                title="Create an account"
                lead="Create your account for free and get started with the appointment scheduling!."
            />
            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="6" md="8">
                        <Card className="bg-secondary border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <Row className="align-items-center">
                                    <Col md="3">
                                        <h4>Email</h4>
                                    </Col>
                                    <Col md="9">
                                        <Input
                                            name="email"
                                            {...register("email", {required: true})}
                                            onChange={(e) => setEmail(`${e.target.value}`)}
                                            style={styles.input}
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md="3">
                                        <h4>Password</h4>
                                    </Col>
                                    <Col md="9">
                                        <Input
                                            type="password"
                                            name="password"
                                            {...register("password", {required: true})}
                                            onChange={(e) => setPassword(`${e.target.value}`)}
                                            style={styles.input}
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md="3">
                                        <h4>Name</h4>
                                    </Col>
                                    <Col md="9">
                                        <Input
                                            name="name"
                                            {...register("name", {required: true})}
                                            onChange={(e) => setName(`${e.target.value}`)}
                                            style={styles.inputWithExtraBottom}
                                            placeholder="Please enter your name"
                                        />
                                    </Col>
                                </Row>
                                <Button style={styles.inputWithExtraBottom} color="success" type="submit"
                                        onClick={onSubmit}>Create</Button>
                                <Row>
                                    <GoogleLogin
                                        clientId={CONSTANTS.CLIENT_ID}
                                        buttonText="Create account with Google"
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        cookiePolicy={'single_host_origin'}
                                        // isSignedIn={true}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Register;
