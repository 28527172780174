import React, {useState} from 'react'
import Dropzone from "dropzone";
import {Container, Row, Col} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CompanyService from "../../../../services/CompanyService";
import AuthService from "../../../../services/AuthService";
import companyService from "../../../../services/CompanyService";
// import "@progress/kendo-theme-default/dist/all.css";
import CompanyMultiTab from "./CompanyMultiTab";
import {LogoutIfJwtExpired} from "../../../../global/Utils";
import {useHistory} from "react-router-dom";

Dropzone.autoDiscover = false;

function Company() {
    const [username, setUsername] = React.useState("")
    const [companyName, setCompanyName] = React.useState("NA")
    const [timeZone, setTimeZone] = React.useState([]);
    const [businessHours, setBusinessHours] = React.useState([]);
    const [refreshFlag, setRefreshFlag] = useState(false)
    const history = useHistory();

    React.useEffect(() => {
        if (AuthService.getCurrentUser()) {
            CompanyService.getCompany(AuthService.getCurrentUser().id).then(
                (response) => {
                    console.log(response.data)
                    setUsername(response.data.name);
                    setCompanyName(response.data.username);
                    setTimeZone(response.data.timeZone)
                },
                (error) => {
                    LogoutIfJwtExpired(error)
                    {
                        history.push("/")
                    }
                }
            );

            companyService.getCompanyBusinessHours(AuthService.getCurrentUser().companyId).then(
                (response) => {
                    setBusinessHours(response.data)
                    console.log(response.data)

                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            setRefreshFlag(false)
        }else{
            LogoutIfJwtExpired(null)
            {history.push("/")}
        }
    }, [refreshFlag])


    return (
        <>
            <SimpleHeader name="Business"/>
            <Container className="mt--6" fluid>
                <Row>
                    <Col sm="10">
                        <div className="card-wrapper">

                            <CompanyMultiTab username={username}
                                             setUsername={setUsername}
                                             timeZone={timeZone}
                                             companyName={companyName}
                                             setTimeZone={setTimeZone}
                                             setRefreshFlag={setRefreshFlag}
                                             workingHours={businessHours}>

                            </CompanyMultiTab>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Company;
