import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthService from "../../../services/AuthService";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import styles from "../../../assets/css/styles";
import EmailService from "../../../services/EmailService";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

const BookingWidget = (props) => {

    const {register, handleSubmit} = useForm();
    const [email, setEmail] = React.useState("NA");

    const [successAlert, setSuccessAlert] = React.useState(false);
    const [failureAlert, setFailureAlert] = React.useState(false);

    const bookingWidgetHeader = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKING_WIDGET_DE_1
            : TRANSLATION.BOOKING_WIDGET_EN_1
    };

    const bookingWidgetDescription = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKING_WIDGET_DE_2
            : TRANSLATION.BOOKING_WIDGET_EN_2
    };

    const onSave = () => {
        EmailService.sendEmail(email).then(
            (response) => {
                console.log(response.data)
                setSuccessAlert(true)
                setTimeout(() => {
                    setSuccessAlert(false);
                }, 1000);
            },
            (error) => {
                setFailureAlert(true)
                setTimeout(() => {
                    setFailureAlert(false);
                }, 1500);
            }
        );

    }

    return (
        <>
            <div style={styles.marginLeftAndBottom}

            >{bookingWidgetHeader()}
            </div>
            <Card>
                <Row>
                    <Col style = {styles.marginLeftAndBottomChild}>
                        {bookingWidgetDescription()}
                    </Col>
                    <Col >
                        <button className="btn btn-primary" type="submit">Book Now</button>

                    </Col>
                </Row>
                <Row>
                    <Col lg="4" md="4">
                        <Input
                            name="email"
                            {...register("email", {required: true})}
                            placeholder="Email"
                            onChange={(e) => setEmail(`${e.target.value}`)}
                            style={styles.marginLeftAndBottomChild}
                        />
                        <button  style={styles.marginLeftAndBottomChild} onClick={onSave} class="btn btn-primary" type="submit">Send Code</button>
                    </Col>
                </Row>
                {successAlert && <div className='alert-container'>
                    <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Code will be sent to your email soon!!
                    </div>
                </div>}
                {failureAlert && <div className='alert-container'>
                    <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Error, please try again!
                    </div>
                </div>}
            </Card>
        </>

    );
}

export default BookingWidget;