import { Button, TextField } from "@mui/material";
import "./AddCategory.css";
import React, { useState } from "react";
import useInput from "../../helper/useInput";
import axiosInstance from "../../helper/AxiosMainHelper";
import { LoadingButton } from "@mui/lab";
import { successToast } from "../../helper/ToastHelper";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const addCategoryText = () => {
    return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ADD_NEW_CATEGORY_DE
        : TRANSLATION.ADD_NEW_CATEGORY_EN
};

const addDescriptionText = () => {
    return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.DESCRIPTION_DE
        : TRANSLATION.DESCRIPTION_EN
};

const createText = () => {
    return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CREATE_DE
        : TRANSLATION.CREATE_EN
};
function AddCategory({
                         setOpenModal,
                         setCategoryList,
                         companyId,
                         fetchCategories,
                         isOnboardPage = false,
                     }) {
    const [loading, setLoading] = useState(false);
    let {
        enteredInput: enteredName,
        inputInvalid: nameInputIsInvalid,
        blurHandler: nameInputBlurHandler,
        changeHandler: nameInputChangeHandler,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredDescription,
        inputInvalid: descriptionInputIsInvalid,
        blurHandler: descriptionInputBlurHandler,
        changeHandler: descriptionInputChangeHandler,
    } = useInput("", (value) => value !== "");

    const handlerSubmit = async (e) => {
        e.preventDefault();

        nameInputBlurHandler();
        descriptionInputBlurHandler();

        if (!enteredName || !enteredDescription) {
            return;
        }
        setLoading(true);
        const payload = {
            name: enteredName,
            description: enteredDescription,
        };
        if (isOnboardPage) {
            setCategoryList((prev) => [
                ...prev,
                {
                    ...payload,
                    groupId: `${prev.length + 1}-${enteredName}`,
                    servicesList: [],
                },
            ]);
            setLoading(false);
            setOpenModal(false);
        } else {
            try {
                const response = await axiosInstance.post(
                    `appointment/resource-group`,
                    { ...payload, companyId: companyId }
                );

                if (response.status === 200) {
                    fetchCategories();
                    setOpenModal(false);
                    successToast("Category created successfully!");
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    };

    return (
        <div className="booking-form-container create-category">
            <h2>{addCategoryText()}</h2>
            <form className="formWrapper">
                    <TextField
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={enteredName}
                        onChange={nameInputChangeHandler}
                        onBlur={nameInputBlurHandler}
                        error={nameInputIsInvalid}
                        helperText={nameInputIsInvalid ? "Please enter valid name!" : ""}
                    />
                    <TextField
                        label= {addDescriptionText()}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={enteredDescription}
                        onChange={descriptionInputChangeHandler}
                        onBlur={descriptionInputBlurHandler}
                        error={descriptionInputIsInvalid}
                        helperText={
                            descriptionInputIsInvalid ? "Please enter valid Description!" : ""
                        }
                    />
                <div className="btns">
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        sx={{ mr: 1 }}
                    >
                        Close
                    </Button>

                    <LoadingButton
                        onClick={handlerSubmit}
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                        loadingIndicator="Creating..."
                        sx={{ mr: 1 }}
                    >
                        {createText()}
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
}

export default AddCategory;
