import React, {useMemo, useState} from "react";
import {Box, Container} from "@mui/system";
import StepperMain from "../helper/StepperMain";
import {Button} from "@mui/material";
import useInput from "../helper/useInput";
import countryList from "react-select-country-list";
import BusinessDetails from "../../../components/Onboarding/BusinessDetails";
import WorkHours from "../../../components/Onboarding/WorkHours";
import "./OnboardingLayout.css";
import AddEmployeeForm from "../../../components/Onboarding/AddEmployeeForm";
import AddServicesOnboard from "../../../components/Onboarding/AddServices";
import {v4 as uuidv4} from "uuid";
import axiosInstance from "../helper/AxiosMainHelper";
import {toast} from "react-toastify";
import {errorToast} from "../helper/ToastHelper";
import {ToastContainer} from "react-toastify";
import authService from '../../../services/AuthService';
import authHeader from "../../../services/AuthHeader";
import {useHistory} from "react-router-dom";


const steps = [
    "Business Detail",
    "Working Hours",
    "Add Employee",
    "Add Service",
];

const STATIC_WORKING_DATA = [
    {id: 1, day: "Monday", startTime: "08:00", endTime: "16:00", isOpen: true},
    {id: 2, day: "Tuesday", startTime: "08:00", endTime: "16:00", isOpen: true},
    {
        id: 3,
        day: "Wednesday",
        startTime: "08:00",
        endTime: "16:00",
        isOpen: true,
    },
    {
        id: 4,
        day: "Thursday",
        startTime: "08:00",
        endTime: "16:00",
        isOpen: true,
    },
    {id: 5, day: "Friday", startTime: "08:00", endTime: "16:00", isOpen: true},
    {
        id: 6,
        day: "Saturday",
        startTime: "08:00",
        endTime: "16:00",
        isOpen: false,
    },
    {id: 7, day: "Sunday", startTime: "08:00", endTime: "16:00", isOpen: false},
];

const validate = (value) => value !== "";

function OnboardingLayout() {
    const history = useHistory();

    const [activeStep, setActiveStep] = useState(0);
    const [tz, setTz] = useState("");
    const [enteredTimeZoneBlur, setEnteredTimeZoneInputBlur] = useState(false);

    const enteredTimeZoneInputIsValid = enteredTimeZoneBlur && validate(tz);
    const TimeZoneInputIsInvalid =
        !enteredTimeZoneInputIsValid && enteredTimeZoneBlur;

    const TimeZoneInputBlurHandler = () => {
        setEnteredTimeZoneInputBlur(true);
    };

    let {
        enteredInput: enteredBusinessName,
        inputInvalid: businessNameInputIsInvalid,
        blurHandler: businessNameInputBlurHandler,
        changeHandler: businessNameInputChangeHandler,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredBusinessType,
        inputInvalid: businessTypeInputIsInvalid,
        blurHandler: businessTypeInputBlurHandler,
        changeHandler: businessTypeInputChangeHandler,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredPhoneNumber,
        inputInvalid: phoneNumberInputIsInvalid,
        blurHandler: phoneNumberInputBlurHandler,
        changeHandler: phoneNumberInputChangeHandler,
    } = useInput("", (value) => true);

    let {
        enteredInput: enteredStreetDetail,
        inputInvalid: streetDetailInputIsInvalid,
        blurHandler: streetDetailInputBlurHandler,
        changeHandler: streetDetailInputChangeHandler,
    } = useInput("", (value) => true);

    let {
        enteredInput: enteredCity,
        inputInvalid: cityInputIsInvalid,
        blurHandler: cityInputBlurHandler,
        changeHandler: cityInputChangeHandler,
    } = useInput("", (value) => true);

    let {
        enteredInput: enteredState,
        inputInvalid: stateInputIsInvalid,
        blurHandler: stateInputBlurHandler,
        changeHandler: stateInputChangeHandler,
    } = useInput("", (value) => true);

    let {
        enteredInput: enteredPostalCode,
        inputInvalid: postalCodeInputIsInvalid,
        blurHandler: postalCodeInputBlurHandler,
        changeHandler: postalCodeInputChangeHandler,
    } = useInput("", (value) => true);

    const [enteredCountry, setEnteredCountry] = useState("");
    const countryOptions = useMemo(() => countryList().getData(), []);
    const [enteredCountryBlur, setEnteredCountryInputBlur] = useState(false);

    const enteredCountryInputIsValid =
        enteredCountryBlur && validate(enteredCountry);
    const countryInputIsInvalid =
        !enteredCountryInputIsValid && enteredCountryBlur;

    const countryInputBlurHandler = () => {
        setEnteredCountryInputBlur(true);
    };

    const countryInputChangeHandler = (value) => {
        setEnteredCountry(value);
    };

    // Working Hours State
    const [workingHoursList, setWorkingHoursList] = useState(STATIC_WORKING_DATA);

    const changeHandler = (value, id, type) => {
        setWorkingHoursList((prev) => {
            return prev.map((s) => {
                if (s.id === id) {
                    return {...s, [type]: value};
                }
                return s;
            });
        });
    };

    const applyToAllHandler = () => {
        setWorkingHoursList((prev) => {
            return prev.map((s, index, arr) => {
                if (index > 0) {
                    return {
                        ...s,
                        startTime: arr[0].startTime,
                        endTime: arr[0].endTime,
                        isOpen: arr[0].isOpen,
                    };
                }
                return s;
            });
        });
    };

    // ADD EMPLOYEE FORM STATE
    const [employeeList, setEmployeeList] = useState([]);

    // ADD SERVICES FORM STATE
    const [categoryList, setCategoryList] = useState([]);

    const handleLogout = async () => {
        authService.logout()
        history.push("/");
    }
    const handleNext = async () => {
        if (activeStep === 0) {
            businessNameInputBlurHandler();
            businessTypeInputBlurHandler();
            TimeZoneInputBlurHandler();
            streetDetailInputBlurHandler();
            cityInputBlurHandler();
            stateInputBlurHandler();
            countryInputBlurHandler();
            postalCodeInputBlurHandler();

            if (
                !enteredBusinessName ||
                !enteredBusinessType ||
                !tz ||
                !enteredCountry
            ) {
                return;
            }
            setActiveStep((prev) => prev + 1);
            return;
        }

        if (activeStep === 1) {
            setActiveStep((prev) => prev + 1);
            return;
        }

        if (activeStep === 2) {
            setActiveStep((prev) => prev + 1);
            return;
        }

        if (activeStep === 3) {
            if (
                categoryList.length === 0
            ) {
                toast.error("Please add at least one category for services/events you want to schedule!");
                return;
            }

            if (
                categoryList[0].servicesList.length === 0
            ) {
                toast.error("Please add at least one service/event by clicking on the + icon next to the category you have added");
                return;
            }
            const finalPayload = {
                userId: authService.getCurrentUser().id,
                company: {
                    name: enteredBusinessName,
                    type: enteredBusinessType,
                    phone: enteredPhoneNumber,
                    addressFirst: enteredStreetDetail,
                    addressSecond: "",
                    city: enteredCity,
                    state: enteredState,
                    postCode: enteredPostalCode,
                    country: enteredCountry,
                    timeZone: tz,
                },
                workingHours: workingHoursList.map((s) => ({
                    day: s.day,
                    endTime: s.endTime,
                    startTime: s.startTime,
                    open: s.isOpen,
                })),
                employees: employeeList.map((s) => ({
                    name: s.name,
                    email: s.email,
                })),
                resources: categoryList,
            };

            console.log({finalPayload});
            try {
                const response = await axiosInstance.post(`onboard/user`, {...finalPayload},
                    {headers: authHeader()});

                if (response.status === 200) {
                    console.log({response});
                    localStorage.setItem('isCompanyOnboarded', 'true')
                    history.push("/admin/dashboard");
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Container maxWidth="xl">
            <div className="onboarding-wrapper">
                <Box sx={{flex: "1 1 auto"}}>
                    <Button onClick={handleLogout} variant="contained">
                        Logout
                    </Button>
                </Box>
                <div className="stepperWrapper">
                    <StepperMain steps={steps} activeStep={activeStep} alternativeLabel/>
                </div>
                <div className="stepContainerWrapper">
                    {activeStep === 0 && (
                        <BusinessDetails
                            enteredBusinessName={enteredBusinessName}
                            businessNameInputChangeHandler={businessNameInputChangeHandler}
                            businessNameInputBlurHandler={businessNameInputBlurHandler}
                            businessNameInputIsInvalid={businessNameInputIsInvalid}
                            enteredBusinessType={enteredBusinessType}
                            businessTypeInputChangeHandler = {businessTypeInputChangeHandler}
                            businessTypeInputBlurHandler = {businessTypeInputBlurHandler}
                            businessTypeInputIsInvalid = {businessTypeInputIsInvalid}
                            tz={tz}
                            setTz={setTz}
                            TimeZoneInputBlurHandler={TimeZoneInputBlurHandler}
                            TimeZoneInputIsInvalid={TimeZoneInputIsInvalid}
                            enteredStreetDetail={enteredStreetDetail}
                            streetDetailInputChangeHandler={streetDetailInputChangeHandler}
                            streetDetailInputBlurHandler={streetDetailInputBlurHandler}
                            streetDetailInputIsInvalid={streetDetailInputIsInvalid}
                            enteredCity={enteredCity}
                            cityInputChangeHandler={cityInputChangeHandler}
                            cityInputBlurHandler={cityInputBlurHandler}
                            cityInputIsInvalid={cityInputIsInvalid}
                            enteredState={enteredState}
                            stateInputChangeHandler={stateInputChangeHandler}
                            stateInputBlurHandler={stateInputBlurHandler}
                            stateInputIsInvalid={stateInputIsInvalid}
                            enteredPostalCode={enteredPostalCode}
                            postalCodeInputChangeHandler={postalCodeInputChangeHandler}
                            postalCodeInputBlurHandler={postalCodeInputBlurHandler}
                            postalCodeInputIsInvalid={postalCodeInputIsInvalid}
                            countryOptions={countryOptions}
                            enteredCountry={enteredCountry}
                            countryInputBlurHandler={countryInputBlurHandler}
                            countryInputChangeHandler={countryInputChangeHandler}
                            countryInputIsInvalid={countryInputIsInvalid}
                            enteredPhoneNumber={enteredPhoneNumber}
                            phoneNumberInputChangeHandler={phoneNumberInputChangeHandler}
                            phoneNumberInputBlurHandler={phoneNumberInputBlurHandler}
                            phoneNumberInputIsInvalid={phoneNumberInputIsInvalid}
                        />
                    )}

                    {activeStep === 1 && (
                        <WorkHours
                            applyToAllHandler={applyToAllHandler}
                            workingHoursList={workingHoursList}
                            changeHandler={changeHandler}
                        />
                    )}

                    {activeStep === 2 && (
                        <AddEmployeeForm
                            setEmployeeList={setEmployeeList}
                            employeeList={employeeList}
                        />
                    )}

                    {activeStep === 3 && (
                        <AddServicesOnboard
                            setCategoryList={setCategoryList}
                            categoryList={categoryList}
                        />
                    )}
                </div>
                <Box sx={{display: "flex", flexDirection: "row", pt: 2, px: 2}}>
                    {activeStep >= 1 && (
                        <Button
                            variant="contained"
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                    )}
                    <Box sx={{flex: "1 1 auto"}}/>

                    <Button onClick={handleNext} variant="contained">
                        {activeStep === 3 ? "Finish" : "Next"}
                    </Button>
                </Box>
            </div>
        </Container>
    );
}

export default OnboardingLayout;
