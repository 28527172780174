import React from "react";
import SelectR from "react-select";
import { FormControl, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function BusinessDetails(props) {
    return (
        <div className="businessDetailsWrapper">
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": {},
                }}
                noValidate
                autoComplete="off"
            >
                <div className="companyWrapper">
                    <Typography variant="p" sx={{ width: "100%" }}>
                        Business
                    </Typography>
                    <div className="twoLayout">
                        <TextField
                            label="Business Name"
                            fullWidth
                            variant="outlined"
                            value={props.enteredBusinessName}
                            onChange={props.businessNameInputChangeHandler}
                            onBlur={props.businessNameInputBlurHandler}N
                            error={props.businessNameInputIsInvalid}
                            helperText={
                                props.businessNameInputIsInvalid
                                    ? "Please enter valid name!"
                                    : ""
                            }
                        />
                        <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.enteredBusinessType}
                                label="Business Type"
                                onChange={props.businessTypeInputChangeHandler}
                            >
                                <MenuItem value={1}>Salon</MenuItem>
                                <MenuItem value={2}>Others</MenuItem>
                            </Select>
                            {props.businessTypeInputIsInvalid && (
                                <p className="error">Please select a Business Type</p>
                            )}
                        </FormControl>


                        <TextField
                            label="Phone Number (Optional)"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={props.enteredPhoneNumber}
                            onChange={props.phoneNumberInputChangeHandler}
                            onBlur={props.phoneNumberInputBlurHandler}
                            error={props.phoneNumberInputIsInvalid}
                            // helperText={
                            //     props.phoneNumberInputIsInvalid
                            //         ? "Please enter valid phone number!"
                            //         : ""
                            // }
                        />
                    </div>
                </div>



                <div className="companyWrapper">
                    <Typography variant="p" sx={{ width: "100%" }}>
                        Address (Optional)
                    </Typography>
                    <TextField
                        label="Street and House number"
                        fullWidth
                        variant="outlined"
                        value={props.enteredStreetDetail}
                        onChange={props.streetDetailInputChangeHandler}
                        onBlur={props.streetDetailInputBlurHandler}
                        error={props.streetDetailInputIsInvalid}
                        // helperText={
                        //     props.streetDetailInputIsInvalid
                        //         ? "Please enter valid details!"
                        //         : ""
                        // }
                    />

                    <div className="twoLayout">
                        <TextField
                            label="City"
                            fullWidth
                            variant="outlined"
                            value={props.enteredCity}
                            onChange={props.cityInputChangeHandler}
                            onBlur={props.cityInputBlurHandler}
                            error={props.cityInputIsInvalid}
                            // helperText={
                            //     props.cityInputIsInvalid ? "Please enter valid city name!" : ""
                            // }
                        />

                        <TextField
                            label="State"
                            fullWidth
                            variant="outlined"
                            value={props.enteredState}
                            onChange={props.stateInputChangeHandler}
                            onBlur={props.stateInputBlurHandler}
                            error={props.stateInputIsInvalid}
                            // helperText={
                            //     props.stateInputIsInvalid
                            //         ? "Please enter valid state name!"
                            //         : ""
                            // }
                        />

                        <TextField
                            label="Post code"
                            fullWidth
                            variant="outlined"
                            value={props.enteredPostalCode}
                            onChange={props.postalCodeInputChangeHandler}
                            onBlur={props.postalCodeInputBlurHandler}
                            error={props.postalCodeInputIsInvalid}
                            // helperText={
                            //     props.postalCodeInputIsInvalid
                            //         ? "Please enter valid postal code!"
                            //         : ""
                            // }
                        />
                    </div>

                    <div className="twoLayout">
                        <Typography variant="p" sx={{ width: "100%" }}>
                            Country
                        </Typography>
                        <FormControl fullWidth>
                            <SelectR
                                options={props.countryOptions}
                                value={props.enteredCountry}
                                onBlur={props.countryInputBlurHandler}
                                onChange={props.countryInputChangeHandler}
                            />
                            {props.countryInputIsInvalid && (
                                <p className="error">Please select a country</p>
                            )}
                        </FormControl>
                    </div>

                    <div className="companyWrapper">
                        <Typography variant="p" sx={{ width: "100%" }}>
                            Time Zone
                        </Typography>
                        <FormControl fullWidth>
                            <div className="twoLayout">
                                <div>
                                    <TimezoneSelect
                                        className="timezoneCustom"
                                        value={props.tz}
                                        onChange={props.setTz}
                                        onBlur={props.TimeZoneInputBlurHandler}
                                        timezones={{
                                            ...allTimezones,
                                            "America/Lima": "Pittsburgh",
                                            "Europe/Berlin": "Frankfurt",
                                        }}
                                    />
                                    {props.TimeZoneInputIsInvalid && (
                                        <p className="error">Please select a time zone</p>
                                    )}
                                </div>
                            </div>
                        </FormControl>
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default BusinessDetails;
