import axios from "axios";
import authHeader from "./AuthHeader";
import * as URL from "../Constants"


const SLOTS_URL = URL.API_URL + "/api/booking/slots"
const BOOKING_URL = URL.API_URL + "/api/booking"
const BOOKING_URL_THIS_WEEK = URL.API_URL + "/api/booking/current"
const FUTURE_BOOKING_URL = URL.API_URL + "/api/booking/future"
const PAST_BOOKING_URL = URL.API_URL + "/api/booking/past"
const CALENDAR_BOOKING_URL = URL.API_URL + "/api/booking/calendar"

class BookingService {
    findFreeSlots(serviceId, userId, companyId, day, selectedServicesList) {
        return axios.post(SLOTS_URL, {
            serviceId: serviceId,
            userId: userId,
            companyId: companyId,
            day: day,
            servicesList:selectedServicesList
        })
    }

    bookAppointment(serviceId, userId, customerDto,companyId, day, timeSlot,serviceList) {
        return axios.post(BOOKING_URL, {
            serviceId: serviceId,
            staffId: userId,
            companyId: companyId,
            customerDto:customerDto,
            day: day,
            timeSlot:timeSlot,
            serviceList:serviceList
        }, {headers: authHeader()})
    }

    bookExternalAppointment(serviceId, userId, customerDto,companyId, day, timeSlot) {
        return axios.post(BOOKING_URL, {
            serviceId: serviceId,
            staffId: userId,
            companyId: companyId,
            customerDto:customerDto,
            day: day,
            timeSlot:timeSlot
        })
    }

    getAllBookingsByCompany(companyId){
        return axios.get(BOOKING_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    getCurrentWeeksBookings(companyId){
        return axios.get(BOOKING_URL_THIS_WEEK, {params: {companyId: companyId}, headers: authHeader()});
    }

    getFutureBookings(companyId){
        return axios.get(FUTURE_BOOKING_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    getPastBookings(companyId){
        return axios.get(PAST_BOOKING_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    getCalendarBookingsForCompany(companyId){
        return axios.get(CALENDAR_BOOKING_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    deleteBooking(bookingId){
        return axios.delete(BOOKING_URL, {params: {bookingId: bookingId}, headers: authHeader()});
    }
}

export default new BookingService()