import React, {useContext} from "react";
import BookingContext from "../../../context/bookingContext";
import OnboardService from "../../../../../../services/OnboardService";
import {useHistory} from "react-router-dom";
import BookingService from "../../../../../../services/BookingService";
import bookingContext from "../../../context/bookingContext";
import * as URL from "Constants"
import {FE_URL} from "Constants";

const NavigationButton = ({goNext, goPrevious, selectedIndex, list, proceedNext}) => {
    const {bookingDetails, setBookingDetails, setProceedNext} = useContext(bookingContext); // Context API
    const history = useHistory();


    function onFinish() {
        const data = {...bookingDetails}
        console.log(data)
        const bookingData = {...bookingDetails}.bookingData
        const customerDto = {...bookingDetails}.customerDto
        BookingService.bookExternalAppointment(bookingData.service, bookingData.staff, customerDto, bookingData.company, bookingData.bookingDate, bookingData.timeSlot).then(
            (response) => {
                window.location.href = URL.FE_URL+ "/auth/booking-success";
            },
            (error) => {
                window.location.href = URL.FE_URL+ "/auth/onboarding-failure";
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    return (
        <>
            <div className="navigateBtn">
                <button disabled={selectedIndex === 0} onClick={goPrevious} className="btn btn-primary">
                    Previous
                </button>
                {" "}
                {selectedIndex !== list.length - 1 && (
                    <button onClick={goNext} disabled={!proceedNext} className="btn btn-primary">
                        {selectedIndex !== list.length - 1 ? "Next" : "Finish"}
                    </button>
                )}
                {selectedIndex == list.length - 1 && (
                    <button onClick={onFinish} disabled={!proceedNext} className="btn btn-primary">
                        {selectedIndex !== list.length - 1 ? "Next" : "Finish"}
                    </button>
                )}
            </div>
        </>
    )
}


export default NavigationButton;
