import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

const Support = (props) => {

    const supportText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SUPPORT_MESSAGE_DE
            : TRANSLATION.SUPPORT_MESSAGE_EN
    };

    const supportBookingText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SUPPORT_BOOKING_DE
            : TRANSLATION.SUPPORT_BOOKING_EN
    };

    return (
        <>
            <p>{supportText()}</p>
            <p>
                {supportBookingText()} <a
                href="https://sakthi.bookmyagenda.com">link</a></p>
        </>
    )
}

export default Support