import axios from 'axios'
import * as URL from "../Constants"

const AUTH_URL = URL.API_URL+ "/api/auth/";
const UPDATE_PASSWORD_URL = URL.API_URL+ "/api/auth/initiate-password-request"
const RESET_PASSWORD_URL = URL.API_URL + "/api/auth/reset-password"


class AuthService {
    login(email, password,idToken) {
        return axios
            .post(AUTH_URL + "signin", {
                email,
                password,
                idToken
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                if(response.data.companyOnboarded === true){
                    localStorage.setItem('isCompanyOnboarded', 'true')
                } else{
                    localStorage.setItem('isCompanyOnboarded', 'false')
                }
                localStorage.setItem("language", JSON.stringify(response.data.language));
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem('isCompanyOnboarded')
    }


    register(email, password, name,company,idToken) {
        return axios.post(AUTH_URL + "signup", {
            email,
            password,
            company,
            name,
            idToken
        }).then(response =>{
            localStorage.setItem("userId", JSON.stringify(response.data));
            return response.data
        })
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getCurrentLanguage(){
        return "DE"
    }
    emailPassword(email) {
        return axios.get(UPDATE_PASSWORD_URL, {params: {email: email}});
    }

    setNewPassword(password, token) {
        return axios.post(RESET_PASSWORD_URL, {
            password,
            token
        })
    }
}

export default new AuthService();
