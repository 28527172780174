import React from "react";
import AuthHeader from "../../../components/Headers/AuthHeader";
import {
    Button, Card, CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import AuthService from "../../../services/AuthService";
import styles from "../../../assets/css/styles";

function ForgotPassword() {

    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const [email, setEmail] = React.useState("NA");

    function onSubmit() {
        AuthService.emailPassword(email).then(
          (response) => {
            history.push("/auth/email-password-reset");
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }

  return (
    <>
      <AuthHeader
        title="Forgot Password"
        lead="Enter your registered email id to set new password"
      />
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="6" md="8">
                    <Card className="bg-secondary border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                                <Input
                                    name="email"
                                    {...register("email", { required: true })}
                                    placeholder="Email"
                                    style={styles.inputWithExtraBottom}
                                    onChange={(e) => setEmail(`${e.target.value}`)}
                                />
                                <button onClick={onSubmit} class="btn btn-primary" type="submit">Submit</button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default ForgotPassword;
