import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import React, {useState} from "react";
import BookingService from "../../../services/BookingService";
import AuthService from "../../../services/AuthService";
import {Col, Container, Modal} from "reactstrap";
import EventDetailsCard from "./EventDetailsCard";
import CompanyService from "../../../services/CompanyService";
import {LogoutIfJwtExpired} from "../../../global/Utils";
import {useHistory} from "react-router-dom";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

function CalendarMain() {
    const localizer = momentLocalizer(moment)
    const history = useHistory();

    const [bookings, setBookings] = React.useState([])
    const [formModal, setFormModal] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [staff, setStaff] = useState([]);

    const selectStaffText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SELECT_STAFF_DE
            : TRANSLATION.SELECT_STAFF_EN;
    };
    const onEventClick = event => {
        setFormModal(true)
        setSelectedEvent(event)
    }

    React.useEffect(async () => {
        await convertDatesInUTCAndFilterBookings()
        CompanyService.getStaff(AuthService.getCurrentUser().companyId).then(
            (response) => {
                console.log(response.data)
                setStaff(response.data);
            },
            (error) => {
                LogoutIfJwtExpired(error)
                {history.push("/")}
            }
        );
    }, [])


    const convertDatesInUTCAndFilterBookings = async () => {
        let response = null
        try{
            response = await BookingService.getCalendarBookingsForCompany(AuthService.getCurrentUser().companyId)
        } catch(error){
            LogoutIfJwtExpired(error)
            {history.push("/")}
        }
        // const response = await BookingService.getCalendarBookingsForCompany(AuthService.getCurrentUser().companyId)
        let appointments = response.data;
        const filteredAppointments = appointments.filter(function (el) {
            return el != null;
        });
        for (const filteredAppointment of filteredAppointments) {
            filteredAppointment.start = moment.utc(filteredAppointment.start).toDate()
            filteredAppointment.end = new Date(filteredAppointment.end)
        }
        setBookings(filteredAppointments)
        return filteredAppointments
    }


    async function handleChangeStaff(event) {
       const bookings = await convertDatesInUTCAndFilterBookings()
        setBookings(bookings)
        const value = staff.filter(function (item) {
            let selectedIndex = event.target.options.selectedIndex;
            return item.staffId === event.target.options[selectedIndex].getAttribute('data-key')
        });
        let staffFilteredBookings = bookings.filter(function (el) {
            return el.staffId === value[0].staffId;
        });
        setBookings(staffFilteredBookings)
    }

    return (
        <>
            <select onChange={handleChangeStaff} name="fruits" id="fruit-select" value={null}>
                <option value="none" selected disabled hidden>{selectStaffText()}</option>
                {staff.map(option => (
                    <option key={option.staffId} data-key={option.staffId} value={option.name}>
                        {option.name}
                    </option>
                ))}
            </select>

            <Calendar
                events={bookings}
                onSelectEvent={onEventClick}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{height: 750}}
            />

            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={formModal}
                toggle={() => setFormModal(false)}
            >
                <div className="modal-header">
                    <EventDetailsCard event={selectedEvent}></EventDetailsCard>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            setFormModal(false)
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            </Modal>
        </>

    )
}

export default CalendarMain