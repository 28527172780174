import {useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ServiceContext from "./context/ServiceContext";
import Services from "./Services";
import ServiceGroup from "./ServiceGroup";
import Categories2 from "../Categories/Categories2";
import Categories from "../Categories/Categories";

const InitialValues = {
    ServiceData: {}
}

const ServiceMultiTab = (props) => {

    const [serviceDetails, setServiceDetails] = useState(InitialValues);

    return(
        <ServiceContext.Provider value={{serviceDetails, setServiceDetails}}>
            <Tabs>
                <TabList>
                    <Tab>Services</Tab>
                </TabList>
                <TabPanel>
                    <Categories/>
                </TabPanel>
            </Tabs>
        </ServiceContext.Provider>
    )

}

export default ServiceMultiTab