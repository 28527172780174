import {Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, Row, Table} from "reactstrap";
import React from "react";
import DeleteBookingModal from "./DeleteBookingModal";

const EventDetailsCard = (props) => {

    const [formModal, setFormModal] = React.useState(false);
    const deleteEvent = () =>{
        setFormModal(true)
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={formModal}
                toggle={() => setFormModal(false)}
            >
                <div className="modal-header">
                    <DeleteBookingModal bookingId={props.event.id}></DeleteBookingModal>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            setFormModal(false)
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            </Modal>

            <Container>
                <Row>
                    <Card className="justify-content-center">
                        <CardHeader className="border-0">
                            <Row>
                                <Col xs="6">
                                    <h3 className="mb-0">Event Details</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm="5">
                                    <label>Event Name </label>

                                </Col>
                                <Col sm="7">
                                    <b>
                                        {props.event.eventName}
                                    </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="5">
                                    <label>Provider </label>
                                </Col>
                                <Col lg="7">
                                    <b>
                                        {props.event.provider}
                                    </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="5">
                                    <label>Customer </label>
                                </Col>
                                <Col lg="7">
                                    <b>
                                        {props.event.customerName ? props.event.customerName : "NA"}
                                    </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="5">
                                    <label>Customer Email</label>
                                </Col>
                                <Col lg="7">
                                    <b>
                                        {props.event.customerEmail ? props.event.customerEmail : "NA"}
                                    </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="5">
                                    <label>Customer Phone</label>
                                </Col>
                                <Col lg="7">
                                    <b>
                                        {props.event.customerPhone ? props.event.customerPhone : "NA"}
                                    </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="5">
                                    <label>Start Time </label>
                                </Col>
                                <Col lg="7">
                                    <b>
                                        {props.event.start.toString()}
                                    </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="5">
                                    <label>End Time </label>
                                </Col>
                                <Col lg="7">
                                    <b>
                                        {props.event.end.toString()}
                                    </b>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <Row>
                    <Button color="info"
                            onClick={deleteEvent}
                            type="submit">Delete Event</Button>
                </Row>
            </Container>

        </>
    )
}

export default EventDetailsCard