import React, {useState} from 'react';
import BookingContext from './context/bookingContext'; // Context API

import "./App.css";
import MultiStepForm from "./components/common/MultiStepForm/MultiStepForm";
import ExternalBookingMain from "./components/booking-process/ExternalBookingMain";
import CustomerDetails from "./components/booking-process/CustomerDetails";

// List of components to switch inside the multi-form container
const componentsList = [
    {headerText: 'Booking Details', headerIcon: 'fa fa-users', component: <ExternalBookingMain/>},
    {headerText: 'Summary', headerIcon: 'fa fa-users', component: <CustomerDetails/>},
];

// Supplying initial state values to use with different components.
const InitialValues = {
    bookingData: {
        staff:"",
        staffName:"",
        service:"",
        serviceName:"",
        company:"",
        bookingDate:"",
        timeSlot:""
    },
    customerDto: {
        name:null,
        email:null,
        phone:null
    }
}

function ExternalBooking() {

    // State to store checkout details
    // This is used to prevent losing unsaved changes when you switch the component.
    const [bookingDetails, setBookingDetails] = useState(InitialValues);
    const [proceedNext, setProceedNext] = useState(true); // Enable or Disable 'Next' button within each component

    return (
        <div className="App">

            <BookingContext.Provider value={{bookingDetails: bookingDetails, setBookingDetails: setBookingDetails, setProceedNext}}>
                <MultiStepForm
                    list={componentsList}
                    displayProgressBar={true} // Set this to false if you don't want to display the progress bar.
                    proceedNext={proceedNext} // Optional props. To Enable/Disable 'Next' button from child component.
                />
            </BookingContext.Provider>

        </div>
    );
}

export default ExternalBooking;
