import React, {useState, useEffect} from "react";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, Row} from "reactstrap";
import AuthService from "../../../services/AuthService";
import styles from "../../../assets/css/styles";
import CompanyService from "../../../services/CompanyService";
import {useForm} from "react-hook-form";
import CustomerList from "./CustomerList";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

const CustomerPage = props => {
    const [formModal, setformModal] = React.useState(false);

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const {register, handleSubmit} = useForm();

    const addCustomerText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ADD_CUSTOMER_DE
            : TRANSLATION.ADD_CUSTOMER_EN
    };
    function addCustomer(companyId, name, email, phone) {
        CompanyService.addCustomer(companyId, name, email, phone).then(
            (response) => {
                console.log(response.data)
                setformModal(false)
                window.location.reload(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    return (
        <>
            <SimpleHeader name="Customer"/>
            <Row>
                <Container className="mt--6" fluid>
                    <Card style={{backgroundColor: '#4DD0E1'}}>
                        <CardHeader className="border-0" style={{backgroundColor: '#4DD0E1'}}>
                            <Row>
                                <Col xs="6">
                                    <Button
                                        block
                                        className={"btn-primary"}
                                        color="primary"
                                        onClick={() => setformModal(true)}
                                    >
                                        {addCustomerText()}
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>

                        </CardBody>
                    </Card>
                    <Modal
                        //className="modal-dialog-centered"
                        // size="lg"
                        isOpen={formModal}
                        toggle={() => setformModal(true)}
                        animation={false}
                    >
                        <div className="modal-header">
                            <Container>
                                <Row className="align-items-center">
                                    <Col md="4">
                                        <h4>Name</h4>
                                    </Col>
                                    <Col md="8">
                                        <Input
                                            name="name"
                                            {...register("name", {required: true})}
                                            onChange={(e) => setName(`${e.target.value}`)}
                                            style={styles.input}
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md="4">
                                        <h4>Email</h4>
                                    </Col>
                                    <Col md="8">
                                        <Input
                                            name="email"
                                            {...register("email", {required: true})}
                                            onChange={(e) => setEmail(`${e.target.value}`)}
                                            style={styles.input}
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md="4">
                                        <h4>Phone</h4>
                                    </Col>
                                    <Col md="8">
                                        <Input
                                            name="phone"
                                            {...register("phone", {required: true})}
                                            onChange={(e) => setPhone(`${e.target.value}`)}
                                            style={styles.input}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button color="info"
                                                onClick={() => addCustomer(AuthService.getCurrentUser().companyId, name, email,phone)}
                                                type="submit">Submit</Button>
                                    </Col>
                                </Row>
                            </Container>

                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => setformModal(false)}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                    </Modal>
                    <CustomerList></CustomerList>
                </Container>
            </Row>
        </>
    )
}

export default CustomerPage