import axios from 'axios';
import authHeader from './AuthHeader';
import authService from './AuthService';
import * as URL from "../Constants"

const ONBOARD_USER = URL.API_URL + '/api/onboard/user'

class OnboardService{
    onboardNewUser(company,workingHours,employeeList,resourceList) {
        return axios.post(ONBOARD_USER, {
            userId: authService.getCurrentUser().id,
            company:company,
            workingHours:workingHours,
            employees:employeeList,
            resources:resourceList
        }, {headers: authHeader()})
    }
}

export default new OnboardService();
