import {Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import styles from "../../../assets/css/styles";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import CompanyService from "../../../services/CompanyService";
import AuthService from "../../../services/AuthService";
import TranslationService, {SUCCESSFUL_BOOKING_MESSAGE_EN} from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";
import {LogoutIfJwtExpired} from "../../../global/Utils";
import companyService from "../../../services/CompanyService";
import {useHistory} from "react-router-dom";

const BookingPageCustomisation = (props) => {
    const history = useHistory();
    const [bookingMessage, setBookingMessage] = React.useState("NA");
    const [isBookingMessage, setIsBookingMessage] = useState(false)
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [failureAlert, setFailureAlert] = React.useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false)


    React.useEffect(() => {
        if (AuthService.getCurrentUser()) {
            CompanyService.getCompany(AuthService.getCurrentUser().id).then(
                (response) => {
                    console.log(response.data)
                    setBookingMessage(response.data.bookingMessage);
                },
                (error) => {
                    LogoutIfJwtExpired(error)
                    {
                        history.push("/")
                    }
                }
            );
            setRefreshFlag(false)
        }else{
            LogoutIfJwtExpired(null)
            {history.push("/")}
        }
    }, [refreshFlag])
    const bookingMessageText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SUCCESSFUL_BOOKING_MESSAGE_DE
            : TRANSLATION.SUCCESSFUL_BOOKING_MESSAGE_EN;
    };
    const editText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.EDIT_DE
            : TRANSLATION.EDIT_EN;
    };

    const saveText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SAVE_DE
            : TRANSLATION.SAVE_EN;
    };

    const cancelText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CANCEL_DE
            : TRANSLATION.CANCEL_EN;
    };
    const onSave = () => {
        CompanyService.updateCompanyBookingMessage(AuthService.getCurrentUser().companyId, bookingMessage).then(
            (response) => {
                console.log(response.data)
                setRefreshFlag(true)
                setSuccessAlert(true)
                setTimeout(() => {
                    setSuccessAlert(false);
                }, 1000);
                setRefreshFlag(true)
            },
            (error) => {
                setFailureAlert(true)
                setTimeout(() => {
                    setFailureAlert(false);
                }, 1500);
                setRefreshFlag(true)
            }
        );

    }

    return (
        <>
            <Card>
                <CardHeader>
                    <h3 className="mb-0">{bookingMessageText()}</h3>
                </CardHeader>
                <CardBody>

                    <Row>
                        <Col lg="6">
                            {!isBookingMessage ? (
                                <React.Fragment>
                                    <label>{bookingMessage}</label>
                                </React.Fragment>) : (
                                <Input
                                    placeholder={props.companyName}
                                    type="text"
                                    value={bookingMessage}
                                    onChange={(event) => setBookingMessage(event.target.value)}
                                />
                            )}
                        </Col>
                        <Col lg="3">
                            { isBookingMessage ? (
                                    <React.Fragment>
                                        <button
                                            className={"btn-success"}
                                            onClick={() => onSave({})}
                                        >
                                            {saveText()}
                                        </button>

                                        <button
                                            className={"btn-secondary"}
                                            style={{marginLeft: 8}}
                                            onClick={() => setIsBookingMessage(false)
                                            }
                                        >
                                            {cancelText()}
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <button
                                        className={"btn-primary"}
                                        onClick={() => setIsBookingMessage(true)
                                        }
                                    >
                                        {editText()}
                                    </button>
                                )
                            }
                        </Col>
                        {successAlert && <div className='alert-container'>
                            <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Details Saved
                                successfully!!
                            </div>
                        </div>}
                        {failureAlert && <div className='alert-container'>
                            <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Error, please try again!
                            </div>
                        </div>}
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default BookingPageCustomisation