import {Button, TextField} from "@mui/material";
import "./AddCategory.css";
import React, {useState} from "react";
import useInput from "../../helper/useInput";
import axiosInstance from "../../helper/AxiosMainHelper";
import {LoadingButton} from "@mui/lab";
import {successToast} from "../../helper/ToastHelper";
import ToggleButton from "@mui/material/ToggleButton";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from '@mui/material/Checkbox';


function EditService({
                         setOpenModal,
                         service,
                         companyId,
                         resourceGroupId,
                         fetchCategories,
                     }) {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState(service.video);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        console.log(checked)
        console.log(event.target.checked)

        const payload = {
            id: service.id,
            name: enteredName,
            description: enteredDescription,
            amount: enteredAmount,
            duration: enteredDuration,
            resourceGroupId: resourceGroupId,
            companyId: companyId,
            video: event.target.checked
        };
        try {
            const response = await axiosInstance.patch(`appointment`, payload);

            if (response.status === 200) {
                fetchCategories();
                setOpenModal(false);
                successToast("Service updated successfully!");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    let {
        enteredInput: enteredName,
        inputInvalid: nameInputIsInvalid,
        blurHandler: nameInputBlurHandler,
        changeHandler: nameInputChangeHandler,
    } = useInput(service.name, (value) => value !== "");

    let {
        enteredInput: enteredDescription,
        inputInvalid: descriptionInputIsInvalid,
        blurHandler: descriptionInputBlurHandler,
        changeHandler: descriptionInputChangeHandler,
    } = useInput(service.description, (value) => value !== "");

    let {
        enteredInput: enteredAmount,
        inputInvalid: amountInputIsInvalid,
        blurHandler: amountInputBlurHandler,
        changeHandler: amountInputChangeHandler,
    } = useInput(service.amount, (value) => value !== "");

    let {
        enteredInput: enteredDuration,
        inputInvalid: durationInputIsInvalid,
        blurHandler: durationInputBlurHandler,
        changeHandler: durationInputChangeHandler,
    } = useInput(service.duration, (value) => value !== "");

    const handlerSubmit = async (e) => {
        e.preventDefault();

        nameInputBlurHandler();
        descriptionInputBlurHandler();
        amountInputBlurHandler();
        durationInputBlurHandler();

        if (
            !enteredName ||
            !enteredDescription ||
            !enteredAmount ||
            !enteredDuration
        ) {
            return;
        }

        setLoading(true);
        const payload = {
            id: service.id,
            name: enteredName,
            description: enteredDescription,
            amount: enteredAmount,
            duration: enteredDuration,
            resourceGroupId: resourceGroupId,
            companyId: companyId,
            video: checked
        };
        try {
            const response = await axiosInstance.patch(`appointment`, payload);

            if (response.status === 200) {
                fetchCategories();
                setOpenModal(false);
                successToast("Service updated successfully!");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="booking-form-container create-category">
            <h2>Update service</h2>
            <form className="formWrapper">
                {/*<div className="form-control">*/}
                <TextField
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={enteredName}
                    onChange={nameInputChangeHandler}
                    onBlur={nameInputBlurHandler}
                    error={nameInputIsInvalid}
                    helperText={nameInputIsInvalid ? "Please enter valid name!" : ""}
                />
                {/*</div>*/}
                {/*<div className="form-control">*/}
                <TextField
                    label="Description"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={enteredDescription}
                    onChange={descriptionInputChangeHandler}
                    onBlur={descriptionInputBlurHandler}
                    error={descriptionInputIsInvalid}
                    helperText={
                        descriptionInputIsInvalid ? "Please enter valid Description!" : ""
                    }
                />
                {/*</div>*/}
                {/*<div className="form-control">*/}
                <TextField
                    label="Amount"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={enteredAmount}
                    onChange={amountInputChangeHandler}
                    onBlur={amountInputBlurHandler}
                    error={amountInputIsInvalid}
                    helperText={
                        amountInputIsInvalid ? "Please enter valid amount!" : ""
                    }
                />
                {/*</div>*/}
                {/*<div className="form-control">*/}
                <TextField
                    label="Duration (min)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={enteredDuration}
                    onChange={durationInputChangeHandler}
                    onBlur={durationInputBlurHandler}
                    error={durationInputIsInvalid}
                    helperText={
                        durationInputIsInvalid ? "Please enter valid duration!" : ""
                    }
                />
                <>
                    <div>
                        Enable Video Conferencing
                        {/*<ToggleButton*/}
                        {/*    value="check"*/}
                        {/*    selected={selected}*/}
                        {/*    onChange={() => {*/}
                        {/*        setSelected(!selected);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <CheckIcon/>*/}
                        {/*</ToggleButton>*/}
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <div>
                        (Check this if you like to have google hangout link sent for this event type to your customer.
                        Also only applicable if google calendar integration is done)
                    </div>

                </>


                {/*</div>*/}

                <div className="btns">
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        sx={{mr: 1}}
                    >
                        Close
                    </Button>

                    <LoadingButton
                        onClick={handlerSubmit}
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                        loadingIndicator="updating..."
                        sx={{mr: 1}}
                    >
                        Update
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
}

export default EditService;
