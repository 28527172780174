import AuthHeader from "../../../components/Headers/AuthHeader";

function ErrorOnboardin() {
    return (
        <>
            <AuthHeader
                title="Appointment Booking Error"
                lead="Oops something went wrong, Please check your mail if you have a booking confirmation, if not please book again!"
            />
        </>
    );

}

export default ErrorOnboardin
