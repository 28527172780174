import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React from "react";
import {
    Button, Card, CardBody, CardHeader, CardImg,
    Col,
    Container,
    Modal,
    Row,
} from "reactstrap";
import BookingModal from "./BookingModal";
import BookingService from "../../../services/BookingService";
import AuthService from "../../../services/AuthService";
import styles from "../../../assets/css/styles";
import BookingMultiTabs from "./BookingMultiTabs"
import {LogoutIfJwtExpired} from "../../../global/Utils";
import {useHistory} from "react-router-dom";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

function BookingsHome() {
    const [formModal, setformModal] = React.useState(false);
    const [userId, setuserId] = React.useState(null)
    const [customerId, setCustomerId] = React.useState(null)
    const [serviceId, setServiceId] = React.useState(null)
    const [servicesList, setServicesList] = React.useState(null)
    const [bookingDate, setBookingDate] = React.useState(null)
    const [timeSlot, setTimeSlot] = React.useState(null)

    const [bookings, setBookings] = React.useState(null)
    const [futureBookings, setFutureBookings] = React.useState(null)
    const [pastBookings, setPastBookings] = React.useState(null)

    const history = useHistory();

    const addBookingText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ADD_BOOKING_DE
            : TRANSLATION.ADD_BOOKING_EN;
    };

    const confirmText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CONFIRM_DE
            : TRANSLATION.CONFIRM_EN;
    };
    const getUserId = (userId) => {
        setuserId(userId)
    }

    const getCustomerId = (customerId) => {
        setCustomerId(customerId)
    }

    const getServiceId = (serviceId) => {
        setServiceId(serviceId)
    }

    const getServicesList = (servicesList) => {
        setServicesList(servicesList)
    }
    const getBookingDate = (date) => {
        setBookingDate(date)
    }

    const getTimeSlot = (timeSlot) => {
        setTimeSlot(timeSlot)
    }

    const deleteBooking = (bookingId) => {
        BookingService.deleteBooking(bookingId).then(
            (response) => {
                console.log(response.data)
            },
            (error) => {
            }
        );
        setTimeout(() => {
            window.location.reload(false);
        }, 1000);
    }

    React.useEffect(() => {
        if (AuthService.getCurrentUser()) {
            BookingService.getCurrentWeeksBookings(AuthService.getCurrentUser().companyId).then(
                (response) => {
                    setBookings(response.data)
                },
                (error) => {
                    LogoutIfJwtExpired(error)
                    {
                        history.push("/")
                    }
                }
            );

            BookingService.getFutureBookings(AuthService.getCurrentUser().companyId).then(
                (response) => {
                    setFutureBookings(response.data)
                },
                (error) => {
                    // LogoutIfJwtExpired(error)
                    // {history.push("/")}
                }
            );

            BookingService.getPastBookings(AuthService.getCurrentUser().companyId).then(
                (response) => {
                    setPastBookings(response.data)
                },
                (error) => {
                    // LogoutIfJwtExpired(error)
                    // {history.push("/")}
                }
            );
        }
    }, [])

    return (
        <>
            <SimpleHeader name="Bookings"/>
            <Row>
                <Container className="mt--6" fluid>
                    <Card style={{backgroundColor: '#4DD0E1'}}>
                        <CardHeader className="border-0" style={{backgroundColor: '#4DD0E1'}}>
                            <Row>
                                <Col xs="6">
                                    <Button
                                        block
                                        className={"btn-primary"}
                                        color="primary"
                                        onClick={() => setformModal(true)}
                                    >
                                        {addBookingText()}
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>

                        </CardBody>
                    </Card>
                    <Modal
                        size="lg"
                        isOpen={formModal}
                        toggle={() => setformModal(true)}
                        animation={false}
                    >
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                setformModal(false)
                                setTimeSlot(null)
                                window.location.reload(false);
                            }}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                        <Container>
                            <Row>
                                <Col>
                                    <BookingModal onChangeUserId={getUserId} onChangeServiceId={getServiceId}
                                                  onChangeCustomerId={getCustomerId}
                                                  onChangeBookingDate={getBookingDate}
                                                  onChangeTimeSlot={getTimeSlot}
                                                  onChangeServicesList={getServicesList}
                                    ></BookingModal>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {timeSlot ? (
                                        <Button
                                            className={"btn btn-info"}
                                            style={{marginLeft: 8}}
                                            onClick={e => {
                                                const details = {
                                                    customerDto: {
                                                        id: customerId,
                                                        type: 'internal'
                                                    }
                                                }
                                                BookingService.bookAppointment(
                                                    serviceId, userId, details.customerDto, AuthService.getCurrentUser().companyId, bookingDate,
                                                    timeSlot, servicesList
                                                )
                                                setformModal(false)
                                                window.location.reload(false);
                                            }
                                            }
                                            style={styles.inputWithExtraBottom}
                                        >
                                            {confirmText()}
                                        </Button>

                                    ) : null}

                                </Col>
                            </Row>
                        </Container>
                    </Modal>
                    <BookingMultiTabs bookings={bookings}
                                      futureBookings={futureBookings}
                                      pastBookings={pastBookings}
                                      deleteBooking={deleteBooking}>
                    </BookingMultiTabs>
                </Container>
            </Row>
        </>
    );
}

export default BookingsHome;
