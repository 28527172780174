import { Button, TextField } from "@mui/material";
import "./AddCategory.css";
import React, { useState } from "react";
import useInput from "../../helper/useInput";
import axiosInstance from "../../helper/AxiosMainHelper";
import { LoadingButton } from "@mui/lab";
import { successToast } from "../../helper/ToastHelper";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

function AddService({
                        setOpenModal,
                        resourceGroupId,
                        companyId,
                        fetchCategories,
                        isOnboardPage = false,
                        setCategoryList,
                    }) {
    const [loading, setLoading] = useState(false);
    let {
        enteredInput: enteredName,
        inputInvalid: nameInputIsInvalid,
        blurHandler: nameInputBlurHandler,
        changeHandler: nameInputChangeHandler,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredDescription,
        inputInvalid: descriptionInputIsInvalid,
        blurHandler: descriptionInputBlurHandler,
        changeHandler: descriptionInputChangeHandler,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredAmount,
        inputInvalid: amountInputIsInvalid,
        blurHandler: amountInputBlurHandler,
        changeHandler: amountInputChangeHandler,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredDuration,
        inputInvalid: durationInputIsInvalid,
        blurHandler: durationInputBlurHandler,
        changeHandler: durationInputChangeHandler,
    } = useInput("", (value) => value !== "");

    const serviceDescriptionText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_DESCRIPTION_DE
            : TRANSLATION.SERVICE_DESCRIPTION_EN
    };

    const serviceDurationText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_DURATION_DE
            : TRANSLATION.SERVICE_DURATION_EN
    };
    const serviceAmountText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_AMOUNT_DE
            : TRANSLATION.SERVICE_AMOUNT_EN
    };
    const handlerSubmit = async (e) => {
        e.preventDefault();

        nameInputBlurHandler();
        descriptionInputBlurHandler();
        amountInputBlurHandler();
        durationInputBlurHandler();

        if (
            !enteredName ||
            !enteredDescription ||
            !enteredAmount ||
            !enteredDuration
        ) {
            return;
        }

        setLoading(true);
        const payload = {
            name: enteredName,
            description: enteredDescription,
            amount: enteredAmount,
            duration: enteredDuration,
            resourceGroupId: resourceGroupId,
        };

        if (isOnboardPage) {
            setCategoryList((prev) => {
                return prev.map((s) => {
                    if (s.groupId === resourceGroupId) {
                        return {
                            ...s,
                            servicesList: [
                                ...s.servicesList,
                                {
                                    ...payload,
                                    id: `${s.servicesList.length + 1}-${enteredName}`,
                                },
                            ],
                        };
                    } else {
                        return s;
                    }
                });
            });
            setLoading(false);
            setOpenModal(false);
        } else {
            try {
                const response = await axiosInstance.post(`appointment`, {
                    ...payload,
                    companyId: companyId,
                });

                if (response.status === 200) {
                    fetchCategories();
                    setOpenModal(false);
                    successToast("Service created successfully!");
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    };

    return (
        <div className="booking-form-container create-category">
            <h2>Add new service</h2>
            <form className="formWrapper">
                    <TextField
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={enteredName}
                        onChange={nameInputChangeHandler}
                        onBlur={nameInputBlurHandler}
                        error={nameInputIsInvalid}
                        helperText={nameInputIsInvalid ? "Please enter valid name!" : ""}
                    />
                    <TextField
                        label={serviceDescriptionText()}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={enteredDescription}
                        onChange={descriptionInputChangeHandler}
                        onBlur={descriptionInputBlurHandler}
                        error={descriptionInputIsInvalid}
                        helperText={
                            descriptionInputIsInvalid ? "Please enter valid Description!" : ""
                        }
                    />
                    <TextField
                        label={serviceAmountText()}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={enteredAmount}
                        onChange={amountInputChangeHandler}
                        onBlur={amountInputBlurHandler}
                        error={amountInputIsInvalid}
                        helperText={
                            amountInputIsInvalid ? "Please enter valid amount!" : ""
                        }
                    />
                    <TextField
                        label={serviceDurationText()}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={enteredDuration}
                        onChange={durationInputChangeHandler}
                        onBlur={durationInputBlurHandler}
                        error={durationInputIsInvalid}
                        helperText={
                            durationInputIsInvalid ? "Please enter valid duration!" : ""
                        }
                    />

                <div className="btns">
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        sx={{ mr: 1 }}
                    >
                        Close
                    </Button>

                    <LoadingButton
                        onClick={handlerSubmit}
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                        loadingIndicator="Creating..."
                        sx={{ mr: 1 }}
                    >
                        Create
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
}

export default AddService;

