import {Card, CardHeader, Col, Row, Table} from "reactstrap";
import React, {useState} from "react";
import CompanyService from "../../../../services/CompanyService";
import AuthService from "../../../../services/AuthService";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const StaffResourcesCard = (props) => {

    // let [staffResourcesData, setStaffResourcesData] = useState(props.data);
    const resourcesText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICES_DE
            : TRANSLATION.SERVICES_EN
    };

    const serviceDescriptionText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_DESCRIPTION_DE
            : TRANSLATION.SERVICE_DESCRIPTION_EN
    };

    const serviceDurationText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_DURATION_DE
            : TRANSLATION.SERVICE_DURATION_EN
    };
    const serviceAmountText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_AMOUNT_DE
            : TRANSLATION.SERVICE_AMOUNT_EN
    };
    function handleResourceClick(userId, resourceId, isActive) {
        CompanyService.updateStaff(userId, resourceId, isActive,AuthService.getCurrentUser().companyId, null).then(
            (response) => {
                props.staffFlagUpdate(true)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    return(
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">{resourcesText()}</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" hover responsive>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>{serviceDescriptionText()}</th>
                        <th>{serviceAmountText()}</th>
                        <th>{serviceDurationText()}</th>
                        {/*<th>Active</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.data.filter(item => item.staffId === props.inEditMode.rowKey).map((item) =>
                            item.resourceDtoList.map((resource) =>
                                <tr key={resource.id}>
                                    <td>{resource.name}</td>
                                    <td>{resource.description}</td>
                                    <td>{resource.amount}</td>
                                    <td>{resource.duration}</td>

                                    <td>
                                        <label className="custom-toggle">
                                            <input defaultChecked={resource.valid}
                                                   type="checkbox"
                                                   name="fridayOpen"
                                                   onClick={() => handleResourceClick(props.inEditMode.rowKey, resource.id, !resource.valid)}/>
                                            <span
                                                className="custom-toggle-slider rounded-circle"
                                                data-label-off="No"
                                                data-label-on="Yes"
                                            />
                                        </label>
                                    </td>
                                </tr>
                            ))
                    }
                    </tbody>
                </Table>
            </Card>
        </>
    )
}

export default StaffResourcesCard