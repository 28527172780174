import axios from 'axios'
import * as URL from "../../Constants"
import authHeader from ".././AuthHeader";

const CALENDAR_URL = URL.API_URL + "/api/social/calendar";


class SocialCalendarService {

    getCalendar(userId) {
        return axios.get(CALENDAR_URL, {params: {userId: userId}, headers: authHeader()});
    }

    updateCalendar(userId, googleCode, googleSync,language) {
        return axios.post(CALENDAR_URL, {
            userId: userId,
            googleCode: googleCode,
            googleSync: googleSync,
            language:language
        }, {headers: authHeader()})
    }

}

export default new SocialCalendarService();