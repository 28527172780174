import { Button, TextField } from "@mui/material";
import "./AddCategory.css";
import React from "react";
import axiosInstance from "../../helper/AxiosMainHelper";
import { LoadingButton } from "@mui/lab";
import { successToast } from "../../helper/ToastHelper";

function DeleteService({
                           setOpenModal,
                           resourceId,
                           fetchCategories,
                           setCategoryList,
                           isOnboardPage = false,
                           resourceGroupId = null,
                       }) {
    const handlerSubmit = async (e) => {
        e.preventDefault();

        if (isOnboardPage) {
            setCategoryList((prev) =>
                prev.map((s) => {
                    if (s.groupId === resourceGroupId) {
                        return {
                            ...s,
                            servicesList: s.servicesList.filter((x) => x.id !== resourceId),
                        };
                    }
                })
            );
            setOpenModal(false);
        } else {
            try {
                const response = await axiosInstance.delete(
                    `appointment?resourceId=${resourceId}`
                );

                if (response.status === 200) {
                    fetchCategories();
                    setOpenModal(false);
                    successToast("Service deleted successfully!");
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="booking-form-container create-category">
            <h2>Are you sure you want to delete this service?</h2>

            <div className="btns">
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    sx={{ mr: 1 }}
                >
                    Close
                </Button>

                <Button onClick={handlerSubmit} variant="contained" sx={{ mr: 1 }}>
                    Delete
                </Button>
            </div>
        </div>
    );
}

export default DeleteService;

