import {Button, Card, Col, Container, Row} from "reactstrap";
import React from "react";
import {deleteBooking} from "../../../global/Helper"
import BookingService from "../../../services/BookingService";

const DeleteBookingModal = (props) => {

    const deleteBooking =  () => {
        BookingService.deleteBooking(props.bookingId).then(
            (response) => {
                console.log(response.data)
            },
            (error) => {
            }
        );
        window.location.reload(false);
    }

    return (
        <>
            <div className="modal-header">
                <Container>
                    <Row>
                        <Card>
                                <span>Are you sure you want to delete this booking?</span>
                        </Card>
                    </Row>
                    <Row>
                        <Button color="info"
                                onClick={deleteBooking}
                                type="submit">Delete</Button>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DeleteBookingModal