import React, {useEffect, useState} from 'react';
import AuthService from "../../../../services/AuthService";
import CompanyService from "../../../../services/CompanyService";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Modal, Row, Table} from "reactstrap";
import {useForm} from "react-hook-form";
import styles from "../../../../assets/css/styles";
import "../../../../assets/css/custom-styles.css";
import StaffMultiTab from "./StaffMultiTab";
import * as URL from "../../../../Constants"
import {LogoutIfJwtExpired} from "../../../../global/Utils";
import {useHistory} from "react-router-dom";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";


function Staffs() {
    const [flagForstaffsUpdate, setFlagsForStaffUpdate] = useState(false)
    const [data, setData] = useState([]);
    const [resources, setResources] = useState([]);

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [businessHours, setBusinessHours] = React.useState([]);
    const history = useHistory();

    const staffDetailsText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.STAFF_DETAILS_DE
            : TRANSLATION.STAFF_DETAILS_EN
    };
    const getResources = () => {
        CompanyService.getResources(AuthService.getCurrentUser().companyId).then(
            (response) => {
                console.log(response.data)
                setResources(response.data);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    const getBusinessHours = () => {
        CompanyService.getUserBusinessHours(AuthService.getCurrentUser().companyId).then(
            (response) => {
                setBusinessHours(response.data);
            },
            (error) => {

            }
        );
    }

    function addStaff(companyId, name, email) {
        CompanyService.addStaff(companyId, name, email).then(
            (response) => {
                console.log(response.data)
                setformModal(false)
                window.location.reload(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    const getStaff = () => {
        CompanyService.getStaff(AuthService.getCurrentUser().companyId).then(
            (response) => {
                console.log(response.data)
                setData(response.data);
            },
            (error) => {
                LogoutIfJwtExpired(error)
                {
                    history.push("/")
                }
            }
        );
    }

    useEffect(() => {
            if (AuthService.getCurrentUser()) {
                getStaff();
                getResources();
                getBusinessHours();
                setFlagsForStaffUpdate(false)
            } else {
                LogoutIfJwtExpired(null)
                {
                    history.push("/")
                }
            }
        }
        ,
        [flagForstaffsUpdate]
    )
    ;

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null,
        currentUser: []
    });

    const [formModal, setformModal] = React.useState(false);
    const {register, handleSubmit} = useForm();

    function onSubmit(submit) {

    }

    return (
        <>
            <SimpleHeader name="Staffs" parentName="Settings"/>
            <Container className="mt--6" fluid>
                <Row>
                    <Col lg="8">
                        <div className="card-wrapper">
                        </div>
                        <Card style={{backgroundColor: '#ECEFF1'}}>
                            <CardHeader className="border-0" style={{backgroundColor: '#1DE9B6'}}>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mb-0">{staffDetailsText()}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {inEditMode.status ? <StaffMultiTab inEditMode={inEditMode} data={data}
                                                                    businessHours={businessHours}
                                                                    staffFlagUpdate={setFlagsForStaffUpdate}></StaffMultiTab> : null}
                                <Table className="align-items-center table-flush" hover responsive>
                                    <thead>
                                    <tr>
                                        <th style={{backgroundColor: '#F4FF81'}}>Name</th>
                                        {/*<th style={{backgroundColor: '#F4FF81'}}>Email</th>*/}
                                        <th style={{backgroundColor: '#F4FF81'}}>Booking Page</th>
                                        <th style={{backgroundColor: '#F4FF81'}}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.map((item) => (
                                            <tr key={item.staffId}>
                                                <td>{item.name}</td>
                                                {/*<td>{item.email}</td>*/}
                                                <td>
                                                    <a href={"http://" + item.companyName + ".bookmyagenda.com"}>
                                                        <div>
                                                            {item.companyName}.bookmyagenda.com/business/staff/{item.username}
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>
                                                    <button
                                                        block
                                                        className={"btn-primary"}
                                                        color="primary"
                                                        onClick={() => {
                                                            console.log(item.staffId)
                                                            setInEditMode({
                                                                status: true,
                                                                rowKey: item.staffId,
                                                                currentUser: item
                                                            })
                                                        }
                                          }
                                                    >
                                                        Details
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Row>
                            <Col xs="4">
                                <button
                                    block
                                    className={"btn-primary"}
                                    color="primary"
                                    onClick={() => setformModal(true)}
                                >
                                    Add Staff
                                </button>
                            </Col>
                        </Row>

                        {/*<Modal*/}
                        {/*    contentClassName="custom-model-style"*/}
                        {/*    // className="modal-dialog-centered"*/}
                        {/*    size="lg"*/}
                        {/*    isOpen={inEditMode.status}*/}
                        {/*    toggle={() => setInEditMode({status: true, rowKey: null, currentUser: []})}*/}
                        {/*    animation={false}*/}
                        {/*    fullscreen={true}*/}
                        {/*>*/}
                        {/*    <div className="modal-header">*/}
                        {/*        <form onSubmit={handleSubmit(onSubmit)}>*/}
                        {/*            <Container>*/}
                        {/*                <Col>*/}
                        {/*                    <StaffMultiTab inEditMode={inEditMode} data={data}*/}
                        {/*                                   businessHours={businessHours} staffFlagUpdate={setFlagsForStaffUpdate}></StaffMultiTab>*/}
                        {/*                </Col>*/}
                        {/*            </Container>*/}

                        {/*        </form>*/}
                        {/*        <button*/}
                        {/*            aria-label="Close"*/}
                        {/*            className="close"*/}
                        {/*            data-dismiss="modal"*/}
                        {/*            type="button"*/}
                        {/*            onClick={() => {*/}
                        {/*                setInEditMode({status: false, rowKey: null, currentUser: []})*/}
                        {/*                window.location.reload(false);*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <span aria-hidden={true}>×</span>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</Modal>*/}
                        <Modal
                            //className="modal-dialog-centered"
                            // size="lg"
                            isOpen={formModal}
                            toggle={() => setformModal(true)}
                            animation={false}
                        >
                            <div className="modal-header">
                                <Container>
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            <h4>Name</h4>
                                        </Col>
                                        <Col md="8">
                                            <Input
                                                name="name"
                                                {...register("name", {required: true})}
                                                onChange={(e) => setName(`${e.target.value}`)}
                                                style={styles.input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            <h4>Email</h4>
                                        </Col>
                                        <Col md="8">
                                            <Input
                                                name="email"
                                                {...register("email", {required: true})}
                                                onChange={(e) => setEmail(`${e.target.value}`)}
                                                style={styles.input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button color="info"
                                                    onClick={() => addStaff(AuthService.getCurrentUser().companyId, name, email)}
                                                    type="submit">Submit</Button>
                                        </Col>
                                    </Row>
                                </Container>

                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => setformModal(false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </>
    )
        ;
}

export default Staffs;