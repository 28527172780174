import React, {useEffect, useState} from 'react';
import AuthService from "../../../../services/AuthService";
import CompanyService from "../../../../services/CompanyService";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import {Button, Card, CardHeader, Col, Container, Input, Modal, Row, Table} from "reactstrap";
import {useForm} from "react-hook-form";
import styles from "../../../../assets/css/styles";
import {LogoutIfJwtExpired} from "../../../../global/Utils";
import {useHistory} from "react-router-dom";

function Services() {
    const [data, setData] = useState([]);
    const history = useHistory();

    const getResources = () => {
        if (AuthService.getCurrentUser()) {
            CompanyService.getResources(AuthService.getCurrentUser().companyId).then(
                (response) => {
                    console.log(response.data)
                    setData(response.data);
                },
                (error) => {
                    LogoutIfJwtExpired(error)
                    {
                        history.push("/")
                    }
                }
            );
        } else {
            LogoutIfJwtExpired(null)
            {
                history.push("/")
            }
        }
    }

    useEffect(() => {
        getResources();
    }, []);


    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const [unitPrice, setUnitPrice] = useState(null);
    const [duration, setDuration] = useState(null);
    const [description, setDescription] = useState(null);
    const [name, setName] = useState(null);
    const [serviceGorup, setServiceGroup] = useState(null);

    const [serviceName, setServiceName] = useState(null);
    const [serviceDescription, setServiceDescription] = useState(null);
    const [serviceDuration, setServiceDuration] = useState(null);
    const [price, setPrice] = React.useState("NA");


    const [formModal, setformModal] = React.useState(false);
    const {register, handleSubmit} = useForm();

    function onSubmit(submit) {
        CompanyService.addResources(AuthService.getCurrentUser().id, serviceName, serviceDescription, price, serviceDuration).then((response) => {
            console.log(response.data)
            onCancel();
            getResources();
            setformModal(false)
        }, (error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
        })
    }

    /**
     *
     * @param id - The id of the product
     * @param currentUnitPrice - The current unit price of the product
     */
    const onEdit = ({id, currentUnitPrice, currentDuration, currentDescription, currentName}) => {
        setInEditMode({
            status: true,
            rowKey: id
        })
        setUnitPrice(currentUnitPrice);
        setDuration(currentDuration)
        setDescription(currentDescription)
        setName(currentName)
    }

    /**
     *
     * @param id
     * @param newUnitPrice
     */
    const updateResources = ({id, newUnitPrice, newDuration, newDescription, newName}) => {
        CompanyService.updateResources(id, newUnitPrice, newDuration, newDescription, newName).then((response) => {
            console.log(response.data)
            onCancel();
            getResources();
        }, (error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
        })
    }

    /**
     *
     * @param id -The id of the product
     * @param newUnitPrice - The new unit price of the product
     */
    const onSave = ({id, newUnitPrice, newDuration, newDescription, newName}) => {
        updateResources({id, newUnitPrice, newDuration, newDescription, newName});
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value
        setUnitPrice(null);
        setDuration(null)
        setDescription(null)
        setName(null)
    }

    return (
        <>
            {/*<SimpleHeader name="Services" parentName="Settings"/>*/}
            {/*<Container className="mt--6" fluid>*/}
                <Row>
                    <Col lg="8">
                        <div className="card-wrapper">
                        </div>
                        <Card style={{backgroundColor: '#ECEFF1'}}>
                            <CardHeader className="border-0" style={{backgroundColor: '#1DE9B6'}}>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mb-0">Resources</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" hover responsive>
                                <thead>
                                <tr>
                                    <th style={{backgroundColor: '#F4FF81'}}>Name</th>
                                    <th style={{backgroundColor: '#F4FF81'}}>Description</th>
                                    <th style={{backgroundColor: '#F4FF81'}}>Amount</th>
                                    <th style={{backgroundColor: '#F4FF81'}}>Duration</th>
                                    <th style={{backgroundColor: '#F4FF81'}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    data.map((item) => (
                                        <tr key={item.id}>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item.id ? (
                                                        <input value={name} placeholder={item.name}
                                                               onChange={(event) => setName(event.target.value)}
                                                        />
                                                    ) : (
                                                        item.name
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item.id ? (
                                                        <input value={description} placeholder={item.description}
                                                               onChange={(event) => setDescription(event.target.value)}
                                                        />
                                                    ) : (
                                                        item.description
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item.id ? (
                                                        <input value={unitPrice} placeholder={item.amount}
                                                               onChange={(event) => setUnitPrice(event.target.value)}
                                                        />
                                                    ) : (
                                                        item.amount
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item.id ? (
                                                        <input value={duration} placeholder={item.duration}
                                                               onChange={(event) => setDuration(event.target.value)}
                                                        />
                                                    ) : (
                                                        item.duration
                                                    )
                                                }

                                            </td>

                                            <td>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === item.id ? (
                                                        <React.Fragment>
                                                            <button
                                                                className={"btn-success"}
                                                                onClick={() => onSave({
                                                                    id: item.id,
                                                                    newUnitPrice: unitPrice,
                                                                    newDuration: duration,
                                                                    newDescription: description,
                                                                    newName: name
                                                                })}
                                                            >
                                                                Save
                                                            </button>

                                                            <button
                                                                className={"btn-secondary"}
                                                                style={{marginLeft: 8}}
                                                                onClick={() => onCancel()}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </React.Fragment>
                                                    ) : (
                                                        <button
                                                            className={"btn-primary"}
                                                            onClick={() => onEdit({
                                                                id: item.id,
                                                                currentUnitPrice: item.amount,
                                                                currentDuration: item.duration,
                                                                currentDescription: item.description,
                                                                currentName: item.name
                                                            })}
                                                        >
                                                            Edit
                                                        </button>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Card>
                        <Row>
                            <Col xs="4">
                                <button
                                    block
                                    className={"btn-primary"}
                                    color="primary"
                                    onClick={() => setformModal(true)}
                                >
                                    Add Service
                                </button>
                            </Col>
                        </Row>
                        <Modal
                            //className="modal-dialog-centered"
                            size="lg"
                            isOpen={formModal}
                            toggle={() => setformModal(true)}
                            animation={false}
                        >
                            <div className="modal-header">
                                <Container>
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            <h4>Name</h4>
                                        </Col>
                                        <Col md="8">
                                            <Input
                                                name="name"
                                                {...register("name", {required: true})}
                                                onChange={(e) => setServiceName(`${e.target.value}`)}
                                                style={styles.input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            <h4>Description</h4>
                                        </Col>
                                        <Col md="8">
                                            <Input
                                                name="description"
                                                {...register("description", {required: true})}
                                                onChange={(e) => setServiceDescription(`${e.target.value}`)}
                                                style={styles.input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            <h4>Duration</h4>
                                        </Col>
                                        <Col md="8">
                                            <Input
                                                name="duration"
                                                {...register("duration", {required: true})}
                                                onChange={(e) => setServiceDuration(`${e.target.value}`)}
                                                placeholder="in (mins)"
                                                style={styles.input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            <h4>Price</h4>
                                        </Col>
                                        <Col md="8">
                                            <Input
                                                name="price"
                                                {...register("price", {required: true})}
                                                onChange={(e) => setPrice(`${e.target.value}`)}
                                                style={styles.inputWithExtraBottom}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button color="info" onClick={onSubmit} type="submit">Submit</Button>
                                        </Col>
                                    </Row>
                                </Container>

                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => setformModal(false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
            {/*</Container>*/}
        </>
    );
}

export default Services;