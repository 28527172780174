import AuthHeader from "../../../components/Headers/AuthHeader";

function EmailValidationSuccess() {
    return (
        <>
            <AuthHeader
                title="Email Validation Successful"
                lead="Thank you, your email has been validated successfully"
            />
        </>
    );

}

export default EmailValidationSuccess