
class TranslationService{
    getCurrentLanguage(){
        return JSON.parse(localStorage.getItem('language'));
    };

}

export default new TranslationService()
export const BOOKINGS_EN = "Bookings"
export const BOOKINGS_DE = "Buchungen"
export const ADD_BOOKING_EN = "Add Booking"
export const ADD_BOOKING_DE = "Buchung hinzufügen"
export const THIS_WEEK_EN = "This Week"
export const THIS_WEEK_DE = "Diese Woche"
export const FUTURE_EN = "Future"
export const FUTURE_DE = "Zukunt"
export const PAST_EN = "Past"
export const PAST_DE = "Vergangenheit"
export const BOOKINGS_DETAILS_EN = "Booking Details"
export const BOOKINGS_DETAILS_DE = "Buchungsdetails"
export const SELECT_STAFF_EN = "Select Staff"
export const SELECT_STAFF_DE = "Personal auswählen"
export const SELECT_SERVICE_EN = "Select Service"
export const SELECT_SERVICE_DE = "Dienst auswählen"
export const SELECT_CUSTOMER_EN = "Select Customer"
export const SELECT_CUSTOMER_DE = "Kunde auswählen"
export const SELECT_DATE_EN = "Select Date"
export const SELECT_DATE_DE = "Datum auswählen"
export const AVAILABLE_SLOTS_EN = "Available Slots"
export const AVAILABLE_SLOTS_DE = "Verfügbare Slots"
export const CONFIRM_EN = "Confirm"
export const CONFIRM_DE = "Bestätigen"
export const EDIT_EN = "Edit"
export const EDIT_DE = "Bearbeiten"
export const SAVE_EN = "Save"
export const SAVE_DE = "Speichern"
export const CANCEL_EN = "Cancel"
export const CANCEL_DE = "Stornieren"
export const BUSINESS_EN = "Business"
export const BUSINESS_DE = "Business"
export const BUSINESS_DETAILS_EN = "Business Details"
export const BUSINESS_DETAILS_DE = "Geschäftliche Details"
export const TIMEZONE_EN = "Time zone"
export const TIMEZONE_DE = "Zeitzone"
export const BOOKING_PAGE_EN = "Booking Page"
export const BOOKING_PAGE_DE = "Buchungsseite"
export const BOOKING_URL_EN = "Booking URL"
export const USERNAME_EN = "username"
export const USERNAME_DE = "Nutzername"
export const BOOKING_URL_DE = "Buchungs-URL"
export const BUSINESS_HOURS_EN = "Business Hours"
export const BUSINESS_HOURS_DE = "Geschäftszeiten"
export const SERVICES_EN = "Services"
export const SERVICES_DE = "Dienstleistungen"
export const ADD_CATEGORY_DE = "Kategorie hinzufügen"
export const ADD_CATEGORY_EN = "Add Category"
export const SERVICE_DESCRIPTION_EN = "Description"
export const SERVICE_DESCRIPTION_DE = "Beschreibung"
export const SERVICE_DURATION_EN= "Duration(in min.)"
export const SERVICE_DURATION_DE= "Dauer(in Min.)"
export const SERVICE_AMOUNT_EN= "Amount"
export const SERVICE_AMOUNT_DE= "Betrag"
export const ADD_NEW_CATEGORY_EN = "Add category"
export const ADD_NEW_CATEGORY_DE = "Kategorie hinzufügen"
export const DESCRIPTION_EN = "Description"
export const DESCRIPTION_DE = "Beschreibung"
export const CREATE_EN = "Create"
export const CREATE_DE = "Erstellen"
export const STAFF_DETAILS_DE = "Mitarbeiter Details"
export const STAFF_DETAILS_EN = "Staff Details"
export const ASSIGNED_SERVICES_EN = "Assigned Services"
export const ASSIGNED_SERVICES_DE = "Zugewiesene Dienstleistungen"
export const AVAILABILITY_DE = "Verfügbarkeit"
export const AVAILABILITY_EN = "Availability"
export const VACATION_EN = "Vacation"
export const VACATION_DE = "Urlaub"
export const VACATION_DATES_EN = "Vacation Dates"
export const VACATION_DATES_DE = "Urlaubstermine"
export const ADD_VACATION_EN = "Add vacation"
export const ADD_VACATION_DE = "Urlaub hinzufügen"
export const START_DATE_EN = "Start Date"
export const START_DATE_DE = "Start Datum"
export const END_DATE_DE = "End Datum"
export const END_DATE_EN = "End Date"
export const START_TIME_EN = "Start Time"
export const START_TIME_DE = "Startzeit"
export const END_TIME_DE = "Endzeit"
export const END_TIME_EN = "End Time"
export const NOTES_EN = "Notes"
export const NOTES_DE = "Hinweise"
export const STAFF_BREAK_EN = "Staff Break"
export const STAFF_BREAK_DE = "Personal Pause"
export const BREAK_EN = "Break"
export const BREAK_DE = "Pause"
export const ADD_BREAK_EN = "Add Break"
export const ADD_BREAK_DE = "Pausen hinzufügen"
export const CUSTOMER_EN = "Customer"
export const CUSTOMER_DE = "Kunden"
export const ADD_CUSTOMER_EN = "Add Customer"
export const ADD_CUSTOMER_DE = "Kunde hinzufügen"
export const BOOKING_WIDGET_EN = "Booking Widget"
export const BOOKING_WIDGET_DE = "Buchungs-Widget"
export const BOOKING_WIDGET_EN_1 = "Enable customers book directly from their website"
export const BOOKING_WIDGET_DE_1 = "Kunden die Möglichkeit geben, direkt von ihrer Website aus zu buchen"
export const BOOKING_WIDGET_EN_2 = "Embed our booking button on your site in This code will give you a button, that when clicked, will have an elegant pop-up window where your customers can schedule appointments."
export const BOOKING_WIDGET_DE_2 = "Integrieren Sie unsere Buchungsschaltfläche auf Ihrer Website in diesen Code. Dieser Code wird Ihnen eine Schaltfläche geben, die, wenn sie angeklickt wird, ein elegantes Pop-up-Fenster öffnet, in dem Ihre Kunden Termine vereinbaren können."
export const CALENDAR_SYNC_EN = "Calendar synchronization"
export const CALENDAR_SYNC_DE = "Kalender-Synchronisation"
export const CALENDAR_SYNC_DESC_EN = "Automatically export your appointments to the personal calendar of your choice"
export const CALENDAR_SYNC_DESC_DE = "Exportieren Sie automatisch Ihre Termine in den persönlichen Kalender Ihrer Wahl."
export const BOOKING_CUSTOMISATIONS_EN = "Booking Customisations"
export const BOOKING_CUSTOMISATIONS_DE = "Buchungsanpassungen"
export const SUCCESSFUL_BOOKING_MESSAGE_EN = "Customize the message when your customer makes a booking"
export const SUCCESSFUL_BOOKING_MESSAGE_DE = "Passen Sie die Nachricht an, wenn Ihr Kunde eine Buchung vornimmt"

export const SUPPORT_MESSAGE_EN = "Kindly please reach out to support@bookmyagenda.com or +4915166104593 for any help"
export const SUPPORT_MESSAGE_DE = "Bei Fragen wenden Sie sich bitte an support@bookmyagenda.com oder +4915166104593."
export const SUPPORT_BOOKING_EN = "Alternatively you can also book an appointment through this"
export const SUPPORT_BOOKING_DE = "Alternativ können Sie auch einen Termin über diesen Link buchen."
export const GOOLGE_CALENDAR_BETA_EN = "Because we are in beta phase, we need to add your google account manually to the whitelisting. Please mail to support@bookmyagenda.com so that this can be done. Then you can synchronise your google calendar!"
export const GOOLGE_CALENDAR_BETA_DE = "Da wir uns in der Beta-Phase befinden, müssen wir Ihr Google-Konto manuell in die Whitelist aufnehmen. Bitte senden Sie eine E-Mail an sakthi@bookmyagenda.com, damit dies erledigt werden kann. Dann können Sie Ihren Google-Kalender synchronisieren!"