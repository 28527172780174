import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import React, {useState} from "react";
import styles from "../../../../../assets/css/styles";

const UserBooking = (props) => {

    const [serviceName, setServiceName] = useState(null);
    const [serviceid, setServiceId] = useState(null);
    const [serviceDescription, setServiceDescription] = useState(null);
    const [serviceDuration, setServiceDuration] = useState(null);
    const [serviceAmount, setServiceAmount] = useState(null);
    const [showServices, setShowServices] = useState(true)

    function updateBookingDetails(id, name, desc, duration, amount) {
        setShowServices(false)
        setServiceId(id)
        setServiceName(name)
        setServiceDescription(desc)
        setServiceDuration(duration)
        setServiceAmount(amount)
        props.getCompanyid(props.staff.companyId)
        props.onChangeService(id, name)
        props.getUserId(props.staff.id, props.staff.name)
    }

    return (<>
        <Container>
            <Row>
                <Col>
                    <div>
                        <>
                            {showServices ? (
                                <Card style={{backgroundColor: '#ECEFF1'}}>
                                    <CardBody>
                                        {props.staff.resourceDtoList.map(appt => {
                                            return (
                                                <button class="btn btn-info"
                                                    key={appt.name}
                                                    onClick={e => {
                                                        updateBookingDetails(appt.id, appt.name, appt.description, appt.duration, appt.amount)
                                                    }}
                                                >
                                                    {appt.name}
                                                </button>
                                            );
                                        })}
                                    </CardBody>
                                </Card>
                            ) : null}

                        </>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    {serviceName ? (
                        <>
                            <Card>
                                <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                                    <h3 className="mb-0">{serviceName}</h3>
                                </CardHeader>
                                <CardBody>
                                    <p>{serviceDescription}</p>
                                    <p><b>Duration:</b> {serviceDuration} mins</p>
                                    <p><b>Price:</b> {serviceAmount}</p>
                                    <p><b>Provider:</b>{props.staff.name}</p>
                                </CardBody>
                                <button class="btn btn-light"
                                    // style={styles.input}
                                    onClick={() => {
                                        window.location.reload(false);
                                    }}
                                >
                                    Back
                                </button>
                            </Card>

                        </>


                    ) : null}
                </Col>
            </Row>

        </Container>
    </>)
}

export default UserBooking