import React, {useState} from "react";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import CompanyService from "../../../services/CompanyService";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";


const BusinessHoursModal = props => {

    const businessHours = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BUSINESS_HOURS_DE
            : TRANSLATION.BUSINESS_HOURS_EN
    };
    function handleChange(oldValue, newValue, row, column) {
        CompanyService.updateIndividualBusinessHour(
            row.id, row.startTime, row.endTime, row.working
        ).then(
            (response) => {
            },
            (error) => {

            }
        );
    }

    const columns = [{
        dataField: 'day',
        text: 'Day'
    }, {
        dataField: 'startTime',
        text: 'Start Time',
        editor: {}
    }, {
        dataField: 'endTime',
        text: 'End Time'
    }, {
        dataField: 'working',
        text: 'open',
        editor: {
            type: Type.SELECT,
            options: [
                {
                    value: "Yes",
                    label: "Yes"
                },
                {
                    value: "No",
                    label: "No"
                }
            ]
        }
    }];

    return (
        <>
            <Card style={{backgroundColor: '#B2DFDB'}}>
                <CardHeader className="border-0" style={{backgroundColor: '#4DD0E1'}}>
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">{businessHours()}</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <BootstrapTable
                        keyField='id'
                        data={props.workingHours}
                        columns={columns}
                        striped
                        bordered={false}
                        wrapperClasses="table-responsive"
                        cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                        afterSaveCell: handleChange
                                    })}
                    >
                    </BootstrapTable>
                </CardBody>
            </Card>
        </>
    )
}

export default BusinessHoursModal