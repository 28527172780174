/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/Login/Login.js";
import Profile from "views/pages/examples/Profile.js";
import Register from "views/pages/Register/Register.js";
import SuccessLogin from "views/pages/Login/SuccessLogin.js"
import UnSuccessLogin from "views/pages/Login/UnsuccessfulLogin.js"
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Timeline from "views/pages/examples/Timeline.js";
import EmailValidationSuccess from "./views/pages/Register/EmailValidationSuccess";
import ForgotPassword from "./views/pages/Password/ForgotPassword";
import EmailPasswordReset from "views/pages/Register/EmailPasswordReset";
import ResetPasswordSuccess from "./views/pages/Password/ResetPasswordSuccess";
import ResetPassword from "./views/pages/Password/ResetPassword";
import Onboarding from "./views/pages/onboarding/Onboarding";
import ErrorOnboardin from "./views/pages/Error/ErrorOnboardin";
import EmailPresent from "./views/pages/Login/EmailPresent";
import ExternalBooking from "./views/pages/external-booking/ExternalBooking";
import BookingSuccess from "./views/pages/external-booking/components/common/BookingSuccess";
import ExternalBookingMain from "./views/pages/external-booking/components/booking-process/ExternalBookingMain";
import ExtBooking from "./views/pages/external-booking/ExtBooking";
import OnboardingLayout from "./views/pages/onboarding-new/OnboardingLayout";
import Pricing from "./views/pages/examples/Pricing";
import PrivacyEn from "./views/pages/privacy/PrivacyEn";

const routes = [
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "R",
        component: Pricing,
        layout: "/auth",
      },
      // {
      //   path: "/pricing",
      //   name: "Pricing",
      //   miniName: "P",
      //   component: Pricing,
      //   layout: "/auth",
      // },
      {
        path: "/validate-email",
        name: "ValidateEmail",
        miniName: "R",
        component: EmailValidationSuccess,
        layout: "/auth",
      },
      {
        path: "/onboarding",
        name: "MultiForm",
        miniName: "R",
        component: OnboardingLayout,
        layout: "/dash",
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        miniName: "R",
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/reset-password-success",
        name: "ResetPasswordSuccess",
        miniName: "R",
        component: ResetPasswordSuccess,
        layout: "/auth",
      },
      {
        path: "/booking-success",
        name: "BookingSuccess",
        miniName: "R",
        component: BookingSuccess,
        layout: "/auth",
      },
      {
        path: "/onboarding-failure",
        name: "ErrorOnboardin",
        miniName: "R",
        component: ErrorOnboardin,
        layout: "/auth",
      },
      {
        path: "/slogin",
        name: "SLogin",
        miniName: "R",
        component: SuccessLogin,
        layout: "/auth",
      },
      {
        path: "/glogin",
        name: "USLogin",
        miniName: "R",
        component: UnSuccessLogin,
        layout: "/auth",
      },
      {
        path: "/flogin",
        name: "FLogin",
        miniName: "R",
        component: EmailPresent,
        layout: "/auth",
      },
      {
        path: "/update-password",
        name: "Password Update",
        miniName: "U",
        component: ForgotPassword,
        layout: "/auth",
      },
      {
        path: "/email-password-reset",
        name: "Email Password Reset",
        miniName: "U",
        component: EmailPasswordReset,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/privacy",
        name: "Privacy",
        miniName: "L",
        component: PrivacyEn,
        layout: "/dash",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/external",
        name: "Ext-Booking",
        miniName: "T",
        component: ExternalBooking,
        layout: "/booking",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "/rtl",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/",
          },
        ],
      },
    ],
  }
];

export default routes;
