import React from "react";
import CompanyService from "../../../../services/CompanyService";
import {Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import AuthService from "../../../../services/AuthService";
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import styles from "../../../../assets/css/styles";
import {useForm} from "react-hook-form";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const startTimeText = () => {
    return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.START_TIME_DE
        : TRANSLATION.START_TIME_EN
};

const StaffBreakCard = (props) => {

    const [workBreak, setWorkBreak] = React.useState(null)
    const [notes, setNotes] = React.useState("NA");
    const {register, handleSubmit} = useForm();
    const hourOptions = [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24'
    ];
    const defaultHourOption = hourOptions[0];
    const minuteOptions = ['00', '15', '30', '45']
    const defaultMinuteOption = minuteOptions[0];

    const endTimeText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.END_TIME_DE
            : TRANSLATION.END_TIME_EN
    };

    const notesText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.NOTES_DE
            : TRANSLATION.NOTES_EN
    };

    const staffBreakText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.STAFF_BREAK_DE
            : TRANSLATION.STAFF_BREAK_EN
    };

    const addBreakText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ADD_BREAK_DE
            : TRANSLATION.ADD_BREAK_EN
    };

    React.useEffect(() => {
        CompanyService.getBreaksForStaff(props.inEditMode.currentUser.staffId
        ).then(
            (response) => {
                setWorkBreak(response.data)
            },
            (error) => {
            }
        );
    }, [])

    const deleteBreakAndGetUpdatedList = (breakId) => {
        CompanyService.deleteBreakAndGetUpdatedBreaksList(breakId
        ).then(
            (response) => {
                setWorkBreak(response.data)
            },
            (error) => {
            }
        );
    }

    function addBreak(staffId, companyId, startTime, endTime, notes) {
        CompanyService.addBreaksForStaff(staffId, companyId, startTime, endTime, notes).then(
            (response) => {
                setWorkBreak(response.data)
            },
            (error) => {
            }
        );
        setformModal(false)
    }

    const columns = [{
        dataField: 'startTime',
        text: startTimeText(),
        sort: true
    }, {
        dataField: 'endTime',
        text: endTimeText(),
        sort: true
    }, {
        dataField: 'notes',
        text: notesText(),
        sort: true

    }, {
        dataField: 'id',
        text: 'Actions',
        formatter: (cellContent, row) => {
            return (
                <>
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() => {
                            deleteBreakAndGetUpdatedList(row.id)
                        }}
                    >
                        Delete
                    </button>
                </>
            );
        }
    }];

    const [formModal, setformModal] = React.useState(false);
    const [startHour, setStartHour] = React.useState('');
    const [startMins, setStartMinutes] = React.useState('');

    const [endHour, setEndHour] = React.useState('');
    const [endMins, setEndMins] = React.useState('');


    return (
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">{staffBreakText()}</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>{
                        workBreak && <BootstrapTable
                            keyField='id'
                            data={workBreak}
                            columns={columns}>
                            striped
                            bordered={false}
                            wrapperClasses="table-responsive"
                        </BootstrapTable>
                    }

                    </Row>
                    <Row>
                        <Col xs="6">
                            <button
                                block
                                className={"btn-primary"}
                                color="primary"
                                onClick={() => setformModal(true)}
                            >
                                {addBreakText()}
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal
                //className="modal-dialog-centered"
                // size="lg"
                isOpen={formModal}
                toggle={() => setformModal(true)}
                animation={false}
            >
                <div className="modal-header">
                    <Container>
                        <Row className="align-items-center">
                            <Col md="4">
                                <h4>{startTimeText()}</h4>
                            </Col>
                            <Col md="4">
                                {/*<TimePicker onChange={setStartHour} value={startHour} minuteStep={15} />*/}
                                <Dropdown options={hourOptions} onChange={setStartHour} value={startHour}
                                          placeholder="Hours"/>
                            </Col>
                            <Col md="4">
                                <Dropdown options={minuteOptions} onChange={setStartMinutes} value={startMins}
                                          placeholder="Minutes"/>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="4">
                                <h4>{endTimeText()}</h4>
                            </Col>
                            <Col md="4">
                                {/*<TimePicker onChange={setStartHour} value={startHour} minuteStep={15} />*/}
                                <Dropdown options={hourOptions} onChange={setEndHour} value={endHour}
                                          placeholder="Hours"/>
                            </Col>
                            <Col md="4">
                                <Dropdown options={minuteOptions} onChange={setEndMins} value={endMins}
                                          placeholder="Minutes"/>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md="7">
                                <input
                                    name="notes"
                                    {...register("notes", {required: true})}
                                    placeholder={notesText()}
                                    onChange={(e) => setNotes(`${e.target.value}`)}
                                    style={styles.marginTopAndBottom}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="info"
                                        onClick={() => {
                                            addBreak(props.inEditMode.currentUser.staffId,
                                                AuthService.getCurrentUser().companyId,
                                                startHour.value + ':' + startMins.value,
                                                endHour.value + ':' + endMins.value,
                                                notes)
                                        }
                                        }

                                        type="submit">Submit</Button>
                            </Col>
                        </Row>
                    </Container>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setformModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            </Modal>
        </>
    )

}

export default StaffBreakCard