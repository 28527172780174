import axios from 'axios';
import authHeader from './AuthHeader';
import * as URL from "../Constants"

const COMPANY_URL = URL.API_URL + '/api/business'
const COMPANY_EXTERNAL_URL = URL.API_URL + '/api/external/company'
const COMPANY_WORKING_HOURS = URL.API_URL + '/api/business/hours'
const USER_WORKING_HOURS = URL.API_URL + '/api/business/user/hours'
const RESOURCE_URL = URL.API_URL + '/api/appointment'
const STAFF_URL = URL.API_URL + '/api/user'
const STAFF_VACATION_URL = URL.API_URL + '/api/user/vacation'
const STAFF_BREAK_URL = URL.API_URL + '/api/user/break'
const CUSTOMER_URL = URL.API_URL + '/api/customer'


class CompanyService {

    getCompany(userId) {
        return axios.get(COMPANY_URL, {params: {userId: userId}, headers: authHeader()});
    }

    updateCompany(id,name,timeZone) {
        return axios.patch(COMPANY_URL, {
            id: id,
            name:name,
            timeZone:timeZone
        }, {headers: authHeader()})    }

    updateCompanyBookingMessage(id,bookingMessage) {
        return axios.patch(COMPANY_URL, {
            id: id,
            bookingMessage:bookingMessage,
        }, {headers: authHeader()})    }
    updateBookingName(id,username) {
        return axios.patch(COMPANY_URL, {
            id: id,
            username:username
        }, {headers: authHeader()})    }

    getCompanyDetails(name) {
        return axios.get(COMPANY_EXTERNAL_URL, {params: {name: name}});
    }

    getCompanyBusinessHours(companyId) {
        return axios.get(COMPANY_WORKING_HOURS, {params: {companyId: companyId}, headers: authHeader()});
    }

    getUserBusinessHours(companyId) {
        return axios.get(USER_WORKING_HOURS, {params: {companyId: companyId}, headers: authHeader()});
    }

    updateIndividualBusinessHour(id, startTime, endTime, working) {
        return axios.post(COMPANY_WORKING_HOURS, {
            id: id,
            startTime: startTime,
            endTime: endTime,
            working: working
        }, {headers: authHeader()})
    }

    getResources(companyId) {
        return axios.get(RESOURCE_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    getStaff(companyId) {
        return axios.get(STAFF_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    addVacationForStaff(staffId, companyId, startDate, endDate, notes) {
        return axios.post(STAFF_VACATION_URL, {
            staffId: staffId,
            companyId: companyId,
            startDate: startDate,
            endDate: endDate,
            notes: notes
        }, {headers: authHeader()})
    }

    getVacationsForStaff(staffId) {
        return axios.get(STAFF_VACATION_URL, {params: {staffId: staffId},
            headers: authHeader()});
    }

    deleteVacationAndGetUpdatedVacationList(vacationId){
        return axios.delete(STAFF_VACATION_URL, {params: {vacationId: vacationId}, headers: authHeader()});
    }

    getBreaksForStaff(staffId) {
        return axios.get(STAFF_BREAK_URL, {params: {staffId: staffId},
            headers: authHeader()});
    }

    deleteBreakAndGetUpdatedBreaksList(breakId){
        return axios.delete(STAFF_BREAK_URL, {params: {breakId: breakId}, headers: authHeader()});
    }

    addBreaksForStaff(staffId, companyId, startTime, endTime, notes) {
        return axios.post(STAFF_BREAK_URL, {
            staffId: staffId,
            companyId: companyId,
            startTime: startTime,
            endTime: endTime,
            notes: notes
        }, {headers: authHeader()})
    }

    updateStaff(userId, resourceId, isActive,companyId,username) {
        return axios.patch(STAFF_URL, {
            userId: userId,
            resourceId: resourceId,
            isActive: isActive,
            companyId:companyId,
            desiredUsername:username
        }, {headers: authHeader()})
    }

    addStaff(companyId, name, email) {
        return axios.post(STAFF_URL, {
            companyId: companyId,
            name: name,
            email: email
        }, {headers: authHeader()})
    }


    getCustomer(companyId) {
        return axios.get(CUSTOMER_URL, {params: {companyId: companyId}, headers: authHeader()});
    }

    deleteCustomer(customerId){
        return axios.delete(CUSTOMER_URL, {params: {customerId: customerId}, headers: authHeader()});
    }

    addCustomer(companyId, name, email, phone) {
        return axios.post(CUSTOMER_URL, {
            companyId: companyId,
            name: name,
            email: email,
            phone: phone
        }, {headers: authHeader()})
    }


    updateResources(resourceId, amount, duration, description, name) {
        return axios.patch(RESOURCE_URL, {
            id: resourceId,
            amount: amount,
            duration: duration,
            name: name,
            description: description
        }, {headers: authHeader()})
    }

    addResources(userId, name, description, amount, duration) {
        return axios.post(RESOURCE_URL, {
            userId: userId,
            name: name,
            amount: amount,
            duration: duration,
            description: description
        }, {headers: authHeader()})
    }
}

export default new CompanyService();
