import { Button, TextField } from "@mui/material";
import "./AddCategory.css";
import React from "react";
import { successToast } from "../../helper/ToastHelper";
import axiosInstance  from "../../helper/AxiosMainHelper";

function DeleteCategory({
                          setOpenModal,
                          isOnboardPage = false,
                          resourceId,
                          fetchCategories,
                          setCategoryList,
                        }) {
  const handlerSubmit = async (e) => {
    e.preventDefault();

    if (isOnboardPage) {
      setCategoryList((prev) => prev.filter((s) => s.groupId !== resourceId));
      setOpenModal(false);
    } else {
      try {
        const response = await axiosInstance.delete(
            `appointment/resource-group?resourceGroupId=${resourceId}`
        );

        if (response.status === 200) {
          fetchCategories();
          setOpenModal(false);
          successToast("Category deleted successfully!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
      <div className="booking-form-container create-category">
        <h2>Are you sure you want to delete this resource?</h2>
        <p>All the services connected to this resource will also be deleted.</p>

        <div className="btns">
          <Button
              variant="contained"
              color="inherit"
              onClick={() => setOpenModal(false)}
              sx={{ mr: 1 }}
          >
            Close
          </Button>

          <Button onClick={handlerSubmit} variant="contained" sx={{ mr: 1 }}>
            Delete
          </Button>
        </div>
      </div>
  );
}

export default DeleteCategory;
