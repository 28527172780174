import axios from 'axios';
import authHeader from './AuthHeader';
import * as URL from "../Constants"


const EMAIL_WIDGET_URL = URL.API_URL + '/api/widget-email'


class EmailService{

    sendEmail(email) {
        return axios.get(EMAIL_WIDGET_URL, {params: {email: email}, headers: authHeader()});
    }
}

export default new EmailService()