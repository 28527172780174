/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import {GoogleLogin} from 'react-google-login';
import {gapi} from 'gapi-script';
import * as CONSTANTS from "../../../Constants"


// reactstrap components
import {
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthService from "../../../services/AuthService";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import styles from "../../../assets/css/styles";

function Login() {

    const {register, handleSubmit} = useForm();
    const history = useHistory();

    const [email, setEmail] = React.useState("NA");
    const [password, setPassword] = React.useState("NA");
    const [failureAlert, setFailureAlert] = React.useState(false);

    const onSuccess = (res) => {
        console.log('success:', res);
        console.log('success:', res.tokenId);
        AuthService.login(null, null, res.tokenId).then(
            (response) => {
                if (localStorage.getItem('isCompanyOnboarded') === 'false') {
                    history.push("/dash/onboarding");
                } else {
                    history.push("/admin/bookings");
                }
            },
            (error) => {
                if(error.response.status == 404){
                    history.push("/auth/glogin");
                }
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    };
    const onFailure = (err) => {
        console.log('failed:', err);
    };

    const clientId = '536709672021-dr3uch132daipeb6smk29arofpvnmkr3.apps.googleusercontent.com';

    React.useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });

    function onSubmit() {
        AuthService.login(email, password,null).then(
            (response) => {
                if (localStorage.getItem('isCompanyOnboarded') === 'false') {
                    history.push("/dash/onboarding");
                } else {
                    history.push("/admin/bookings");
                }
            },
            (error) => {
                if(error.response.status == 500){
                    setFailureAlert(true)
                    setTimeout(() => {
                        setFailureAlert(false);
                    }, 2000);
                }
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    if (localStorage.getItem('isCompanyOnboarded') === 'false') {
        history.push("/dash/onboarding");
    } else if (JSON.parse(localStorage.getItem('user'))) {
        history.push("/admin/bookings");
    } else {
        return (
            <>
                <AuthHeader
                    title="Welcome!"
                    lead="Organize your business with automated scheduling and much more"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="6" md="8">
                            <Card className="bg-secondary border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <Row>
                                        <Input
                                            name="email"
                                            {...register("email", {required: true})}
                                            placeholder="Email"
                                            onChange={(e) => setEmail(`${e.target.value}`)}
                                            style={styles.inputWithExtraBottom}
                                        />
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            {...register("password", {required: true})}
                                            onChange={(e) => setPassword(`${e.target.value}`)}
                                            style={styles.inputWithExtraBottom}
                                        />
                                        <button style={styles.inputWithExtraBottom} onClick={onSubmit} class="btn btn-primary" type="submit">Log In</button>
                                    </Row>
                                    {failureAlert && <div className='alert-container'>
                                        <div  style={styles.inputWithExtraBottom} className='alert-inner'><b style={{backgroundColor: '#F4FF81'}}>Email or Password incorrect!!</b>
                                        </div>
                                    </div>}
                                    <Row>
                                        <GoogleLogin
                                            clientId={CONSTANTS.CLIENT_ID}
                                            buttonText="Sign in with Google"
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                            cookiePolicy={'single_host_origin'}
                                            // isSignedIn={true}
                                        />
                                    </Row>
                                </CardBody>
                            </Card>
                            <Row className="mt-2">
                                <Col xs="6">
                                    <form>
                                        <button class="btn btn-info" onClick={() => history.push({
                                            pathname: "/auth/update-password"
                                        })}>Forgot Password
                                        </button>
                                    </form>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <form>
                                        <button class="btn btn-success" onClick={() => history.push({
                                            pathname: "/auth/register"
                                        })}>Create new account
                                        </button>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Login;
