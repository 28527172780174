import AuthHeader from "components/Headers/AuthHeader";

function SuccessLogin() {
  return (
    <>
      <AuthHeader
        title="User Created Successfully"
        lead="Please validate your account by clicking on the link sent in email"
      />
    </>
  );
}

export default SuccessLogin;
