import {Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import React, {useState} from "react";
import CompanyService from "../../../../services/CompanyService";
import AuthService from "../../../../services/AuthService";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const CompanyBookingPageCard = (props) => {

    const [bookingUrl, setBookingURL] = useState(false)
    const [bookingName, setBookingName] = React.useState(props.name)
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [failureAlert, setFailureAlert] = React.useState(false);

    const bookingPageURL = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKING_URL_DE
            : TRANSLATION.BOOKING_URL_EN;
    };

    const editText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.EDIT_DE
            : TRANSLATION.EDIT_EN;
    };

    const saveText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SAVE_DE
            : TRANSLATION.SAVE_EN;
    };

    const cancelText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CANCEL_DE
            : TRANSLATION.CANCEL_EN;
    };
    const onSave = () => {
        CompanyService.updateBookingName(AuthService.getCurrentUser().companyId, bookingName).then(
            (response) => {
                props.setRefreshFlag(true)
                setBookingURL(false)
                setSuccessAlert(true)
                setTimeout(() => {
                    setSuccessAlert(false);
                }, 1000);
            },
            (error) => {
                setFailureAlert(true)
                setTimeout(() => {
                    setFailureAlert(false);
                }, 1500);
            }
        );
    }

    return(
        <>
            <Card style={{backgroundColor: '#B2DFDB'}}>
                <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                    <h3 className="mb-0">Company Booking Page</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="3">
                            <label>{bookingPageURL()}</label>
                        </Col>
                        <Col lg="6">
                            {!bookingUrl ? (
                                <React.Fragment>
                                    <label>{props.companyName}</label>
                                </React.Fragment>) : (
                                <Input
                                    placeholder={props.companyName}
                                    type="text"
                                    value={bookingName}
                                    onChange={(event) => setBookingName(event.target.value)}
                                />
                            )}
                            <label>.bookmyagenda.com</label>


                        </Col>
                        <Col lg="3">
                            {
                                bookingUrl ? (
                                    <React.Fragment>
                                        <button
                                            className={"btn-success"}
                                            onClick={() => onSave({})}
                                        >
                                            {saveText()}
                                        </button>

                                        <button
                                            className={"btn-secondary"}
                                            style={{marginLeft: 8}}
                                            onClick={() => setBookingURL(false)
                                            }
                                        >
                                            {cancelText()}
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <button
                                        className={"btn-primary"}
                                        onClick={() => setBookingURL(true)
                                        }
                                    >
                                        {editText()}
                                    </button>
                                )
                            }
                        </Col>
                        {successAlert && <div className='alert-container'>
                            <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Details Saved
                                successfully!!
                            </div>
                        </div>}
                        {failureAlert && <div className='alert-container'>
                            <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Error, please try again!
                            </div>
                        </div>}
                    </Row>
                </CardBody>
            </Card>
        </>
    )

}

export default CompanyBookingPageCard