import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToggleButton from '@mui/material/ToggleButton';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Container, Modal } from "@mui/material";
import axiosInstance from "../../helper/AxiosMainHelper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from '@mui/icons-material/Check';


import "./Categories2.css";
import { useState } from "react";
import { useEffect } from "react";
import BackDrop from "../../helper/BackDrop";
import DeleteService from "./DeleteService";
import EditService from "./EditService";
import AddService from "./AddService";
import DeleteCategory from "./DeleteCategory";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import AuthService from "../../../../services/AuthService";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 540,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function Row({
                 row,
                 setOpenModalForDelete,
                 setSelectedCategoryForEditDelete,
                 setOpenModalEditService,
                 setSelectedServiceForEditDelete,
                 setOpenModalDeleteService,
                 setOpenModalCreateService,
                 setSelectedCategory,
                 setOpenModalEditCategory,
             }) {
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(false);

    const serviceDescriptionText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_DESCRIPTION_DE
            : TRANSLATION.SERVICE_DESCRIPTION_EN
    };

    const serviceDurationText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_DURATION_DE
            : TRANSLATION.SERVICE_DURATION_EN
    };
    const serviceAmountText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICE_AMOUNT_DE
            : TRANSLATION.SERVICE_AMOUNT_EN
    };

    return (
        <React.Fragment key={row.groupId}>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }} style={{backgroundColor:"#90caf9"}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setSelectedCategory(row);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                    <div className="icons">
                        <AddIcon
                            className={"icon-sidebar2 add"}
                            onClick={() => {
                                setOpenModalCreateService(true);
                            }}
                        />
                        <EditIcon
                            className={"icon-sidebar2 edit"}
                            onClick={() => {
                                setOpenModalEditCategory(true);
                                setSelectedCategoryForEditDelete(row);
                            }}
                        />
                        <DeleteIcon
                            className={"icon-sidebar2 delete"}
                            onClick={() => {
                                setOpenModalForDelete(true);
                                setSelectedCategoryForEditDelete(row);
                            }}
                        />
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div className="headerMain">
                                <Typography variant="h6" gutterBottom component="div">
                                </Typography>
                                {/* <Button
                  variant="contained"
                  onClick={() => {
                    setOpenModalCreateService(true);
                  }}
                >
                  Create Service
                </Button> */}
                            </div>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                                        <TableCell sx={{ fontWeight: 700 }}>{serviceDescriptionText()}</TableCell>
                                        <TableCell sx={{ fontWeight: 700 }}>{serviceDurationText()}</TableCell>
                                        <TableCell sx={{ fontWeight: 700 }}>{serviceAmountText()} </TableCell>
                                        <TableCell sx={{ fontWeight: 700 }}>Video Conferencing </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.servicesList.map((service) => (
                                        <TableRow key={service.id}>
                                            <TableCell component="th" scope="row">
                                                {service.name}
                                            </TableCell>
                                            <TableCell>{service.description}</TableCell>
                                            <TableCell>{service.duration}</TableCell>
                                            <TableCell>{service.amount}</TableCell>
                                            <TableCell>
                                                <ToggleButton
                                                    value="check"
                                                    selected={service.video}
                                                    onChange={() => {
                                                    setSelected(!selected);
                                                }}
                                                    >
                                                    <CheckIcon />
                                                </ToggleButton>
                                            </TableCell>
                                            <TableCell>
                                                <div className="icons">
                                                    <EditIcon
                                                        className={"icon-sidebar2 edit"}
                                                        onClick={() => {
                                                            setOpenModalEditService(true);
                                                            setSelectedServiceForEditDelete(service);
                                                        }}
                                                    />
                                                    <DeleteIcon
                                                        className={"icon-sidebar2 delete"}
                                                        onClick={() => {
                                                            setOpenModalDeleteService(true);
                                                            setSelectedServiceForEditDelete(service);
                                                        }}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function Categories() {
    const companyId = AuthService.getCurrentUser().companyId
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEditCategory, setOpenModalEditCategory] = useState(false);
    const [selectedCategoryForEditDelete, setSelectedCategoryForEditDelete] =
        useState(null);
    const [openModalForDelete, setOpenModalForDelete] = useState(false);
    const [openModalCreateService, setOpenModalCreateService] = useState(false);
    const [openModalDeleteService, setOpenModalDeleteService] = useState(false);
    const [selectedServiceForEditDelete, setSelectedServiceForEditDelete] =
        useState(null);
    const [openModalEditService, setOpenModalEditService] = useState(false);

    const servicesText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SERVICES_DE
            : TRANSLATION.SERVICES_EN
    };

    const addCategoryText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ADD_CATEGORY_DE
            : TRANSLATION.ADD_CATEGORY_EN
    };
    const fetchCategories = async () => {
        try {
            const response = await axiosInstance.get(
                `appointment/resource-group?companyId=${companyId}`
            );
            if (response?.status === 200) {
                if (response.data.length > 0) {
                    setCategoryList(response.data);
                    if (selectedCategory) {
                        setSelectedCategory(
                            response.data.find((s) => s.groupId === selectedCategory.groupId)
                        );
                    } else {
                        setSelectedCategory(response.data[0]);
                    }
                }
                else{
                    setCategoryList(null);
                    setSelectedCategory("abc")
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    if (!selectedCategory) {
        return <BackDrop loading={true} />;
    }
    return (
        <Container maxWidth="xl">
            <div className="headerWithBtn">
                <h1>{servicesText()}</h1>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    {addCategoryText()}
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {/*<TableCell>name</TableCell>*/}
                            {/*<TableCell>description</TableCell>*/}
                            {/*<TableCell>Action</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categoryList && categoryList.map((row) => (
                            <Row
                                key={row.name}
                                row={row}
                                setOpenModalForDelete={setOpenModalForDelete}
                                setSelectedCategoryForEditDelete={
                                    setSelectedCategoryForEditDelete
                                }
                                setOpenModalEditService={setOpenModalEditService}
                                setSelectedServiceForEditDelete={
                                    setSelectedServiceForEditDelete
                                }
                                setOpenModalDeleteService={setOpenModalDeleteService}
                                setOpenModalCreateService={setOpenModalCreateService}
                                setSelectedCategory={setSelectedCategory}
                                setOpenModalEditCategory={setOpenModalEditCategory}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* MODAL FOR CREATE CATEGORY */}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                disableEscapeKeyDown
                hideBackdrop
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AddCategory
                        setOpenModal={setOpenModal}
                        companyId={companyId}
                        fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR EDIT CATEGORY */}
            <Modal
                open={openModalEditCategory}
                onClose={() => setOpenModalEditCategory(false)}
                disableEscapeKeyDown
                hideBackdrop
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <EditCategory
                        setOpenModal={setOpenModalEditCategory}
                        companyId={companyId}
                        fetchCategories={fetchCategories}
                        category={selectedCategoryForEditDelete}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR DELETE CATEGORY */}
            <Modal
                open={openModalForDelete}
                onClose={() => setOpenModalForDelete(false)}
                disableEscapeKeyDown
                hideBackdrop
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DeleteCategory
                        setOpenModal={setOpenModalForDelete}
                        resourceId={
                            selectedCategoryForEditDelete
                                ? selectedCategoryForEditDelete.groupId
                                : null
                        }
                        fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR CREATE SERVICE */}
            <Modal
                open={openModalCreateService}
                onClose={() => setOpenModalCreateService(false)}
                disableEscapeKeyDown
                hideBackdrop
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AddService
                        setOpenModal={setOpenModalCreateService}
                        resourceGroupId={selectedCategory.groupId}
                        companyId={companyId}
                        fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR EDIT SERVICE */}
            <Modal
                open={openModalEditService}
                onClose={() => setOpenModalEditService(false)}
                disableEscapeKeyDown
                hideBackdrop
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <EditService
                        setOpenModal={setOpenModalEditService}
                        service={selectedServiceForEditDelete}
                        resourceGroupId={selectedCategory.groupId}
                        companyId={companyId}
                        fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR DELETE SERVICE */}
            <Modal
                open={openModalDeleteService}
                onClose={() => setOpenModalDeleteService(false)}
                disableEscapeKeyDown
                hideBackdrop
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DeleteService
                        setOpenModal={setOpenModalDeleteService}
                        resourceId={
                            selectedServiceForEditDelete
                                ? selectedServiceForEditDelete.id
                                : null
                        }
                        fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>
        </Container>
    );
}
