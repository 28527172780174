import {Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import React, {useContext} from "react";
import styles from "../../../../../assets/css/styles";
import {useForm} from "react-hook-form";
import bookingContext from "../../context/bookingContext";

const CustomerDetails = props => {
    const {bookingDetails, setBookingDetails, setProceedNext} = useContext(bookingContext); // Context API
    const {register, handleSubmit} = useForm();

    const {bookingData} = bookingDetails;

    function setNameOnContext( e) {
        const data = {...bookingDetails}
        data["customerDto"]["name"] = e.target.value
        setBookingDetails(data)
    }

    function setEmailOnContext(e) {
        const data = {...bookingDetails}
        data["customerDto"]["email"] = e.target.value
        setBookingDetails(data)
    }

    function setPhoneOnContext(e) {
        const data = {...bookingDetails}
        data["customerDto"]["phone"] = e.target.value
        setBookingDetails(data)
    }

    return(
        <>
            <Card>
                <CardBody>
                    You are about to book an appointment with {bookingData.staffName} for a {bookingData.serviceName} on
                     {" "+bookingData.bookingDate} at {" "+bookingData.timeSlot}.
                     Please enter your details below and submit.
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    Customer Details
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                    <Input
                        name="name"
                        {...register("name", {required: true})}
                        placeholder="Name"
                        onChange={(e) =>
                            setNameOnContext(e)}
                        style={styles.inputWithExtraBottom}
                    />
                    <Input
                        name="email"
                        {...register("email", {required: true})}
                        placeholder="Email"
                        onChange={(e) => setEmailOnContext(e)}
                        style={styles.inputWithExtraBottom}
                    />
                    <Input
                        name="phone"
                        placeholder="phone"
                        {...register("phone", {required: true})}
                        onChange={(e) => setPhoneOnContext(e)}
                        style={styles.inputWithExtraBottom}
                    />
                </CardBody>
            </Card>
        </>
    )

}

export default CustomerDetails
