import AuthHeader from "../../../components/Headers/AuthHeader";
import {Box, Container} from "@mui/system";
import {Button} from "@mui/material";
import StepperMain from "../helper/StepperMain";
import BusinessDetails from "../../../components/Onboarding/BusinessDetails";
import WorkHours from "../../../components/Onboarding/WorkHours";
import AddEmployeeForm from "../../../components/Onboarding/AddEmployeeForm";
import AddServicesOnboard from "../../../components/Onboarding/AddServices";

const PrivacyEn = props => {
    return (
        <Container maxWidth="xl">
            <div className="onboarding-wrapper">
                <div >
                    <h1> Privacy Policy for BookMyAgenda</h1>
                    <h3> Effective Date: [28/11/2023] </h3>
                </div>
                <div >
                    <h1>Introduction</h1>
                    <h3>
                        Welcome to BookMyAgenda ("we," "our," or "us"). We are committed to respecting your privacy and protecting your personal information. This Privacy Policy explains how we collect, use, and disclose data when you use our website and grant us access to your Google Calendar to manage events based on your bookings.
                    </h3>
                </div>
                <div >
                    <h1>Information We Collect</h1>
                    <h2>
                        When you use BookMyAgenda, we collect the following types of information:
                    </h2>
                    <h3>
                        a.Basic details including your mail and postal address which helps us to let your customers know how to reach out to you when they have made a booking.
                    </h3>
                    <h3>
                        b. Google Calendar Data: When you grant us access to your Google Calendar, we may read, create, and delete events on your calendar based on your bookings through BookMyAgenda. We only access the data necessary to provide our services.
                    </h3>
                </div>
                <div >
                    <h1>How We Use Your Information</h1>
                    <h2>
                        We use your information, including Google Calendar data, for the following purposes:
                    </h2>
                    <h3>
                        a. Service Provision: To facilitate the booking process and synchronize it with your Google Calendar, including creating, updating, or deleting events.
                    </h3>
                    <h3>
                        b. Customer Support: To assist you with any inquiries or issues you may have while using our services.
                    </h3>
                </div>
                <div>
                    <h1>Information Sharing </h1>
                    <h2>We do not sell or share your Google Calendar data with third parties unless:</h2>
                    <h3>a. Legal Compliance: We may disclose your information if required by law or in response to a valid legal request.
                    </h3>
                </div>
                <div>
                    <h1>Security</h1>
                    <h3>We employ security measures to safeguard your data from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of data transmission over the internet is entirely secure, and we cannot guarantee the absolute security of your data.</h3>
                </div>
                <div>
                    <h1>Your Choices</h1>
                    <h3>You have the right to revoke our access to your Google Calendar at any time by adjusting your settings. However, please note that doing so may affect the functionality of BookMyAgenda, and certain features may become unavailable.</h3>
                </div>
                <div>
                    <h1>Cookies and Tracking Technologies</h1>
                    <h3>We may use cookies and similar tracking technologies to enhance your browsing experience on our website. You can manage or disable cookies in your browser settings.</h3>
                </div>
                <div>
                    <h1>Google API Services User Data Policy</h1>
                    <h3>BookMyAgenda's use and transfer to any other app of information received from Google APIs will adhere to  <b><a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a></b>, including the Limited Use requirements.</h3>
                </div>
                <div>
                    <h1>Changes to This Privacy Policy</h1>
                    <h3>We may update this Privacy Policy periodically to reflect changes in our practices, website features, or legal requirements. We recommend reviewing this policy regularly for any updates.</h3>
                </div>
                <div>
                    <h1>Contact Us</h1>
                    <h3>If you have questions or concerns about this Privacy Policy or our data handling practices, please contact us at support@bookmyagenda.com</h3>
                </div>
            </div>
        </Container>
    )
}

export default PrivacyEn