import BusinessHoursModal from "../BusinessHoursModal";
import React from "react";

const StaffBusinessHours = (props) => {

    return (
        <>
            {
                props.businessHours.filter(item => item.staffId === props.inEditMode.rowKey).map((item) =>
                    <BusinessHoursModal
                        workingHours={item.businessHoursDto}></BusinessHoursModal>
                )
            }
        </>

    )
}

export default StaffBusinessHours