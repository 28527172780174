import AuthHeader from "components/Headers/AuthHeader";
import {
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Text
} from "reactstrap";
import React from "react";
import {GoogleLogin} from 'react-google-login';
import {gapi} from 'gapi-script';
import styles from "../../../assets/css/styles";
import {useHistory} from "react-router-dom";
import AuthService from "../../../services/AuthService";

const UnsuccessfulLogin = (props) => {

    const history = useHistory();
    const clientId = '536709672021-dr3uch132daipeb6smk29arofpvnmkr3.apps.googleusercontent.com';

    const onSuccess = (res) => {
        AuthService.register(null, null, null, null, res.tokenId).then(
            (response) => {
                history.push("/auth/slogin");
            },
            (error) => {
                if (error.response.data.message.includes("email")) {
                    history.push("/auth/flogin");
                }
            }
        );
    };

    const onFailure = (err) => {
        console.log('failed:', err);
    };

    return (
        <>
            <AuthHeader
                title="User Not Present"
                lead="Hey there!, We could not find you in our system yet."
            />
            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Card>
                        <CardHeader>
                            <b>Are you working for a company which already uses bookmyagenda?</b>
                        </CardHeader>
                        <CardBody>
                            <span>Please contact your owner or admin so that they could add you</span><br/>
                            <span>to the system. You will get an invite after that</span>
                        </CardBody>
                    </Card>
                </Row>
                <Row className="justify-content-center">
                    <Card>
                        <CardHeader>
                            <b> Would you like to create a new account for your company?</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Button style={styles.inputWithExtraBottom}
                                        color="success" type="submit" onClick={() => history.push({
                                    pathname: "/auth/register"
                                })}>Create Account</Button>
                            </Row>
                            <Row>
                                <GoogleLogin
                                    clientId={clientId}
                                    buttonText="Create account with Google"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    cookiePolicy={'single_host_origin'}
                                    // isSignedIn={true}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default UnsuccessfulLogin