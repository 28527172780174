import BookingsList from "./BookingsList";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import React from "react";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

const BookingMultiTabs = (props) => {

    const thisWeekText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.THIS_WEEK_DE
            : TRANSLATION.THIS_WEEK_EN;
    };

    const futureText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.FUTURE_DE
            : TRANSLATION.FUTURE_EN;
    };

    const pastText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.PAST_DE
            : TRANSLATION.PAST_EN;
    };
    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>{thisWeekText()}</Tab>
                    <Tab>{futureText()}</Tab>
                    <Tab>{pastText()}</Tab>
                </TabList>

                <TabPanel>
                    <BookingsList deleteBooking={props.deleteBooking}
                                  bookings={props.bookings}>

                    </BookingsList>

                </TabPanel>
                <TabPanel>
                    <BookingsList deleteBooking={props.deleteBooking}
                                  bookings={props.futureBookings}>
                    </BookingsList>
                </TabPanel>
                <TabPanel>
                    <BookingsList deleteBooking={props.deleteBooking}
                                  bookings={props.pastBookings}>
                    </BookingsList>
                </TabPanel>
            </Tabs>
        </>
    )
}

export default BookingMultiTabs