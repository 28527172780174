import AuthHeader from "../../../../../components/Headers/AuthHeader";

function BookingSuccess() {
    return (
        <>
            <AuthHeader
                title="Appointment Booked Successfully"
                lead="Your Appointment has been booked successfully and confirmation sent to your email."
            />
        </>
    );

}

export default BookingSuccess