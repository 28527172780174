import {Calendar} from 'react-calendar';
import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import BookingService from "../../services/BookingService";
import styles from "../../assets/css/styles";
import TranslationService from "../../services/TranslationService";
import * as TRANSLATION from "../../services/TranslationService";


const BookingsSlot = (props) => {
    const [bookingDate, setBookingDate] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [bookingTimes, setBookingTimes] = useState(null);
    const [bookingFirstSlots, setBookingFirstSlots] = useState([]);
    const [bookingSecondSlots, setBookingSecondSlots] = useState([]);

    const selectDateText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SELECT_DATE_DE
            : TRANSLATION.SELECT_DATE_EN;
    };

    const availableSlotsText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.AVAILABLE_SLOTS_DE
            : TRANSLATION.AVAILABLE_SLOTS_EN;
    };

    React.useEffect(() => {
        setBookingTimes(null);
        setSelectedTimeSlot(null)
        props.onChangeTimeSlot(null)
    }, [props.userid, props.serviceid])

    const onDateChange = e => {
        setSelectedTimeSlot(null);
        setBookingDate(e);
        props.onChangeTimeSlot(null)

        let date = e.toDateString().slice(4).replace(/ /g, '');
        console.log(date)
        date = date.substr(0, 3) + "/" + date.substr(3, 2) + "/" + date.substr(5, date.length)
        props.onChangeBookingDate(date)

        BookingService.findFreeSlots(props.serviceid, props.userid,
            props.companyid, date, props.selectedServicesList).then(
            (response) => {
                setBookingTimes(response.data);
                let bookingFreeSlotsList = response.data;

                const middleIndex = Math.ceil(bookingFreeSlotsList.length / 2);

                setBookingFirstSlots(bookingFreeSlotsList.splice(0, middleIndex));
                setBookingSecondSlots(bookingFreeSlotsList.splice(-middleIndex));
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    };

    return (
        <>
            <Container>
            <Row>
                <Card>
                    <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                        <b>{selectDateText()}</b>
                    </CardHeader>
                    <CardBody>
                        <Calendar onChange={onDateChange} value={bookingDate}/>
                    </CardBody>
                </Card>
            </Row>
            <Row>
                {bookingTimes ? (
                    <Card>
                        <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                            <b>{availableSlotsText()}</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    {bookingFirstSlots.map(time => {
                                        return (
                                            <Button
                                                style={styles.input}
                                                key={time}
                                                onClick={e => {
                                                    setSelectedTimeSlot(time)
                                                    props.onChangeTimeSlot(time)
                                                }}
                                            >
                                                {time}
                                            </Button>
                                        );
                                    })}
                                </Col>
                                <Col>
                                    {bookingSecondSlots.map(time => {
                                        return (
                                            <Button
                                                style={styles.input}
                                                key={time}
                                                onClick={e => {
                                                    setSelectedTimeSlot(time)
                                                    props.onChangeTimeSlot(time)
                                                }}
                                            >
                                                {time}
                                            </Button>
                                        );
                                    })}
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                ) :null }

            </Row>
            </Container>
            {bookingDate && selectedTimeSlot ? (
                <Container>
                    <Row>
                        <Col md="6">
                            <b>Selected slot: {bookingDate.toDateString()} at {selectedTimeSlot}</b>
                        </Col>
                    </Row>
                </Container>

            ) : null}
        </>
    )

}

export default BookingsSlot;