import React, {useState, useEffect} from "react";
import {Card, CardBody, CardHeader, Col, Label, Row} from "reactstrap";
import AuthService from "../../../services/AuthService";
import TranslationService from "../../../services/TranslationService";
import CompanyService from "../../../services/CompanyService";
import BookingsSlot from "../../../components/booking/BookingsSlot";
import Select from "react-select";
import * as TRANSLATION from "../../../services/TranslationService"


const BookingModal = props => {
    const [staff, setStaff] = useState([]);
    const [customers, setCustomers] = React.useState(null)
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);


    const [userid, setuserId] = useState(null);
    const [serviceid, setServiceId] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    let [servicesList, setServicesList] = useState([]);
    let [selectedServicesList, setSelectedServicesList] = useState([]);
    let [selectedServicesListOptions, setSelectedServicesListOptions] = useState([]);

    const bookingDetailsText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKINGS_DETAILS_DE
            : TRANSLATION.BOOKINGS_DETAILS_EN;
    };

    const selectStaffText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SELECT_STAFF_DE
            : TRANSLATION.SELECT_STAFF_EN;
    };

    const selectServiceText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SELECT_SERVICE_DE
            : TRANSLATION.SELECT_SERVICE_EN;
    };

    const selectCustomerText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SELECT_CUSTOMER_DE
            : TRANSLATION.SELECT_CUSTOMER_EN;
    };

    const getBookingDate = (date) => {
        props.onChangeBookingDate(date)
    }

    const getTimeSlot = (timeslot) => {
        props.onChangeTimeSlot(timeslot)
    }

    const changeHandler = value => {
        setSelectedServicesList([])
        setSelectedServicesListOptions([])
        console.log(value)
        let i = 0;
        const tmpArray = [];
        const tmpArrayOptions = [];

        while (i < value.length) {
            tmpArray.push(value[i].value)
            tmpArrayOptions.push({
                value: value[i].value,
                label: value[i].label
            })
            console.log(value[i].value);
            i++;
        }
        setSelectedServicesList(tmpArray)
        setSelectedServicesListOptions(tmpArrayOptions)
        props.onChangeServicesList(tmpArray)

    }


    React.useEffect(() => {
        CompanyService.getStaff(AuthService.getCurrentUser().companyId).then(
            (response) => {
                console.log(response.data)
                setStaff(response.data);
            },
            (error) => {
            }
        );
        CompanyService.getCustomer(AuthService.getCurrentUser().companyId).then(
            (response) => {
                console.log(response.data)
                setCustomers(response.data)
            },
            (error) => {
            }
        );
    }, [])


    const handleChangeStaff = event => {
        console.log(servicesList)

        setServicesList([])
        setSelectedServicesListOptions([])
        console.log(servicesList)

        const value = staff.filter(function (item) {
            let selectedIndex = event.target.options.selectedIndex;
            return item.staffId === event.target.options[selectedIndex].getAttribute('data-key')
        });
        console.log("staff")
        console.log(value[0])
        const tmpArray = [];

        value[0].resourceDtoList.map(service => {
                if (service.valid) {
                    tmpArray.push({
                        value: service.id,
                        label: service.name
                    })
                }
            }
        )

        setServicesList(tmpArray)


        console.log("servicesList")
        console.log(servicesList)

        setSelectedStaff(value[0]);
        setuserId(value[0].staffId)
        props.onChangeUserId(value[0].staffId)
    };

    const handleChangeCustomer = event => {
        const value = customers.filter(function (item) {
            let selectedIndex = event.target.options.selectedIndex;
            return item.id === event.target.options[selectedIndex].getAttribute('data-key')
        });
        setSelectedCustomer(value[0]);

        if (value.length === 0) {
            return
        } //Here NA is selected as the customer
        setCustomerId(value[0].id)
        props.onChangeCustomerId(value[0].id)
    };

    const handleChangeService = event => {
        console.log(event.target.options)
        console.log(event.target.options.selectedIndex)
        const value = selectedStaff.resourceDtoList.filter(function (item) {
            let selectedIndex = event.target.options.selectedIndex;
            return item.id === event.target.options[selectedIndex].getAttribute('data-key')
        });
        setServiceId(value[0].id)
        props.onChangeServiceId(value[0].id)
    };

    return (
        <>
            <Card style={{backgroundColor: '#4DD0E1'}}>
                <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                    <h3 className="mb-0">{bookingDetailsText()}</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="4">
                            <Label>{selectStaffText()}</Label>
                        </Col>
                        <Col>
                            <select onChange={handleChangeStaff} name="fruits" id="fruit-select" value={null}>
                                <option value="none" selected disabled hidden>{selectStaffText()}</option>
                                {staff.map(option => (
                                    <option key={option.staffId} data-key={option.staffId} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            <blockquote>
                                <small><cite title="Source Title">  {selectedStaff && selectedStaff.email}<i
                                    className="icon-map-marker"></i></cite></small>
                            </blockquote>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Label>{selectServiceText()}</Label>
                        </Col>
                        <Col>
                            {/*<select multiple={true} aria-multiselectable={true} onChange={handleChangeService}*/}
                            {/*        name="fruits" id="fruit-select">*/}
                            {/*    /!*<option value="none" selected disabled hidden>Select Service</option>*!/*/}
                            {/*    {selectedStaff && selectedStaff.resourceDtoList.map(option => option.valid && (*/}
                            {/*        <option key={option.id} data-key={option.id} value={option.name}>*/}
                            {/*            {option.name}*/}
                            {/*        </option>*/}
                            {/*    ))}*/}
                            {/*</select>*/}
                            {servicesList && <Select options={servicesList} value={selectedServicesListOptions} onChange={changeHandler} isMulti  escapeClearsValue/>}

                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Label>{selectCustomerText()}</Label>
                        </Col>
                        <Col>
                            <select onChange={handleChangeCustomer} name="fruits" id="fruit-select" value={null}>
                                <option value="none" selected disabled hidden>{selectCustomerText()}</option>
                                <option id="null">NA</option>

                                {customers && customers.map(option => (
                                    <option key={option.id} data-key={option.id} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            <blockquote>
                                <small><cite title="Source Title">  {selectedCustomer && selectedCustomer.email}<i
                                    className="icon-map-marker"></i></cite></small>
                            </blockquote>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <BookingSlot onChangeBookingDate={getBookingDate} onChangeTimeSlot={getTimeSlot}*/}
                    {/*                 userid={userid} serviceid={serviceid} customerid = {customerId} companyid = {AuthService.getCurrentUser().companyId}/>*/}
                    {/*</Row>*/}
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <BookingsSlot onChangeBookingDate={getBookingDate} onChangeTimeSlot={getTimeSlot}
                                  userid={userid} serviceid={serviceid} selectedServicesList={selectedServicesList}
                                  customerid={customerId}
                                  companyid={AuthService.getCurrentUser().companyId}/>
                </CardBody>
            </Card>

        </>
    );
}

export default BookingModal;
