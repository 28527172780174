import {GoogleLogin} from 'react-google-login';
import {gapi} from 'gapi-script';
import {
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import * as CONSTANTS from "../../../Constants"
import SocialCalendarService from "../../../services/social/SocialCalendarService";
import authService from '../../../services/AuthService'
import React, {useState} from "react";
import {Checkmark} from 'react-checkmark'
import {FaWindowClose} from 'react-icons/fa'
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";

const CalendarSync = (props) => {

    const calendarSyncDescriptionText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CALENDAR_SYNC_DESC_DE
            : TRANSLATION.CALENDAR_SYNC_DESC_EN
    };

    const googleCalendarBetaMessage = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.GOOLGE_CALENDAR_BETA_DE
            : TRANSLATION.GOOLGE_CALENDAR_BETA_EN
    };


    const [response, setResponse] = useState([]);

    let redirect_url = CONSTANTS.GOOGLE_REDIRECT_URI
    let language = 'en'

    console.log("redirect_url_first")
    console.log(redirect_url)

    if (window.location.host.split(".")[0].includes('de')) {
        redirect_url = CONSTANTS.GOOGLE_REDIRECT_URI_DE
        language = 'de'
    }
    console.log("redirect_url_second")
    console.log(redirect_url)

    React.useEffect(() => {
        SocialCalendarService.getCalendar(authService.getCurrentUser().id).then(
            (response) => {
                console.log(response.data)
                setResponse(response.data)
            },
            (error) => {
            }
        );
    }, [])


    const onSuccess = (res) => {
        SocialCalendarService.updateCalendar(authService.getCurrentUser().id, res.code, null, language).then(
            (response) => {
                console.log(res)
                window.location.reload(false);
            },
            (error) => {
                console.log(res)
            }
        );
    };

    const onFailure = (err) => {
        console.log('failed:', err);
    };

    return (
        <>
            <Card>
                <CardHeader>
                    <b>{calendarSyncDescriptionText()}</b>
                    {/*<b>{googleCalendarBetaMessage()}</b>*/}
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="6" md="8">
                            <GoogleLogin
                                clientId={CONSTANTS.CLIENT_ID}
                                buttonText="Sync with Google"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                                responseType='code'
                                accessType='offline'
                                // isSignedIn={true}
                                redirectUri={redirect_url}
                                scope='openid email profile https://www.googleapis.com/auth/calendar.events'
                            />
                        </Col>
                        <Col>
                            {response.googleSync && <div>
                                <Checkmark/>
                            </div>}
                            {!response.googleSync && <div>
                                <FaWindowClose/>
                            </div>}
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </>
    )
}

export default CalendarSync
