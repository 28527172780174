import React from "react";
import AuthHeader from "../../../components/Headers/AuthHeader";
import {Button, Card, CardBody, Col, Container, Input, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import AuthService from "../../../services/AuthService";
import styles from "../../../assets/css/styles";

function ResetPassword(){
    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const [password, setPassword] = React.useState("NA");


    function onSubmit(data) {
        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get("token")

        AuthService.setNewPassword(password, token).then(
            (response) => {
                history.push("/auth/reset-password-success");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    return (
        <>
            <AuthHeader
                title="Password Reset"
                lead="Enter your new Password"
            />
            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="6" md="8">
                        <Card className="bg-secondary border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                    <Input
                                        name="password"
                                        type="password"
                                        {...register("password", { required: true })}
                                        onChange={(e) => setPassword(`${e.target.value}`)}
                                        placeholder="Password"
                                        style={styles.inputWithExtraBottom}
                                    />
                                    <Button color="success" onClick={onSubmit} type="submit">Submit</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetPassword