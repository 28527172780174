import React, {useContext, useState} from "react";
import CompanyService from "../../../../../services/CompanyService";
import {Button, Card, CardBody, CardHeader, Col, Container, Label, Row} from "reactstrap";
import BookingsSlot from "../../../../../components/booking/BookingsSlot";
import bookingContext from "../../context/bookingContext";
import styles from "../../../../../assets/css/styles";
import UserBooking from "./UserBooking";
import {UncontrolledCarousel} from "reactstrap";
import ExternalBookingHeader from "../../../../../components/Headers/ExternalBookingHeader";
import TranslationService from "../../../../../services/TranslationService";
import * as TRANSLATION from "../../../../../services/TranslationService";

const ExternalBookingMain = props => {
    const {bookingDetails, setBookingDetails, setProceedNext} = useContext(bookingContext); // Context API
    const [staff, setStaff] = useState([]);
    const [response, setResponse] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);

    const [userid, setuserId] = useState(null);
    const [serviceid, setServiceId] = useState(null);
    const [companyid, setCompanyId] = useState(null);
    const [bookingDate, setBookingDate] = React.useState(null)
    const [timeSlot, setTimeSlot] = React.useState(null)

    const selectStaffText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SELECT_STAFF_DE
            : TRANSLATION.SELECT_STAFF_EN;
    };
    function saveAndProceedToNextPage() {
        const data = {...bookingDetails}
        data["bookingData"]["staff"] = userid
        data["bookingData"]["service"] = serviceid
        data["bookingData"]["company"] = companyid
        data["bookingData"]["bookingDate"] = bookingDate
        data["bookingData"]["timeSlot"] = timeSlot
        setBookingDetails(data)

        const {bookingData} = bookingDetails;

        console.log(bookingData.staff)
    }

    const getBookingDate = (date) => {
        const data = {...bookingDetails}
        data["bookingData"]["bookingDate"] = date
        setBookingDetails(data)
        setBookingDate(date)
    }

    const getTimeSlot = (timeslot) => {
        const data = {...bookingDetails}
        data["bookingData"]["timeSlot"] = timeslot
        setBookingDetails(data)
        setTimeSlot(timeSlot)
    }

    function getDetailsFromUserBooking() {
        const getCompanyIdFromUserBooking = (companyId) => {
            const data = {...bookingDetails}
            data["bookingData"]["company"] = companyId
            setBookingDetails(data)
            setCompanyId(companyId)
        }

        const getServiceIdFromUserBooking = (serviceId, name) => {
            const data = {...bookingDetails}
            data["bookingData"]["service"] = serviceId
            data["bookingData"]["serviceName"] = name
            setBookingDetails(data)
            setServiceId(serviceId)
        }

        const getUserIdFromUserBooking = (userId, name) => {
            const data = {...bookingDetails}
            data["bookingData"]["staff"] = userId
            data["bookingData"]["staffName"] = name
            setBookingDetails(data)
            setuserId(userId)
        }
        return {getCompanyIdFromUserBooking, getServiceIdFromUserBooking, getUserIdFromUserBooking};
    }

    const {
        getCompanyIdFromUserBooking,
        getServiceIdFromUserBooking,
        getUserIdFromUserBooking
    } = getDetailsFromUserBooking();

    function setDetailsForCompanyBooking(response) {
        setStaff(response.data.staffDetailsList);
        setCompanyId(response.data.companyDetails.id)
        const data = {...bookingDetails}
        data["bookingData"]["company"] = response.data.companyDetails.id
        setBookingDetails(data)
    }

    React.useEffect(() => {
        let companyName = window.location.host.split(".")[0]
        CompanyService.getCompanyDetails(companyName).then(
            (response) => {
                console.log(response.data)
                if (!!(response.data.companyDetails)) {
                    setDetailsForCompanyBooking(response);
                }
                setResponse(response.data)
            },
            (error) => {
            }
        );
    }, [])


    const handleChangeStaff = event => {
        const value = staff.filter(function (item) {
            function setStaffNameInContext() {
                const data = {...bookingDetails}
                data["bookingData"]["staffName"] = event.target.value
                setBookingDetails(data)
            }

            setStaffNameInContext();
            let selectedIndex = event.target.options.selectedIndex;
            return item.staffId === event.target.options[selectedIndex].getAttribute('data-key')
        });

        setSelectedStaff(value[0]);
        setuserId(value[0].staffId)

        function setStaffIdInContext() {
            const data = {...bookingDetails}
            data["bookingData"]["staff"] = value[0].id
            setBookingDetails(data)
        }

        setStaffIdInContext();

    };

    const handleChangeService = event => {
        const value = selectedStaff.resourceDtoList.filter(function (item) {
            function setServiceNameInContext() {
                const data = {...bookingDetails}
                data["bookingData"]["serviceName"] = event.target.value
                setBookingDetails(data)
            }

            setServiceNameInContext();
            let selectedIndex = event.target.options.selectedIndex;
            return item.id === event.target.options[selectedIndex].getAttribute('data-key')
        });
        setServiceId(value[0].id)

        function setServiceidInContext() {
            const data = {...bookingDetails}
            data["bookingData"]["service"] = value[0].id
            setBookingDetails(data)
        }

        setServiceidInContext();
        // props.onChangeServiceId(value[0].id)
    };

    return (
        <>

            <div>{!!(response.currentStaffDetails) ? (

                <>
                    <p>Hi there!, welcome to the booking page of {response.currentStaffDetails.name}. Please select the
                        appropriate event to add an event to my calendar.
                        You can also reach me at {response.currentStaffDetails.email} for any queries in prior</p>

                    <Row>

                    <UserBooking staff={response.currentStaffDetails} onChangeService={getServiceIdFromUserBooking}
                                 getCompanyid={getCompanyIdFromUserBooking}
                                 getUserId={getUserIdFromUserBooking}></UserBooking>
                    </Row>


                    <BookingsSlot onChangeBookingDate={getBookingDate} onChangeTimeSlot={getTimeSlot}
                                  userid={response.currentStaffDetails.id} serviceid={serviceid} companyid={companyid}/>

                </>

            ) : null}

            </div>

            <div>{!!(response.companyDetails) ? (
                <Card style={{backgroundColor: '#4DD0E1'}}>
                    <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                        <h3 className="mb-0">Booking Details</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="4">
                                <Label>{selectStaffText()}</Label>
                            </Col>
                            <Col>
                                <select onChange={handleChangeStaff} name="fruits" id="fruit-select" value={null}>
                                    <option value="none" selected disabled hidden>{selectStaffText()}</option>
                                    {staff.map(option => (
                                        <option key={option.staffId} data-key={option.staffId} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                <blockquote>
                                    <small><cite title="Source Title">  {selectedStaff && selectedStaff.email}<i
                                        className="icon-map-marker"></i></cite></small>
                                </blockquote>
                            </Col>
                            w </Row>
                        <Row>
                            <Col md="4">
                                <Label>Select Service</Label>
                            </Col>
                            <Col>
                                <select onChange={handleChangeService} name="fruits" id="fruit-select">
                                    <option value="none" selected disabled hidden>Select Service</option>
                                    {selectedStaff && selectedStaff.resourceDtoList.map(option => option.valid && (
                                        <option key={option.id} data-key={option.id} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <BookingsSlot onChangeBookingDate={getBookingDate} onChangeTimeSlot={getTimeSlot}
                                         userid={userid} serviceid={serviceid} companyid={companyid}/>
                        </Row>
                        <Row>
                            <Col>
                                {timeSlot ? (
                                    <Button
                                        className={"btn btn-info"}
                                        style={{marginLeft: 8}}
                                        onClick={e => {
                                            saveAndProceedToNextPage()
                                        }
                                        }
                                        style={styles.inputWithExtraBottom}
                                    >
                                        Next
                                    </Button>

                                ) : null}

                            </Col>
                        </Row>
                    </CardBody>
                </Card>) : null}</div>
        </>
    );
}

export default ExternalBookingMain;