import React from "react";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import BookingWidget from "./BookingWidget"
import CalendarSync from "./CalendarSync"
import AuthService from "../../../services/AuthService";
import CompanyService from "../../../services/CompanyService";
import {LogoutIfJwtExpired} from "../../../global/Utils";
import {useHistory} from "react-router-dom";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService";
import BookingPageCustomisation from "./BookingPageCustomisation";

const BaseIntegrationsPage = (props) => {

    const history = useHistory();

    const bookingWidgetText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKING_WIDGET_DE
            : TRANSLATION.BOOKING_WIDGET_EN
    };

    const calendarSyncText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CALENDAR_SYNC_DE
            : TRANSLATION.CALENDAR_SYNC_EN
    };

    const bookingCustomisationsText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BOOKING_CUSTOMISATIONS_DE
            : TRANSLATION.BOOKING_CUSTOMISATIONS_EN
    };

    // just so that if a person is idle it logs them out
    React.useEffect(() => {
        if (AuthService.getCurrentUser()) {
            CompanyService.getCustomer(AuthService.getCurrentUser().companyId).then(
                () => {
                },
                (error) => {
                    LogoutIfJwtExpired(error)
                    {
                        history.push("/")
                    }
                }
            );
        } else {
            LogoutIfJwtExpired(null)
            {
                history.push("/")
            }
        }
    }, [])

    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>
                        {bookingWidgetText()}
                    </Tab>
                    <Tab>{calendarSyncText()}</Tab>
                    <Tab>{bookingCustomisationsText()}</Tab>

                </TabList>

                <TabPanel>
                    <BookingWidget></BookingWidget>
                </TabPanel>

                <TabPanel>
                    <CalendarSync></CalendarSync>
                </TabPanel>
                <TabPanel>
                    <BookingPageCustomisation/>
                </TabPanel>
            </Tabs>
        </>
    );

}

export default BaseIntegrationsPage;