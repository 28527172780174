/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

function Pricing() {

    return (
        <>
            <AuthHeader title="Features"
            />

            <Container className="mt--8 pb-5">
                <span>you heard us right</span>
                <Row className="justify-content-center">
                    {/*<Col lg="10">*/}
                    {/*    <div className="pricing card-group flex-column flex-md-row mb-3">*/}
                    {/*          <Card*/}
                    {/*              className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">*/}
                    {/*              <CardHeader className="bg-transparent">*/}
                    {/*                  <h4 className="text-uppercase ls-1 text-white py-3 mb-0">*/}
                    {/*                      Premium pack*/}
                    {/*                  </h4>*/}
                    {/*              </CardHeader>*/}
                    {/*              <CardBody className="px-lg-7">*/}
                    {/*                  <ul className="list-unstyled my-4">*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-calendar"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Unlimited Appointments*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-bars"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Mini website*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-sign"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Company Booking Page*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-sign"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Staff Booking Page*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-code"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Iframe Code*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-bell"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Email reminders - unlimited*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-clock"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Timezone support*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-play"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Video conference (launching soon)*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-credit-card"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Payments(launching soon)*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-calendar"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          2-Way calendar sync(launching soon)*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                      <li>*/}
                    {/*                          <div className="d-flex align-items-center">*/}
                    {/*                              <div>*/}
                    {/*                                  <div*/}
                    {/*                                      className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">*/}
                    {/*                                      <i className="fas fa-envelope"/>*/}
                    {/*                                  </div>*/}
                    {/*                              </div>*/}
                    {/*                              <div>*/}
                    {/*        <span className="pl-2 text-white">*/}
                    {/*          Email Support*/}
                    {/*        </span>*/}
                    {/*                              </div>*/}
                    {/*                          </div>*/}
                    {/*                      </li>*/}
                    {/*                  </ul>*/}
                    {/*                  <Button className="mb-3" color="secondary" type="button">*/}
                    {/*                      Start free*/}
                    {/*                  </Button>*/}
                    {/*              </CardBody>*/}
                    {/*              <CardFooter className="bg-transparent">*/}
                    {/*                  <a*/}
                    {/*                      className="text-white"*/}
                    {/*                      href="#pablo"*/}
                    {/*                      onClick={(e) => e.preventDefault()}*/}
                    {/*                  >*/}
                    {/*                      Contact @ sakthi@bookmyagenda.com*/}
                    {/*                  </a>*/}
                    {/*              </CardFooter>*/}
                    {/*          </Card>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <div className="d-flex justify-content-lg-center px-3 mt-5">
                    <div>
                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                            <i className="ni ni-building text-primary"/>
                        </div>
                    </div>
                    {/*<Col lg="6">*/}
                    {/*    <p className="text-white">*/}
                    {/*        Here is how the pricing plan could look in the start of 2024*/}
                    {/*    </p>*/}
                    {/*</Col>*/}
                </div>
                <Row className="row-grid justify-content-center">
                    <Col lg="10">
                        <Table className="table-dark mt-5" responsive>
                            <thead>
                            <tr>
                                <th className="px-0 bg-transparent" scope="col">
                                    <span className="text-light font-weight-700">Features</span>
                                </th>
                                <th className="text-center bg-transparent" scope="col">
                                    Free Pack
                                </th>
                                <th className="text-center bg-transparent" scope="col">
                                    Premium Pack
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="px-0">Unlimited appointments</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Unlimited Staffs</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Mini Website</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Company Booking Page</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Staff Booking Page</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Iframe code</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Email reminders(unlimited)</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Timezone support</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Reports & Analytics </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td className="px-0">Video conference(launching soon)</td>*/}
                            {/*    <td className="text-center">-</td>*/}
                            {/*    <td className="text-center">*/}
                            {/*        <i className="fas fa-check text-success"/>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td className="px-0">Payments(launching soon)</td>*/}
                            {/*    <td className="text-center">-</td>*/}

                            {/*    <td className="text-center">*/}
                            {/*        <i className="fas fa-check text-success"/>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td className="px-0">2-Way calendar sync(Google)</td>
                                <td className="text-center"><i className="fas fa-check text-success"/>
                                </td>

                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-0">Email/Phone support</td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                                <td className="text-center">
                                    <i className="fas fa-check text-success"/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Pricing;
