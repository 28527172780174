import {Card, CardBody, CardHeader, Col, FormGroup, Input, Row} from "reactstrap";
import TimezoneSelect from "react-timezone-select";
import React, {useState} from "react";
import CompanyService from "../../../../services/CompanyService";
import AuthService from "../../../../services/AuthService";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const CompanyDetailsCard = (props) => {

    const [username, setUsername] = React.useState(props.name)
    const [companyDetails, setCompanyDetails] = useState(false)
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [failureAlert, setFailureAlert] = React.useState(false);

    const businessNameText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BUSINESS_DETAILS_DE
            : TRANSLATION.BUSINESS_DETAILS_EN;
    }

    const timeZoneText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.TIMEZONE_DE
            : TRANSLATION.TIMEZONE_EN;
    };

    const editText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.EDIT_DE
            : TRANSLATION.EDIT_EN;
    };

    const saveText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.SAVE_DE
            : TRANSLATION.SAVE_EN;
    };

    const cancelText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CANCEL_DE
            : TRANSLATION.CANCEL_EN;
    };

    const onSave = () => {
        CompanyService.updateCompany(AuthService.getCurrentUser().companyId, username, props.timeZone).then(
            (response) => {
                console.log(response.data)
                props.setRefreshFlag(true)
                setCompanyDetails(false)
                setSuccessAlert(true)
                setTimeout(() => {
                    setSuccessAlert(false);
                }, 1000);
            },
            (error) => {
                setFailureAlert(true)
                setTimeout(() => {
                    setFailureAlert(false);
                }, 1500);
            }
        );

    }

    const onCancel = () => {
        // setName(null)
        setCompanyDetails(false)
    }

    return (
        <>
            <Card style={{backgroundColor: '#B2DFDB'}}>
                <CardHeader style={{backgroundColor: '#4DD0E1'}}>
                    <h3 className="mb-0">{businessNameText()}</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="4">
                            <label>Name</label>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                {
                                    companyDetails ? (<Input
                                        placeholder={props.username}
                                        type="text"
                                        value={username}
                                        onChange={(event) => setUsername(event.target.value)}
                                    />) : (<label>{props.username}</label>)
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <label>{timeZoneText()}</label>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <div className="select-wrapper">
                                    <TimezoneSelect
                                        name="timeZone"
                                        value={props.timeZone}
                                        onChange={props.setTimeZone}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            {
                                companyDetails ? (
                                    <React.Fragment>
                                        <button
                                            className={"btn-success"}
                                            onClick={() => onSave()}
                                        >
                                            {saveText()}
                                        </button>

                                        <button
                                            className={"btn-secondary"}
                                            style={{marginLeft: 8}}
                                            onClick={() => onCancel()
                                            }
                                        >
                                            {cancelText()}
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <button
                                        className={"btn-primary"}
                                        onClick={() => setCompanyDetails(true)
                                        }
                                    >
                                        {editText()}
                                    </button>
                                )
                            }
                        </Col>
                        {successAlert && <div className='alert-container'>
                            <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Details Saved
                                successfully!!
                            </div>
                        </div>}
                        {failureAlert && <div className='alert-container'>
                            <div style={{backgroundColor: '#F4FF81'}} className='alert-inner'>Error, please try again!
                            </div>
                        </div>}
                    </Row>
                </CardBody>
            </Card>

        </>
    )
}

export default CompanyDetailsCard