import React, {useState} from "react";
import AuthService from "../../../services/AuthService";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import CompanyService from "../../../services/CompanyService";
import {useHistory} from "react-router-dom";
import {LogoutIfJwtExpired} from "../../../global/Utils";
import TranslationService from "../../../services/TranslationService";
import * as TRANSLATION from "../../../services/TranslationService"; // Import css

const CustomerList = props => {

    const [customers, setCustomers] = React.useState(null)
    const history = useHistory();

    const customerText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.CUSTOMER_DE
            : TRANSLATION.CUSTOMER_EN
    };

    React.useEffect(() => {
        if (AuthService.getCurrentUser()) {
            CompanyService.getCustomer(AuthService.getCurrentUser().companyId).then(
                (response) => {
                    console.log(response.data)
                    setCustomers(response.data)
                },
                (error) => {
                    LogoutIfJwtExpired(error)
                    {
                        history.push("/")
                    }
                }
            );
        } else {
            LogoutIfJwtExpired(null)
            {
                history.push("/")
            }
        }
    }, [])


    const deleteCustomer = (bookingId) => {
        CompanyService.deleteCustomer(bookingId).then(
            (response) => {
                console.log(response.data)
            },
            (error) => {
            }
        );
        window.location.reload(false);
    }


    const submit = (customerId) => {

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Deleting the customer will also delete their bookings.' +
                'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCustomer(customerId)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true
    }, {
        dataField: 'email',
        text: 'Email',
        sort: true
    }, {
        dataField: 'phone',
        text: 'Phone'
    }, {
        dataField: 'id',
        text: 'Actions',
        formatter: (cellContent, row) => {
            return (
                <>
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() => {
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() => {
                            submit(row.id)
                        }}
                    >
                        Delete
                    </button>
                </>
            );
        }
    }];

    return (
        <>
            {
                customers && (
                    <Card style={{backgroundColor: '#B2DFDB'}}>
                        <CardHeader className="border-0" style={{backgroundColor: '#4DD0E1'}}>
                            <Row>
                                <Col xs="6">
                                    <h3 className="mb-0">{customerText()}</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <BootstrapTable
                                keyField='id'
                                data={customers}
                                columns={columns}
                                striped
                                bordered={false}
                                wrapperClasses="table-responsive"
                            >
                            </BootstrapTable>
                        </CardBody>
                    </Card>
                )
            }

        </>
    )
}

export default CustomerList