import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React from "react";
import useInput from "../../views/pages/helper/useInput";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function AddEmployeeForm(props) {
    let {
        enteredInput: enteredEmployeeName,
        inputInvalid: employeeNameInputIsInvalid,
        blurHandler: employeeNameInputBlurHandler,
        changeHandler: employeeNameInputChangeHandler,
        reset: employeeNameInputReset,
    } = useInput("", (value) => value !== "");

    let {
        enteredInput: enteredEmail,
        inputInvalid: emailInputIsInvalid,
        blurHandler: emailInputBlurHandler,
        changeHandler: emailInputChangeHandler,
        reset: emailInputReset,
    } = useInput(
        "",
        (value) =>
            value !== "" &&
            value
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                )
    );

    const addEmployeeHandler = (e) => {
        e.preventDefault();

        employeeNameInputBlurHandler();
        emailInputBlurHandler();

        if (!enteredEmployeeName || emailInputIsInvalid) {
            return;
        }
        props.setEmployeeList((prev) => [
            ...prev,
            {
                id: prev.length + 1,
                name: enteredEmployeeName,
                email: enteredEmail,
            },
        ]);

        employeeNameInputReset();
        emailInputReset();
    };

    const deleteHandler = (id) => {
        props.setEmployeeList((prev) => {
            return prev.filter((s) => s.id !== id);
        });
    };

    return (
        <div className="employeeFormWrapper">
            <div className="addEmployeeForm">
                <TextField
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={enteredEmployeeName}
                    onChange={employeeNameInputChangeHandler}
                    onBlur={employeeNameInputBlurHandler}
                    error={employeeNameInputIsInvalid}
                    helperText={
                        employeeNameInputIsInvalid ? "Please enter valid name!" : ""
                    }
                />
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={enteredEmail}
                    onChange={emailInputChangeHandler}
                    onBlur={emailInputBlurHandler}
                    error={emailInputIsInvalid}
                    helperText={
                        emailInputIsInvalid ? "Please enter valid email address!" : ""
                    }
                />
                <Button variant="contained" onClick={addEmployeeHandler}>
                    Add Employee
                </Button>
            </div>

            <div className="employeeListWrapper">
                {props.employeeList.length === 0 && <h3>Please do not add the email you are already registered with</h3>}

                {props.employeeList.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Employee Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.employeeList.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ "& > *": { borderBottom: "unset" } }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.email}
                                        </TableCell>
                                        <TableCell>
                                            <DeleteForeverIcon
                                                onClick={() => deleteHandler(row.id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
}

export default AddEmployeeForm;
