import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StaffDetailsCard from "./StaffDetailsCard";
import StaffResourcesCard from "./StaffResourcesCard";
import StaffBusinessHours from "./StaffBusinessHours";
import StaffVacationsCard from "./StaffVacationsCard";
import StaffBreakCard from "./StaffBreakCard";
import StaffContext from "./context/StaffContext";
import {useState} from "react";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const InitialValues = {
    StaffData: {}
}

const StaffMultiTab = (props) => {
    const [staffDetails, setStaffDetails] = useState(InitialValues);

    const assignedServicesText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.ASSIGNED_SERVICES_DE
            : TRANSLATION.ASSIGNED_SERVICES_EN
    };

    const availabilityText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.AVAILABILITY_DE
            : TRANSLATION.AVAILABILITY_EN
    };
    const vacationText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.VACATION_DE
            : TRANSLATION.VACATION_EN
    }
    const breakText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.BREAK_DE
            : TRANSLATION.BREAK_EN
    };
    return (
        <StaffContext.Provider value={{staffDetails, setStaffDetails}}>
            <Tabs>
                <TabList>
                    <Tab>
                        Details
                    </Tab>
                    <Tab>{assignedServicesText()}</Tab>
                    <Tab>{availabilityText()}</Tab>
                    <Tab>{vacationText()}</Tab>
                    <Tab>{breakText()}</Tab>
                </TabList>

                <TabPanel>
                    <StaffDetailsCard setRefreshFlag={props.setRefreshFlag} inEditMode={props.inEditMode}
                                      staffFlagUpdate={props.staffFlagUpdate}/>
                </TabPanel>
                <TabPanel>
                    <StaffResourcesCard inEditMode={props.inEditMode} data={props.data}
                                        staffFlagUpdate={props.staffFlagUpdate}/>
                </TabPanel>
                <TabPanel>
                    <StaffBusinessHours businessHours={props.businessHours} inEditMode={props.inEditMode}/>
                </TabPanel>
                <TabPanel>
                    <StaffVacationsCard inEditMode={props.inEditMode}/>
                </TabPanel>
                <TabPanel>
                    <StaffBreakCard inEditMode={props.inEditMode}/>
                </TabPanel>
            </Tabs>
        </StaffContext.Provider>
    )
}

export default StaffMultiTab