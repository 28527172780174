import AuthHeader from "../../../components/Headers/AuthHeader";

function ResetPasswordSuccess() {
    return (
        <>
            <AuthHeader
                title="Password Reset Successful"
                lead="Your password has been reset successfully"
            />
        </>
    );

}

export default ResetPasswordSuccess