import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Container, Modal } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import "../../views/pages/settings/Categories/Categories2.css";
import { useState } from "react";
import DeleteService from "../../views/pages/settings/Categories/DeleteService";
import AddService from "../../views/pages/settings/Categories/AddService";
import DeleteCategory from "../../views/pages/settings/Categories/DeleteCategory";
import AddCategory from "../../views/pages/settings/Categories/AddCategory";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 540,
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "90%",
};

function Row({
                 row,
                 setOpenModalForDelete,
                 setSelectedCategoryForEditDelete,
                 setOpenModalEditService,
                 setSelectedServiceForEditDelete,
                 setOpenModalDeleteService,
                 setOpenModalCreateService,
                 setSelectedCategory,
                 setOpenModalEditCategory,
             }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={row.groupId}>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }} style={{backgroundColor:'#90caf9'}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setSelectedCategory(row);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                    <div className="icons">
                        <AddIcon
                            className={"icon-sidebar2 add"}
                            onClick={() => {
                                setOpenModalCreateService(true);
                                setSelectedCategory(row);
                            }}
                        />
                        {/* <EditIcon
              className={"icon-sidebar2 edit"}
              onClick={() => {
                setOpenModalEditCategory(true);
                setSelectedCategoryForEditDelete(row);
              }}
            /> */}
                        <DeleteIcon
                            className={"icon-sidebar2 delete"}
                            onClick={() => {
                                setOpenModalForDelete(true);
                                setSelectedCategoryForEditDelete(row);
                            }}
                        />
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div className="headerMain">
                                <Typography variant="h6" gutterBottom component="div">
                                    Services
                                </Typography>
                                {/* <Button
                  variant="contained"
                  onClick={() => {
                    setOpenModalCreateService(true);
                  }}
                >
                  Create Service
                </Button> */}
                            </div>
                            {row.servicesList.length > 0 && (
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>name</TableCell>
                                            <TableCell>description</TableCell>
                                            <TableCell>duration</TableCell>
                                            <TableCell>amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.servicesList.map((service) => (
                                            <TableRow key={service.id}>
                                                <TableCell component="th" scope="row">
                                                    {service.name}
                                                </TableCell>
                                                <TableCell>{service.description}</TableCell>
                                                <TableCell>{service.duration}</TableCell>
                                                <TableCell>{service.amount}</TableCell>
                                                <TableCell>
                                                    <div className="icons">
                                                        {/* <EditIcon
                            className={"icon-sidebar2 edit"}
                            onClick={() => {
                              setOpenModalEditService(true);
                              setSelectedServiceForEditDelete(service);
                            }}
                          /> */}
                                                        <DeleteIcon
                                                            className={"icon-sidebar2 delete"}
                                                            onClick={() => {
                                                                setOpenModalDeleteService(true);
                                                                setSelectedServiceForEditDelete(service);
                                                                setSelectedCategoryForEditDelete(row);
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function AddServicesOnboard({ categoryList, setCategoryList }) {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEditCategory, setOpenModalEditCategory] = useState(false);
    const [selectedCategoryForEditDelete, setSelectedCategoryForEditDelete] =
        useState(null);
    const [openModalForDelete, setOpenModalForDelete] = useState(false);
    const [openModalCreateService, setOpenModalCreateService] = useState(false);
    const [openModalDeleteService, setOpenModalDeleteService] = useState(false);
    const [selectedServiceForEditDelete, setSelectedServiceForEditDelete] =
        useState(null);
    const [openModalEditService, setOpenModalEditService] = useState(false);

    return (
        <Container maxWidth="xl">
            <div className="headerWithBtn">
                <h1>Categories</h1>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    Create Category
                </Button>
            </div>
            {categoryList.length > 0 && (
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow >
                                <TableCell />
                                <TableCell>name</TableCell>
                                <TableCell>description</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categoryList.map((row) => (
                                <Row
                                    key={row.name}
                                    row={row}
                                    setOpenModalForDelete={setOpenModalForDelete}
                                    setSelectedCategoryForEditDelete={
                                        setSelectedCategoryForEditDelete
                                    }
                                    setOpenModalEditService={setOpenModalEditService}
                                    setSelectedServiceForEditDelete={
                                        setSelectedServiceForEditDelete
                                    }
                                    setOpenModalDeleteService={setOpenModalDeleteService}
                                    setOpenModalCreateService={setOpenModalCreateService}
                                    setSelectedCategory={setSelectedCategory}
                                    setOpenModalEditCategory={setOpenModalEditCategory}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* MODAL FOR CREATE CATEGORY */}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                disableEscapeKeyDown
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="custom-modal" sx={style}>
                    <AddCategory
                        setOpenModal={setOpenModal}
                        setCategoryList={setCategoryList}
                        isOnboardPage={true}
                        // companyId={companyId}
                        // fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR DELETE CATEGORY */}
            <Modal
                open={openModalForDelete}
                onClose={() => setOpenModalForDelete(false)}
                disableEscapeKeyDown
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="custom-modal" sx={style}>
                    <DeleteCategory
                        setOpenModal={setOpenModalForDelete}
                        setCategoryList={setCategoryList}
                        isOnboardPage={true}
                        resourceId={
                            selectedCategoryForEditDelete
                                ? selectedCategoryForEditDelete.groupId
                                : null
                        }
                        // fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR CREATE SERVICE */}
            <Modal
                open={openModalCreateService}
                onClose={() => setOpenModalCreateService(false)}
                disableEscapeKeyDown
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="custom-modal" sx={style}>
                    <AddService
                        setOpenModal={setOpenModalCreateService}
                        resourceGroupId={selectedCategory ? selectedCategory.groupId : ""}
                        isOnboardPage={true}
                        setCategoryList={setCategoryList}
                        //   companyId={companyId}
                        //   fetchCategories={fetchCategories}
                    />
                </Box>
            </Modal>

            {/* MODAL FOR DELETE SERVICE */}
            <Modal
                open={openModalDeleteService}
                onClose={() => setOpenModalDeleteService(false)}
                disableEscapeKeyDown
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="custom-modal" sx={style}>
                    <DeleteService
                        setOpenModal={setOpenModalDeleteService}
                        isOnboardPage={true}
                        setCategoryList={setCategoryList}
                        resourceGroupId={
                            selectedCategoryForEditDelete
                                ? selectedCategoryForEditDelete.groupId
                                : null
                        }
                        resourceId={
                            selectedServiceForEditDelete
                                ? selectedServiceForEditDelete.id
                                : null
                        }
                    />
                </Box>
            </Modal>
        </Container>
    );
}
