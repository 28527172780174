import {Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import React, {useState} from "react";
import CompanyService from "../../../../services/CompanyService";
import AuthService from "../../../../services/AuthService";
import TranslationService from "../../../../services/TranslationService";
import * as TRANSLATION from "../../../../services/TranslationService";

const StaffDetailsCard = (props) => {

    const [detailsUpdate, setDetailsUpdate] = useState(false)
    const [username, setUsername] = React.useState(props.inEditMode.currentUser.username)

    const staffDetailsText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.STAFF_DETAILS_DE
            : TRANSLATION.STAFF_DETAILS_EN
    };

    const usernameText = () => {
        return TranslationService.getCurrentLanguage() === "DE" ? TRANSLATION.USERNAME_DE
            : TRANSLATION.USERNAME_EN
    };

    const onSave = (userId) => {
        CompanyService.updateStaff(userId, null, null, AuthService.getCurrentUser().companyId, username).then(
            (response) => {
                props.staffFlagUpdate(true)
                window.location.reload(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        setDetailsUpdate(false)
    }

    return (
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">{staffDetailsText()}</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="2">
                            <label>Name</label>

                        </Col>
                        <Col sm="9">
                            <b>
                                {props.inEditMode.currentUser.name}
                            </b>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="2">
                            <label>Email</label>
                        </Col>
                        <Col lg="9">
                            <b>
                                {props.inEditMode.currentUser.email}
                            </b>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="2">
                            <label>{usernameText()}</label>
                        </Col>
                        <Col lg="5">
                            {!detailsUpdate ? (
                                <React.Fragment>
                                    <label>{props.inEditMode.currentUser.username}</label>
                                </React.Fragment>) : (
                                <Input
                                    placeholder={props.inEditMode.currentUser.username}
                                    type="text"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />)}
                            {/*<label>.bookmyagenda.com</label>*/}
                        </Col>
                        <Col xs="5">
                            {
                                detailsUpdate ? (
                                    <React.Fragment>
                                        <button
                                            className={"btn-success"}
                                            onClick={() => onSave(props.inEditMode.rowKey)}
                                        >
                                            Save
                                        </button>

                                        <button
                                            className={"btn-secondary"}
                                            style={{marginLeft: 8}}
                                            onClick={() => setDetailsUpdate(false)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <button
                                        className={"btn-primary"}
                                        onClick={() => setDetailsUpdate(true)
                                        }
                                    >
                                        Edit
                                    </button>
                                )
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default StaffDetailsCard