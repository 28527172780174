import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import axiosInstance  from "../../helper/AxiosMainHelper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Categories2.css";
import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BackDrop from "../../helper/BackDrop";
import AddIcon from "@mui/icons-material/Add";
import AddCategory from "./AddCategory";
import DeleteCategory from "./DeleteCategory";
import AuthService from "../../../../services/AuthService";

const drawerWidth = 320;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 540,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Categories2() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
 // const [searchParams] = useSearchParams();
  const companyId = "f48abe8f-ac2b-4a6d-914e-7dda77d57732";
  const [categoryList, setCategoryList] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedCategoryForEditDelete, setSelectedCategoryForEditDelete] =
    React.useState(null);
  const [openModalForDelete, setOpenModalForDelete] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get(
        `appointment/resource-group?companyId=${companyId}`
      );
      if (response?.status === 200) {
        if (response.data.length > 0) {
          setCategoryList(response.data);
          if (response.data[0].servicesList) {
            setSelectedCategory(response.data[0]);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log({ categoryList });

  React.useEffect(() => {
    fetchCategories();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (selectedCategory.length <= 0) {
    return <BackDrop loading={true} />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <Typography variant="h6" noWrap component="div">
              Categories
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="drawerHeader">
          {open && (
            <Typography variant="h6" noWrap component="div">
              Categories
            </Typography>
          )}
          <div>
            <Button variant="contained">
              <AddIcon
                onClick={() => {
                  handleOpen();
                }}
              />
            </Button>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider />
        <List>
          {categoryList.map((category, index) => (
            <ListItem
              className={`sidebar-item ${
                category.groupId === selectedCategory.groupId ? "selected" : ""
              }`}
              key={category.groupId}
            >
              <ListItemButton
                onClick={() => {
                  setSelectedCategory(category);
                }}
              >
                <ListItemIcon>
                  <InboxIcon className={"icon-sidebar"} />
                </ListItemIcon>
                <ListItemText
                  primary={`${category.name} (${category.servicesList.length})`}
                />
              </ListItemButton>
              <div className="icons">
                <EditIcon className={"icon-sidebar2 edit"} />
                <DeleteIcon
                  className={"icon-sidebar2 delete"}
                  onClick={() => {
                    setOpenModalForDelete(true);
                    setSelectedCategoryForEditDelete(category);
                  }}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box sx={{ margin: 1 }}>
          <Typography variant="h4" gutterBottom component="div">
            Services
          </Typography>
          <div className="headerMain">
            <div className="headerMain-text">
              <Typography variant="h5" gutterBottom component="div">
                {selectedCategory.name}
              </Typography>
              <Typography
                variant="p"
                className="headerMain-desc"
                gutterBottom
                component="div"
              >
                {selectedCategory.description}
              </Typography>
            </div>
            <Button variant="contained" startIcon={<AddIcon />}>
              Create Service
            </Button>
          </div>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow sx={{ fontWeight: 900 }}>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Amount ($)</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCategory?.servicesList?.map((service) => (
                <TableRow key={service.name}>
                  <TableCell component="th" scope="row">
                    {service.name}
                  </TableCell>
                  <TableCell>{service.description}</TableCell>
                  <TableCell>{service.duration}</TableCell>
                  <TableCell>{service.amount}</TableCell>
                  <TableCell>
                    <div className="icons">
                      <EditIcon className={"icon-sidebar2 edit"} />
                      <DeleteIcon className={"icon-sidebar2 delete"} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Main>
      {/* MODAL FOR CREATE CATEGORY */}
      <Modal
        size="lg"
        open={openModal}
        onClose={handleClose}
        disableEscapeKeyDown
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <AddCategory
            setOpenModal={setOpenModal}
            companyId={companyId}
            fetchCategories={fetchCategories}
          />
      </Modal>

      {/* MODAL FOR DELETE CATEGORY */}
      <Modal
        open={openModalForDelete}
        onClose={() => setOpenModalForDelete(false)}
        disableEscapeKeyDown
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DeleteCategory
            setOpenModal={setOpenModalForDelete}
            resourceId={
              selectedCategoryForEditDelete
                ? selectedCategoryForEditDelete.groupId
                : null
            }
            fetchCategories={fetchCategories}
          />
        </Box>
      </Modal>
    </Box>
  );
}
