import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const BookingsList = props => {

    const deleteBooking =  (bookingId) => {
      props.deleteBooking(bookingId)
    }

    const submit = (bookingId) => {

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete the booking?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>  deleteBooking(bookingId)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }

    const columns = [{
        dataField: 'provider',
        text: 'Provider',
        sort: true
    }, {
        dataField: 'service',
        text: 'Service',
        sort: true
    }, {
        dataField: 'startTime',
        text: 'Time',
        sort: true

    }, {
        dataField: 'id',
        text: 'Actions',
        formatter: (cellContent, row) => {
            return (
                <>
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() => {
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() =>{
                            submit(row.id)
                        }}
                    >
                        Delete
                    </button>
                </>
            );
        }
    }];

    return (
        <>
            {
                props.bookings && props.bookings.map((booking) => (
                    <Card style={{backgroundColor: '#B2DFDB'}}>
                        <CardHeader className="border-0" style={{backgroundColor: '#4DD0E1'}}>
                            <Row>
                                <Col xs="6">
                                    <h3 className="mb-0">{booking.date}</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <BootstrapTable
                                keyField='id'
                                data={booking.dayBookings}
                                columns={columns}
                                // pagination={paginationFactory()}
                                striped
                                bordered={false}
                                wrapperClasses="table-responsive"
                            >
                            </BootstrapTable>
                        </CardBody>
                    </Card>
                ))
            }

        </>
    )
}

export default BookingsList