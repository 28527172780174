import AuthHeader from "components/Headers/AuthHeader";

function EmailPresent() {
    return (
        <>
            <AuthHeader
                title="Email already present"
                lead="Please login or click on Forgot password"
            />
        </>
    );
}

export default EmailPresent;